export class OpUtils {
  private static readonly binDefinition = {
    // P専板カード
    pointOnlyReal: [
      '51',
      '010',
      '011',
      '012',
      '013',
      '014',
      '015',
      '016',
      '017',
      '018',
      '0190',
      '0197',
      '0198',
      '0199'
    ],
    // デジタルカード(OP発番、ONE発番)
    pointOnlyDigital: ['201', '202'],
    // JCBカード
    JCB: ['831', '832', '833', '834'],
    // VISAカード
    VISA: ['841', '842', '843', '844'],
    // MasterCardカード
    MasterCard: ['851', '852', '853', '854'],
    // OPCメンバーズカード
    OPCMembers: ['861', '862'],
    // JCIカード（JALカード OPクレジットのこと）
    JCI: ['871', '872', '873', '874']
  };

  // 重複はあるが、受領資料通りのためそのまま実装とする
  // 参考資料については右記参照：https://can-can.backlog.com/view/ODE_OPWEB-322
  private static readonly webCardCodeDefinition = [
    { code: '11000', name: ' OPクレジット・JCB' },
    { code: '11R01', name: ' OPクレジット Rカード・JCB' },
    { code: '11R02', name: ' OPクレジット RIIカード・JCB' },
    { code: '14000', name: ' OPクレジット ゴールド・JCB' },
    { code: '11000', name: ' OPクレジット・JCB' },
    { code: '11R01', name: ' OPクレジット Rカード・JCB' },
    { code: '11R02', name: ' OPクレジット RIIカード・JCB' },
    { code: '14000', name: ' OPクレジット ゴールド・JCB' },
    { code: '12000', name: ' OPクレジット・Visa' },
    { code: '12R02', name: ' OPクレジット RIIカード・Visa' },
    { code: '15000', name: ' OPクレジット ゴールド・Visa' },
    { code: '13000', name: ' OPクレジット・Mastercard(R)' },
    { code: '13R02', name: ' OPクレジット RIIカード・Mastercard(R)' },
    { code: '16000', name: ' OPクレジット ゴールド・Mastercard(R) ' },
    { code: '12000', name: ' OPクレジット・Visa' },
    { code: '12R02', name: ' OPクレジット RIIカード・Visa' },
    { code: '15000', name: ' OPクレジット ゴールド・Visa' },
    { code: '13000', name: ' OPクレジット・Mastercard(R)' },
    { code: '13R02', name: ' OPクレジット RIIカード・Mastercard(R)' },
    { code: '16000', name: ' OPクレジット ゴールド・Mastercard(R) ' },
    { code: '11JGC', name: ' JALカード OPクレジット JGC' },
    { code: '11JL', name: ' JALカード OPクレジット' },
    { code: '14JGC', name: ' JALカード OPクレジット CLUB-AゴールドJGC' },
    { code: '14JL', name: ' JALカード OPクレジット CLUB-Aゴールド' },
    { code: '11JGC', name: ' JALカード OPクレジット JGC' },
    { code: '11JL', name: ' JALカード OPクレジット' },
    { code: '14JGC', name: ' JALカード OPクレジット CLUB-AゴールドJGC' },
    { code: '14JL', name: ' JALカード OPクレジット CLUB-Aゴールド' },
    { code: '10000', name: ' OPクレジットハウス' },
    { code: '10000', name: ' OPクレジットハウス' },
    { code: '20000', name: ' OPポイント専用カード 旧OP' },
    { code: '21000', name: ' OPポイント専用カード 新OP' },
    { code: '40000', name: ' OPポイント専用カード 新OP' },
    { code: '31000', name: ' OPポイント専用カード 家族会員(JCB)' },
    {
      code: '32000',
      name: ' OPポイント専用カード 家族会員(Visa/Mastercard(R) )'
    },
    { code: '13MB', name: ' OPクレジット メンバーズカード' },
    { code: '13MB', name: ' OPクレジット メンバーズカード' },
    { code: '21MB', name: ' OPポイント専用 メンバーズカード' },
    { code: '32MB', name: ' OPポイント専用 メンバーズカード 家族会員' }
  ];

  private static matchBin = (bin: string, odakyuCustomerNo: string) =>
    odakyuCustomerNo.startsWith(bin);

  /**
   * 引数の小田急お客さま番号がP専板カードの番号かどうかを判定
   * titanAPIのop_card#IsPointOnlyと同等の処理
   * https://ode.futurefraqta.io/gitlab/platform/sources/titan/-/blob/master/domain/model/op_card.go#L79
   * 本来、titanの処理結果をfrontendでも利用するのが望ましいが、
   * OPカード属性参照API およびAuth0のIDトークン生成処理が改修対象で無いため、frontendで同等の処理を実施する
   * TODO: (a.takahashi.xz@future.co.jp) titanで判定した結果をIDトークンに渡すように修正し、その値を使用するよう改修
   * @param odakyuCustomerNo 小田急お客さま番号
   * @returns 小田急お客さま番号がP専板カードの場合trueを返却
   */
  public static isPointOnlyRealCard(odakyuCustomerNo: string) {
    return OpUtils.binDefinition.pointOnlyReal.some(bin =>
      OpUtils.matchBin(bin, odakyuCustomerNo)
    );
  }

  /**
   * 引数の小田急お客さま番号がP専デジタルカード（エアカード）の番号かどうかを判定
   * titanAPIのop_card#IsAirと同等の処理
   * https://ode.futurefraqta.io/gitlab/platform/sources/titan/-/blob/master/domain/model/op_card.go#L85
   * 本来、titanの処理結果をfrontendでも利用するのが望ましいが、
   * OPカード属性参照API およびAuth0のIDトークン生成処理が改修対象で無いため、frontendで同等の処理を実施する
   * TODO: (a.takahashi.xz@future.co.jp) titanで判定した結果をIDトークンに渡すように修正し、その値を使用するよう改修
   * @param odakyuCustomerNo 小田急お客さま番号
   * @returns 小田急お客さま番号がP専デジタルカード（エアカード）の場合trueを返却
   */
  public static isPointOnlyDigitalCard(odakyuCustomerNo: string) {
    return OpUtils.binDefinition.pointOnlyDigital.some(bin =>
      OpUtils.matchBin(bin, odakyuCustomerNo)
    );
  }

  /**
   * 引数の小田急お客さま番号がJCBカードの番号かどうかを判定
   * 本来、titanの処理結果をfrontendでも利用するのが望ましいが、
   * OPカード属性参照API およびAuth0のIDトークン生成処理が改修対象で無いため、frontendで同等の処理を実施する
   * TODO: (a.takahashi.xz@future.co.jp) titanで判定した結果をIDトークンに渡すように修正し、その値を使用するよう改修
   * @param odakyuCustomerNo 小田急お客さま番号
   * @returns 小田急お客さま番号がJCBカードの場合trueを返却
   */
  public static isJCBCard(odakyuCustomerNo: string) {
    return OpUtils.binDefinition.JCB.some(bin =>
      OpUtils.matchBin(bin, odakyuCustomerNo)
    );
  }

  /**
   * 引数の小田急お客さま番号がVISAカードの番号かどうかを判定
   * 本来、titanの処理結果をfrontendでも利用するのが望ましいが、
   * OPカード属性参照API およびAuth0のIDトークン生成処理が改修対象で無いため、frontendで同等の処理を実施する
   * TODO: (a.takahashi.xz@future.co.jp) titanで判定した結果をIDトークンに渡すように修正し、その値を使用するよう改修
   * @param odakyuCustomerNo 小田急お客さま番号
   * @returns 小田急お客さま番号がVISAカードの場合trueを返却
   */
  public static isVISACard(odakyuCustomerNo: string) {
    return OpUtils.binDefinition.VISA.some(bin =>
      OpUtils.matchBin(bin, odakyuCustomerNo)
    );
  }

  /**
   * 引数の小田急お客さま番号がMasterCardカードの番号かどうかを判定
   * 本来、titanの処理結果をfrontendでも利用するのが望ましいが、
   * OPカード属性参照API およびAuth0のIDトークン生成処理が改修対象で無いため、frontendで同等の処理を実施する
   * TODO: (a.takahashi.xz@future.co.jp) titanで判定した結果をIDトークンに渡すように修正し、その値を使用するよう改修
   * @param odakyuCustomerNo 小田急お客さま番号
   * @returns 小田急お客さま番号がMasterCardカードの場合trueを返却
   */
  public static isMasterCard(odakyuCustomerNo: string) {
    return OpUtils.binDefinition.MasterCard.some(bin =>
      OpUtils.matchBin(bin, odakyuCustomerNo)
    );
  }

  /**
   * 引数の小田急お客さま番号がOPクレジットメンバーズカードの番号かどうかを判定
   * 本来、titanの処理結果をfrontendでも利用するのが望ましいが、
   * OPカード属性参照API およびAuth0のIDトークン生成処理が改修対象で無いため、frontendで同等の処理を実施する
   * TODO: (a.takahashi.xz@future.co.jp) titanで判定した結果をIDトークンに渡すように修正し、その値を使用するよう改修
   * @param odakyuCustomerNo 小田急お客さま番号
   * @returns 小田急お客さま番号がOPクレジットメンバーズカードの場合trueを返却
   */
  public static isOPCMembersCard(odakyuCustomerNo: string) {
    return OpUtils.binDefinition.OPCMembers.some(bin =>
      OpUtils.matchBin(bin, odakyuCustomerNo)
    );
  }

  /**
   * 引数の小田急お客さま番号がJCIカードの番号かどうかを判定
   * 本来、titanの処理結果をfrontendでも利用するのが望ましいが、
   * OPカード属性参照API およびAuth0のIDトークン生成処理が改修対象で無いため、frontendで同等の処理を実施する
   * TODO: (a.takahashi.xz@future.co.jp) titanで判定した結果をIDトークンに渡すように修正し、その値を使用するよう改修
   * @param odakyuCustomerNo 小田急お客さま番号
   * @returns 小田急お客さま番号がJCIカードの場合trueを返却
   */
  public static isJCICard(odakyuCustomerNo: string) {
    return OpUtils.binDefinition.JCI.some(bin =>
      OpUtils.matchBin(bin, odakyuCustomerNo)
    );
  }

  /**
   * WebカードコードからWebカード名称を取得
   * @param webCardCode Webカードコード（OpCardHistory.cardTypeCd）
   * @returns Webカード名称（該当するコードがない場合は空文字を返却）
   */
  public static getWebCardName(webCardCode: string) {
    const d = OpUtils.webCardCodeDefinition.find(w => w.code === webCardCode);
    return d ? d.name : '';
  }
}
