import Vue from 'vue';
import Vuex from 'vuex';
import cls from '@/common/classification';
import { Balance, OpCard, OpCardList } from './models/opcards/OpCard';
import { CardTypeItem } from '@/models/CardTypeItem';

Vue.use(Vuex);

export const state = {
  currentUser: {
    lastName: '',
    uid: ''
  },
  op: {
    flg: {
      isOpFetched: false // 初回ロード時にログイン中のユーザに紐付くOPカードの情報が取得されたらtrueに変更する
    },
    balance: {
      processDatetime: '',
      returnStatus: '',
      memberStatus: '',
      thisYearBalance: 0,
      lastYearBalance: 0,
      previousBalance: 0,
      pointAccumTerm: '',
      pointInvalidDate: '',
      admissionDate: ''
    } as Balance,
    cards: [] as OpCard[]
  },
  microCMS: {
    isMicroCMSFetched: false,
    cardType: new Map<string, CardTypeItem>()
  },
  fromPath: '',
  isBack: false,
  enableBackBtn: false,
  supportsWebP: false,
  isSP: false,
  tokenResponded: false
};

export const getters = {
  getOpBalanceSuccess: (state: any): boolean => {
    return (
      state.op.balance.returnStatus ===
      cls.POINT_DEAL_STATUS_FROM_ECOP.SUCCESS.CD
    );
  },
  getOpFetched: (state: any): boolean => {
    return state.op.flg.isOpFetched;
  },
  getMicroCMSFetched: (state: any): boolean => {
    return state.microCMS.isMicroCMSFetched;
  }
};

export const mutations = {
  setLastName(state: any, payload: any) {
    state.currentUser.lastName = payload;
  },
  setUid(state: any, payload: any) {
    state.currentUser.uid = payload;
  },
  setOpCards(state: any, payload: any) {
    state.op.cards = OpCardList.valueOf(payload).list;
  },
  setOpBalance(state: any, payload: any) {
    state.op.balance = Balance.valueOf(payload);
  },
  setOpFetched(state: any, payload: boolean) {
    state.op.flg.isOpFetched = payload;
  },
  setMicroCMSCardType(state: any, payload: any) {
    state.microCMS.cardType = payload;
  },
  setMicroCMSFetched(state: any, payload: boolean) {
    state.microCMS.isMicroCMSFetched = payload;
  },
  removeAllOpCards(state: any) {
    state.op.cards = [];
  },
  removeOpBalance(state: any) {
    state.op.balance = {};
  },
  setSupportsWebP(state: any, payload: boolean) {
    state.supportsWebP = payload;
  },
  setIsSP(state: any, payload: boolean) {
    state.isSP = payload;
  },
  setTokenResponded(state: any, payload: boolean) {
    state.tokenResponded = payload;
  }
};

export default new Vuex.Store({
  state,
  getters,
  mutations
});
