import { CampaignResponse } from '@/models/cms/campaign';
import { Image } from '@/models/cms';
import * as campaignUtils from '@/common/campaignUtils';
import * as cmsUtils from '@/common/cmsUtils';
import { convertOptimizedUrl } from '@/common/cmsUtils';
import { CardTypeItem } from './CardTypeItem';
import { GroupCardTypeItem } from './GroupCardTypeItem';
/**
 * @classdesc microCMSから受け取ったキャンペーンの情報を管理する
 */
export class CampaignItem {
  private static readonly DEFAULT_ENTRY_BTN_TEXT = 'エントリーする';
  private static readonly DEFAULT_CANCEL_BTN_TEXT =
    'エントリーをキャンセルする';
  private static readonly DEFAULT_FREE_FORM_TITLE_TEXT =
    '感想があればご入力ください';

  private static readonly CAMPAIGN_OPTIONS_TYPE_MULTIPLE_SELECT =
    'チェックボックス（複数選択）';

  static valueOf(c: CampaignResponse, isSP: boolean, supportsWebP: boolean) {
    return new CampaignItem(
      isSP,
      supportsWebP,
      c.id,
      c.title,
      c.campaignId,
      c.contents,
      c.startDatetime,
      c.finishDatetime,
      c.entryBtnText ? c.entryBtnText : this.DEFAULT_ENTRY_BTN_TEXT,
      c.cancelBtnText ? c.cancelBtnText : this.DEFAULT_CANCEL_BTN_TEXT,
      c.disableEntryBtn,
      c.hiddenOnList,
      c.listImg,
      c.detailImg,
      c.showFreeForm,
      c.freeFormTitleText
        ? c.freeFormTitleText
        : this.DEFAULT_FREE_FORM_TITLE_TEXT,
      c.requiredFreeForm,
      c.campaignOptions?.map(o => CampaignOption.valueOf(false, o.optionText)),
      c.campaignOptionsType[0],
      c.permitType[0] ? c.permitType[0] : cmsUtils.permitAllValue(),
      c.permitCardType?.map(pc => CardTypeItem.valueOf(pc)),
      c.permitCardTypeGroup?.map(pg => GroupCardTypeItem.valueOf(pg)),
      c.permitCardTypeText,
      c.publishedAt
    );
  }

  get publishedAtJST() {
    return cmsUtils.toJSTString(this.publishedAt);
  }

  get isBeforeAvailableEntryTime() {
    return campaignUtils.isBeforeAvailableEntryTime(this.startDatetime);
  }

  get isAvailableEntryTime() {
    return campaignUtils.isAvailableEntryTime(
      this.startDatetime,
      this.finishDatetime
    );
  }

  get isAfterAvailableEntryTime() {
    return campaignUtils.isAfterAvailableEntryTime(this.finishDatetime);
  }

  get isNewCampaign() {
    return cmsUtils.isNewContent(this.publishedAt);
  }

  get isMultipleSelect() {
    return (
      this.campaignOptionsType ===
      CampaignItem.CAMPAIGN_OPTIONS_TYPE_MULTIPLE_SELECT
    );
  }

  get isShowOptionsForm() {
    return this.campaignOptions ? !!this.campaignOptions.length : false;
  }

  get isPermitTargetGroup() {
    return cmsUtils.isPermitTargetGroup(this.permitType);
  }

  get isPermitTargetSingle() {
    return cmsUtils.isPermitTargetSingle(this.permitType);
  }

  get isPermitTargetAll() {
    return cmsUtils.isPermitTargetAll(this.permitType);
  }

  get listImgUrl() {
    return this.listImg
      ? convertOptimizedUrl(this.listImg, this.isSP, this.supportsWebP)
      : '';
  }

  get detailImgUrl() {
    return this.detailImg
      ? convertOptimizedUrl(this.detailImg, this.isSP, this.supportsWebP)
      : '';
  }

  private constructor(
    readonly isSP: boolean,
    readonly supportsWebP: boolean,
    readonly id: string,
    readonly title: string,
    readonly campaignId: string,
    readonly contents: string,
    readonly startDatetime: string,
    readonly finishDatetime: string,
    readonly entryBtnText: string,
    readonly cancelBtnText: string,
    readonly disableEntryBtn: boolean,
    readonly hiddenOnList: boolean,
    readonly listImg: Image,
    readonly detailImg: Image,
    readonly showFreeForm: boolean,
    readonly freeFormTitleText: string,
    readonly requiredFreeForm: boolean,
    readonly campaignOptions: Array<CampaignOption>,
    readonly campaignOptionsType: string,
    readonly permitType: string,
    readonly permitCardTypes: Array<CardTypeItem>,
    readonly permitCardTypeGroups: Array<GroupCardTypeItem>,
    readonly permitCardTypeText: string,
    readonly publishedAt: string
  ) {}
}

export class CampaignOption {
  static valueOf(isSelectedFlag: boolean, optionText: string) {
    return new CampaignOption(isSelectedFlag, optionText);
  }
  private constructor(
    private isSelectedFlag: boolean,
    private readonly optionText: string
  ) {}

  public get isSelected(): boolean {
    return this.isSelectedFlag;
  }
  public set isSelected(isSelectedFlag: boolean) {
    this.isSelectedFlag = isSelectedFlag;
  }
  public get text(): string {
    return this.optionText;
  }
}
