import axios from 'axios';
import { CMSResponse } from '@/models/cms';

const axiosConfig = {
  headers: {
    'x-microcms-api-key': process.env.VUE_APP_CMS_API_KEY!
  }
};

export const fetchListContents = async <T>(
  relativePath: string,
  options?: fetchListContentsOptions
) => {
  return await axios.get<CMSResponse<T>>(
    process.env.VUE_APP_CMS_ENDPOINT_URL! + relativePath,
    {
      ...axiosConfig,
      params: options
    }
  );
};

/* MicroCMSのAPIパラメータ
 * https://microcms.io/docs/content-api/get-list-contents
 */
export interface fetchListContentsOptions {
  draftKey?: string;
  limit?: number;
  offset?: number;
  orders?: string;
  q?: string;
  fields?: string;
  ids?: string;
  filters?: string;
}

// 単票取得APIコール
export const selectContent = async <T>(relativePath: string, id: string) => {
  return await axios.get<T>(
    process.env.VUE_APP_CMS_ENDPOINT_URL! + relativePath + `/${id}`,
    axiosConfig
  );
};
