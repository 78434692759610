import Vue from 'vue';
import * as api from '@/gen/api';
import { EnergyRepositoryImpl } from '@/repositories/interface/EnergyRepositoryImpl';

/**
 * @classdesc でんき・ガスの契約情報・請求情報を取得する。
 */
export class EnergyRepository implements EnergyRepositoryImpl {
  public constructor() {}

  private async getElectricityApiClient() {
    const config = await Vue.prototype.$getConfigWithToken(Vue.prototype.$auth);
    return new api.ElectricityApi(config);
  }

  private async getGasApiClient() {
    const config = await Vue.prototype.$getConfigWithToken(Vue.prototype.$auth);
    return new api.GasApi(config);
  }

  private async fetchGasInvoices() {
    const client = await this.getGasApiClient();
    const res = await client
      .getGasContractInvoices()
      .catch(Vue.prototype.$tokenErrHandler);
    return res ? res.data : [];
  }

  private async fetchElectricityInvoices() {
    const client = await this.getElectricityApiClient();
    const res = await client
      .getElectricityContractInvoices()
      .catch(Vue.prototype.$tokenErrHandler);
    return res ? res.data : [];
  }

  /**
   * でんき・ガスそれぞれの契約情報を取得して返す
   */
  public async fetchEnergyInvoices() {
    const results = await Promise.all([
      await this.fetchElectricityInvoices(),
      await this.fetchGasInvoices()
    ]);

    return { electricityInvoices: results[0], gasInvoices: results[1] };
  }

  /**
   * #1428
   * でんき・ガスの契約情報を取得する。どちらかでも契約していればtrueを返す。
   */
  public async userHasEnergyContracts() {
    const {
      electricityInvoices,
      gasInvoices
    } = await this.fetchEnergyInvoices();

    // 契約数がどちらも0件ならfalseを返す。
    return !!electricityInvoices.length || !!gasInvoices.length;
  }
}
