import { Configuration } from '@/gen/configuration';

// ＜使用例＞
// import * as api from '@/gen/api';
// export default class MyPage extends Vue {
//   private async test() {
//     const config = await this.$getDefaultConfig(); // use this when you call api without auth
//     const config = await this.$getConfigWithToken(this.$auth); // use this when you call api with auth
//     const userApi = new api.UserApi(config);
//     const query={...}
//     const res = await userApi.searchUsers(query);
//     // use res.data
//   }

export default {
  install(Vue: any) {
    const defaultConfig = { basePath: process.env.VUE_APP_BASE_PATH };
    const titanConfig = { basePath: process.env.VUE_APP_TITAN_BASE_PATH };
    const shivaConfig = { basePath: process.env.VUE_APP_SHIVA_BASE_PATH };

    Vue.prototype.$getDefaultConfig = async () =>
      new Configuration(defaultConfig);
    Vue.prototype.$getConfigWithToken = async ($auth: any) =>
      new Configuration({
        accessToken: await $auth.getToken(),
        ...defaultConfig
      });
    Vue.prototype.$getTitanConfig = async () => new Configuration(titanConfig);
    Vue.prototype.$getTitanConfigWithToken = async ($auth: any) =>
      new Configuration({
        accessToken: await $auth.getToken(),
        ...titanConfig
      });
    Vue.prototype.$getShivaConfig = async () => new Configuration(shivaConfig);
    Vue.prototype.$getShivaConfigWithToken = async ($auth: any) =>
      new Configuration({
        accessToken: await $auth.getToken(),
        ...shivaConfig
      });
  }
};
