









































import { Component, Vue, Prop } from 'vue-property-decorator';
import { EventCmsRepository } from '@/repositories/EventCmsRepository';
import { EventItem } from '@/models/EventItem';

@Component
export default class EventScroll extends Vue {
  readonly INITIAL_PAGE_NO = 1;
  readonly LIMIT_NUM = 4;
  eventList: Array<EventItem> = [] as Array<EventItem>;
  totalCount: number = 0;
  pageNo: number = this.INITIAL_PAGE_NO;
  pathName: string = this.$cls.EVENT_TAB_NAME_CLS.TOP.PATH_NAME;

  created() {
    this.initialize();
  }
  initialize() {
    this.getEventList();
  }

  async getEventList() {
    const listItem = await this.eventCmsRepository.getPage(
      this.LIMIT_NUM,
      this.LIMIT_NUM * (this.pageNo - 1),
      this.pathName
    );
    if (listItem) {
      this.eventList = listItem.items;
      this.totalCount = listItem.totalCount;
    }
  }

  get eventCmsRepository() {
    return new EventCmsRepository(
      this.$store.state.isSP,
      this.$store.state.supportsWebP
    );
  }

  @Prop()
  readonly isMarginShort?: boolean;
}
