import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
const TIMEZONE_JST = 'Asia/Tokyo';

export const TAG_ALL = 'all';
export const TAG_HOME = 'home';
export const TAG_KIDS = 'kids';
export const TAG_FOOD = 'food';
export const TAG_FASHION = 'fashion';
export const TAG_BEAUTY = 'beauty';
export const TAG_SPORTS = 'sports';
export const TAG_OTHER = 'other';

/**
 * タグの種類に応じてsvgファイルのパスを返す
 * @param tagName
 */
export const getServiceTagSvgPath = (tagName: string) => {
  switch (tagName) {
    case TAG_HOME:
      return '/static/assets/img/home_tag.svg';
    case TAG_KIDS:
      return '/static/assets/img/baby_tag.svg';
    case TAG_FOOD:
      return '/static/assets/img/food_tag.svg';
    case TAG_FASHION:
      return '/static/assets/img/fashion_tag.svg';
    default:
      return '/static/assets/img/other_tag.svg';
  }
};

/**
 * タグの種類に応じてcssのclassを返す
 * @param tagName
 */
export const getServiceTagCssClass = (tagName: string) => {
  switch (tagName) {
    case TAG_HOME:
      return 'tag_home';
    case TAG_KIDS:
      return 'tag_kid'; // class は kid と kids で表記揺れが発生しているため注意
    case TAG_FOOD:
      return 'tag_food';
    case TAG_FASHION:
      return 'tag_fashion';
    default:
      return 'tag_other';
  }
};

/**
 * タグの種類に応じて日本語を返す
 * @param tagName
 */
export const getServiceTagNameJp = (tagName: string) => {
  switch (tagName) {
    case TAG_HOME:
      return '暮らし';
    case TAG_KIDS:
      return '子育て';
    case TAG_FOOD:
      return '料理';
    case TAG_FASHION:
      return 'ファッション';
    case TAG_BEAUTY:
      return '美容';
    case TAG_SPORTS:
      return '運動';
    case TAG_ALL:
      return '全てのクーポン';
    default:
      return 'その他';
  }
};

/**
 * 現在利用できるタグのリストを返す
 */
export const getTagList = () => {
  return [
    { name: TAG_ALL, nameJp: getServiceTagNameJp(TAG_ALL) },
    { name: TAG_FASHION, nameJp: getServiceTagNameJp(TAG_FASHION) },
    { name: TAG_HOME, nameJp: getServiceTagNameJp(TAG_HOME) },
    { name: TAG_FOOD, nameJp: getServiceTagNameJp(TAG_FOOD) },
    { name: TAG_KIDS, nameJp: getServiceTagNameJp(TAG_KIDS) },
    { name: TAG_OTHER, nameJp: getServiceTagNameJp(TAG_OTHER) }
  ];
};

/**
 * 今サブスクに加入すると、初回のクーポン発行日がいつになるかを計算する。
 */
export const getFirstDistributionDt = (interval: number) => {
  let now = dayjs().tz(TIMEZONE_JST);
  if (interval === 1) {
    return now
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .format();
  }
  return now
    .set('date', 1)
    .add(interval, 'month')
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .format();
};
