import Vue from 'vue';
import { fetchListContents, fetchListContentsOptions } from '@/common/cms';
import { CardTypeResponse } from '@/models/cms/cardType';
import { CardTypeItem } from '@/models/CardTypeItem';

export class CardTypeRepository extends Vue {
  private readonly PATH = 'card_type';
  // microCMS側のデフォルト値が10のため設定
  // 個別券種マスタのContents数よりも多くなるように設定すること
  private readonly FETCH_CONTENTS_LIMIT = 1000;

  /*
   * 券種マスタの一覧をIDがkeyで券種マスタ情報がvalueのMapで取得
   */
  async allMap() {
    const requestOption: fetchListContentsOptions = {
      limit: this.FETCH_CONTENTS_LIMIT
    };
    try {
      const { data } = await fetchListContents<CardTypeResponse>(
        this.PATH,
        requestOption
      );
      const { contents } = data;

      if (!contents.length) {
        return null;
      }

      const contentMap = new Map<string, CardTypeItem>();
      contents.map(c => contentMap.set(c.id, CardTypeItem.valueOf(c)));
      return contentMap;
    } catch (_) {
      return null;
    }
  }
}
