import { EventResponse } from '@/models/cms';
import { EventItem } from '@/models/EventItem';

export class EventListItem {
  static valueOf(
    responses: EventResponse[],
    totalCount: number,
    isSP: boolean,
    supportsWebP: boolean
  ) {
    return new EventListItem(
      responses.map(c => EventItem.valueOf(c, isSP, supportsWebP)),
      totalCount
    );
  }

  private constructor(
    readonly items: Array<EventItem>,
    readonly totalCount: number
  ) {}
}
