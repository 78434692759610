export default class Classification {
  /**
   * 区分値を配列に変換し、昇順に並び替え
   */
  public static toArray(cls: string): string[] {
    const res: string[] = [];
    for (let key in (<any>Classification)[cls]) {
      res.push((<any>(<any>Classification)[cls])[key]);
    }
    return res.sort((a: any, b: any) => a.ORDER - b.ORDER);
  }

  /**
   * 区分値を用いたプルダウンメニューの選択肢を作成
   */
  public static createSelectItems(cls: string): any[] {
    return this.toArray(cls).map((c: any) => ({ text: c.NAME, value: c.CD }));
  }

  /**
   * 区分値のコードを元に名称を取得
   */
  public static getName(cls: string, cd: string): string {
    return this.toArray(cls)
      .filter((c: any) => c.CD === cd)
      .map((c: any) => c.NAME)
      .toString();
  }

  /**
   * 区分値のコードを元にパス名称を取得
   */
  public static getPathName(cls: string, cd: string): string {
    return this.toArray(cls)
      .filter((c: any) => c.CD === cd)
      .map((c: any) => c.PATH_NAME)
      .toString();
  }

  /**
   * 区分値のパス名称を元にコードを取得
   */
  public static getCodeByPathName(cls: string, name: string): string {
    return this.toArray(cls)
      .filter((c: any) => c.PATH_NAME === name)
      .map((c: any) => c.CD)
      .toString();
  }

  // 連携区分
  public static readonly COOPERATION_CLS = {
    OFF: {
      // コード
      CD: '00',
      // 区分値名
      NAME: '連携しない',
      // 順番
      ORDER: 1
    },
    ON: {
      // コード
      CD: '01',
      // 区分値名
      NAME: '連携する',
      // 順番
      ORDER: 2
    }
  };
  // 性別区分
  public static readonly GENDER_CLS = {
    MALE: {
      // コード
      CD: '00',
      // 区分値名
      NAME: '男性',
      // 順番
      ORDER: 1
    },
    FEMALE: {
      // コード
      CD: '01',
      // 区分値名
      NAME: '女性',
      // 順番
      ORDER: 2
    },
    OTHER: {
      // コード
      CD: '02',
      // 区分値名
      NAME: 'その他',
      // 順番
      ORDER: 3
    },
    UNANSWERED: {
      // コード
      CD: '03',
      // 区分値名
      NAME: '回答しない',
      // 順番
      ORDER: 4
    }
  };
  // 都道府県区分
  public static readonly PREF_CLS = {
    HOKKAIDO: {
      // コード
      CD: '01',
      // 区分値名
      NAME: '北海道',
      // 順番
      ORDER: 1
    },
    AOMORI: {
      // コード
      CD: '02',
      // 区分値名
      NAME: '青森県',
      // 順番
      ORDER: 2
    },
    IWATE: {
      // コード
      CD: '03',
      // 区分値名
      NAME: '岩手県',
      // 順番
      ORDER: 3
    },
    MIYAGI: {
      // コード
      CD: '04',
      // 区分値名
      NAME: '宮城県',
      // 順番
      ORDER: 4
    },
    AKITA: {
      // コード
      CD: '05',
      // 区分値名
      NAME: '秋田県',
      // 順番
      ORDER: 5
    },
    YAMAGATA: {
      // コード
      CD: '06',
      // 区分値名
      NAME: '山形県',
      // 順番
      ORDER: 6
    },
    FUKUSHIMA: {
      // コード
      CD: '07',
      // 区分値名
      NAME: '福島県',
      // 順番
      ORDER: 7
    },
    IBARAKI: {
      // コード
      CD: '08',
      // 区分値名
      NAME: '茨城県',
      // 順番
      ORDER: 8
    },
    TOCHIGI: {
      // コード
      CD: '09',
      // 区分値名
      NAME: '栃木県',
      // 順番
      ORDER: 9
    },
    GUNMA: {
      // コード
      CD: '10',
      // 区分値名
      NAME: '群馬県',
      // 順番
      ORDER: 10
    },
    SAITAMA: {
      // コード
      CD: '11',
      // 区分値名
      NAME: '埼玉県',
      // 順番
      ORDER: 11
    },
    CHIBA: {
      // コード
      CD: '12',
      // 区分値名
      NAME: '千葉県',
      // 順番
      ORDER: 12
    },
    TOKYO: {
      // コード
      CD: '13',
      // 区分値名
      NAME: '東京都',
      // 順番
      ORDER: 13
    },
    KANAGAWA: {
      // コード
      CD: '14',
      // 区分値名
      NAME: '神奈川県',
      // 順番
      ORDER: 14
    },
    NIIGATA: {
      // コード
      CD: '15',
      // 区分値名
      NAME: '新潟県',
      // 順番
      ORDER: 15
    },
    TOYAMA: {
      // コード
      CD: '16',
      // 区分値名
      NAME: '富山県',
      // 順番
      ORDER: 16
    },
    ISHIKAWA: {
      // コード
      CD: '17',
      // 区分値名
      NAME: '石川県',
      // 順番
      ORDER: 17
    },
    FUKUI: {
      // コード
      CD: '18',
      // 区分値名
      NAME: '福井県',
      // 順番
      ORDER: 18
    },
    YAMANASHI: {
      // コード
      CD: '19',
      // 区分値名
      NAME: '山梨県',
      // 順番
      ORDER: 19
    },
    NAGANO: {
      // コード
      CD: '20',
      // 区分値名
      NAME: '長野県',
      // 順番
      ORDER: 20
    },
    GIFU: {
      // コード
      CD: '21',
      // 区分値名
      NAME: '岐阜県',
      // 順番
      ORDER: 21
    },
    SHIZUOKA: {
      // コード
      CD: '22',
      // 区分値名
      NAME: '静岡県',
      // 順番
      ORDER: 22
    },
    AICHI: {
      // コード
      CD: '23',
      // 区分値名
      NAME: '愛知県',
      // 順番
      ORDER: 23
    },
    MIE: {
      // コード
      CD: '24',
      // 区分値名
      NAME: '三重県',
      // 順番
      ORDER: 24
    },
    SHIGA: {
      // コード
      CD: '25',
      // 区分値名
      NAME: '滋賀県',
      // 順番
      ORDER: 25
    },
    KYOTO: {
      // コード
      CD: '26',
      // 区分値名
      NAME: '京都府',
      // 順番
      ORDER: 26
    },
    OSAKA: {
      // コード
      CD: '27',
      // 区分値名
      NAME: '大阪府',
      // 順番
      ORDER: 27
    },
    HYOGO: {
      // コード
      CD: '28',
      // 区分値名
      NAME: '兵庫県',
      // 順番
      ORDER: 28
    },
    NARA: {
      // コード
      CD: '29',
      // 区分値名
      NAME: '奈良県',
      // 順番
      ORDER: 29
    },
    WAKAYAMA: {
      // コード
      CD: '30',
      // 区分値名
      NAME: '和歌山県',
      // 順番
      ORDER: 30
    },
    TOTTORI: {
      // コード
      CD: '31',
      // 区分値名
      NAME: '鳥取県',
      // 順番
      ORDER: 31
    },
    SHIMANE: {
      // コード
      CD: '32',
      // 区分値名
      NAME: '島根県',
      // 順番
      ORDER: 32
    },
    OKAYAMA: {
      // コード
      CD: '33',
      // 区分値名
      NAME: '岡山県',
      // 順番
      ORDER: 33
    },
    HIROSHIMA: {
      // コード
      CD: '34',
      // 区分値名
      NAME: '広島県',
      // 順番
      ORDER: 34
    },
    YAMAGUCHI: {
      // コード
      CD: '35',
      // 区分値名
      NAME: '山口県',
      // 順番
      ORDER: 35
    },
    TOKUSHIMA: {
      // コード
      CD: '36',
      // 区分値名
      NAME: '徳島県',
      // 順番
      ORDER: 36
    },
    KAGAWA: {
      // コード
      CD: '37',
      // 区分値名
      NAME: '香川県',
      // 順番
      ORDER: 37
    },
    EHIME: {
      // コード
      CD: '38',
      // 区分値名
      NAME: '愛媛県',
      // 順番
      ORDER: 38
    },
    KOCHI: {
      // コード
      CD: '39',
      // 区分値名
      NAME: '高知県',
      // 順番
      ORDER: 39
    },
    FUKUOKA: {
      // コード
      CD: '40',
      // 区分値名
      NAME: '福岡県',
      // 順番
      ORDER: 40
    },
    SAGA: {
      // コード
      CD: '41',
      // 区分値名
      NAME: '佐賀県',
      // 順番
      ORDER: 41
    },
    NAGASAKI: {
      // コード
      CD: '42',
      // 区分値名
      NAME: '長崎県',
      // 順番
      ORDER: 42
    },
    KUMAMOTO: {
      // コード
      CD: '43',
      // 区分値名
      NAME: '熊本県',
      // 順番
      ORDER: 43
    },
    OITA: {
      // コード
      CD: '44',
      // 区分値名
      NAME: '大分県',
      // 順番
      ORDER: 44
    },
    MIYAZAKI: {
      // コード
      CD: '45',
      // 区分値名
      NAME: '宮崎県',
      // 順番
      ORDER: 45
    },
    KAGOSHIMA: {
      // コード
      CD: '46',
      // 区分値名
      NAME: '鹿児島県',
      // 順番
      ORDER: 46
    },
    OKINAWA: {
      // コード
      CD: '47',
      // 区分値名
      NAME: '沖縄県',
      // 順番
      ORDER: 47
    },
    OTHER: {
      CD: '99',
      NAME: '住所未入力',
      ORDER: 99
    }
  };
  // 初期登録状況区分
  public static readonly INITIAL_REGISTRATION_STATUS_CLS = {
    UNREGISTERED: {
      // コード
      CD: '00',
      // 区分値名
      NAME: '未登録',
      // 順番
      ORDER: 1
    },
    REGISTERED: {
      // コード
      CD: '01',
      // 区分値名
      NAME: '登録済',
      // 順番
      ORDER: 2
    }
  };
  // OPカード登録状況区分
  public static readonly OP_CARD_REGISTRATION_STATUS_CLS = {
    UNREGISTERED: {
      // コード
      CD: '00',
      // 区分値名
      NAME: '未登録',
      // 順番
      ORDER: 1
    },
    ISSUING: {
      // コード
      CD: '01',
      // 区分値名
      NAME: '発行中',
      // 順番
      ORDER: 2
    },
    REGISTERED: {
      // コード
      CD: '02',
      // 区分値名
      NAME: '登録済',
      // 順番
      ORDER: 3
    }
  };
  // OPカード確認状況区分
  public static readonly OP_CARD_CONFIRMATION_STATUS_CLS = {
    UNCONFIRMED: {
      // コード
      CD: '00',
      // 区分値名
      NAME: '未確認',
      // 順番
      ORDER: 1
    },
    NG: {
      // コード
      CD: '01',
      // 区分値名
      NAME: '確認済(NG)',
      // 順番
      ORDER: 2
    },
    OK: {
      // コード
      CD: '02',
      // 区分値名
      NAME: '確認済(OK)',
      // 順番
      ORDER: 3
    },
    UNREGISTERED: {
      // コード
      CD: '99',
      // 区分値名
      NAME: '未登録',
      // 順番
      ORDER: 4
    }
  };
  // サービスカテゴリ区分。アライアンス先（パートナー）やクーポンのタグに当たる部分を管理する
  public static readonly SERVICE_CATEGORY_CLS = {
    HOME: {
      // コード
      CD: '00',
      // 区分値名
      NAME: 'home',
      // 順番
      ORDER: 1
    },
    KIDS: {
      // コード
      CD: '01',
      // 区分値名
      NAME: 'kids', // 子育て
      // 順番
      ORDER: 2
    },
    FOOD: {
      // コード
      CD: '02',
      // 区分値名
      NAME: 'food', // 料理
      // 順番
      ORDER: 3
    },
    FASHION: {
      // コード
      CD: '03',
      // 区分値名
      NAME: 'fashion',
      // 順番
      ORDER: 4
    },
    OTHER: {
      // コード
      CD: '99',
      // 区分値名
      NAME: 'other',
      // 順番
      ORDER: 99
    }
  };
  // 電ガス区分
  public static readonly ENERGY_CLS = {
    ELECTRICITY: {
      // コード
      CD: '01',
      // 区分値名
      NAME: '電気',
      // 順番
      ORDER: 1
    },
    GAS: {
      // コード
      CD: '02',
      // 区分値名
      NAME: 'ガス',
      // 順番
      ORDER: 2
    }
  };
  // 電ガスマイページ表示内容区分
  public static readonly ENERGY_PAGE_CONTENTS_CLS = {
    TOP: {
      // コード
      CD: '01',
      // 区分値名
      NAME: 'でんき・ガスマイページトップ',
      // 順番
      ORDER: 1
    },
    ELECTRICITY_CONTRACT: {
      // コード
      CD: '02',
      // 区分値名
      NAME: 'でんき契約',
      // 順番
      ORDER: 2
    },
    ELECTRICITY_INVOICE: {
      // コード
      CD: '03',
      // 区分値名
      NAME: 'でんき明細',
      // 順番
      ORDER: 3
    },
    ELECTRICITY_GRAPH: {
      // コード
      CD: '04',
      // 区分値名
      NAME: 'でんきグラフ',
      // 順番
      ORDER: 4
    },
    GAS_CONTRACT: {
      // コード
      CD: '05',
      // 区分値名
      NAME: 'ガス契約',
      // 順番
      ORDER: 5
    },
    GAS_INVOICE: {
      // コード
      CD: '06',
      // 区分値名
      NAME: 'ガス明細',
      // 順番
      ORDER: 6
    },
    GAS_GRAPH: {
      // コード
      CD: '07',
      // 区分値名
      NAME: 'ガスグラフ',
      // 順番
      ORDER: 7
    }
  };
  // 電ガス契約状況区分
  public static readonly DENGASU_CONTRACT_STATUS_CLS = {
    ONGOING: {
      // コード
      CD: '01',
      // 区分値名
      NAME: '契約中',
      // 順番
      ORDER: 1
    },
    END: {
      // コード
      CD: '02',
      // 区分値名
      NAME: '契約終了',
      // 順番
      ORDER: 2
    }
  };
  // ガス住所区分
  public static readonly GAS_CONTRACT_ADDRESS_CLS = {
    SAME: {
      // コード
      CD: '0',
      // 区分値名
      NAME: '設置場所住所と同じ',
      // 順番
      ORDER: 1
    },
    DIFFERENT: {
      // コード
      CD: '1',
      // 区分値名
      NAME: '設置場所住所と異なる',
      // 順番
      ORDER: 2
    }
  };

  public static readonly POINT_DEAL_STATUS_FROM_ECOP = {
    SUCCESS: {
      CD: '000',
      NAME: '正常'
    },
    MISSING: {
      CD: '007',
      NAME: 'ステータスエラー（紛失）'
    },
    UNSUBSCRIBE: {
      CD: '047',
      NAME: 'ステータスエラー（退会）'
    },
    EXPIRED: {
      CD: '057',
      NAME: 'ステータスエラー（有効期限切れ）'
    }
  };

  public static readonly JOB_CLS = {
    OFFICEWORKER: {
      CD: '01',
      NAME: '会社員の方'
    },
    CIVIL_SERVANT: {
      CD: '02',
      NAME: '公務員の方、学校・医療機関にお勤めの方'
    },
    SELF_EMPLOYMENT: {
      CD: '03',
      NAME: '自営業の方'
    },
    FREELANCE: {
      CD: '05',
      NAME: '自由業の方'
    },
    PART_TIME: {
      CD: '09',
      NAME: 'パート・アルバイトの方'
    },
    PENSIONER: {
      CD: '10',
      NAME: '年金が主な収入の方'
    },
    REAL_ESTATE: {
      CD: '11',
      NAME: '不動産等が主な収入の方'
    },
    UNEMPLOYED: {
      CD: '12',
      NAME: '主婦または主夫の方'
    },
    STUDENT: {
      CD: '06',
      NAME: '学生の方'
    },
    OTHER: {
      CD: '00',
      NAME: 'その他'
    }
  };

  // イベントタブ名区分
  public static readonly EVENT_TAB_NAME_CLS = {
    TOP: { CD: '1', NAME: '全て表示', PATH_NAME: 'top' },
    REGISTERED: {
      CD: '2',
      NAME: 'エントリー済を表示',
      PATH_NAME: 'registered'
    },
    ARCHIVE: { CD: '3', NAME: '過去のイベント', PATH_NAME: 'archive' }
  };
  // クレジットカード区分
  public static readonly CREDIT_CARD_CLS = {
    SAVED_CARD: {
      CD: '1',
      NAME: '保存済カード'
    },
    ANOTHER_CARD: {
      CD: '2',
      NAME: '別のカードで支払う'
    }
  };
  // イベント申込料金区分
  public static readonly EVENT_FEE_CLS = {
    DISCOUNT: {
      CD: '1',
      NAME: '特別料金'
    },
    GENERAL: {
      CD: '2',
      NAME: '通常料金'
    }
  };
  // サブスク会員状態区分
  public static readonly SUBSCRIPTION_STATUS_CLS = {
    NEW_REGISTER_ADDRESS_NOT_EXISTS: {
      CD: '00',
      NAME: '新規登録(過去に同一アドレス無し)'
    },
    NEW_REGISTER_ADDRESS_EXISTS: {
      CD: '01',
      NAME: '登録なし(同一メールアドレスによる登録経験あり)'
    },
    REGISTERING: {
      CD: '02',
      NAME: '登録中'
    },
    RE_REGISTER: {
      CD: '03',
      NAME: '登録なし(同一 UID による登録経験あり)'
    }
  };
  // サブスク契約 決済エラー区分
  public static readonly SUBSCRIPTION_PAY_ERROR_CLS = {
    NORMAL: {
      CD: '00',
      NAME: '正常'
    },
    OTHER_ERROR: {
      CD: '01',
      NAME: 'その他の障害'
    },
    USER_ERROR: {
      CD: '02',
      NAME: 'ユーザ責エラー'
    },
    SERVER_ERROR: {
      CD: '03',
      NAME: 'サーバエラー'
    }
  };
}
