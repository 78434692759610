import { fetchListContents, fetchListContentsOptions } from '@/common/cms';
import { LargeCarouselResponse, SmallCarouselResponse } from '@/models/cms';
import { LargeCarouselItem } from '@/models/LargeCarouselItem';
import { SmallCarouselItem } from '@/models/SmallCarouselItem';
import {
  ILargeCarouselRepository,
  ISmallCarouselRepository,
  SmallCarouselRepositoryOption
} from '@/repositories/interface/ICarouselRepository';

/**
 * @classdesc 大カルーセルの情報を取得する
 */
export class LargeCarouselRepository implements ILargeCarouselRepository {
  private readonly PATH = 'large-carousels';

  /**
   * @constructor
   */
  public constructor(private readonly supportsWebP: boolean) {}

  /**
   * 大カルーセルを取得する
   * @returns { LargeCarousel[] }
   */
  public async get() {
    try {
      const { data } = await fetchListContents<LargeCarouselResponse>(
        this.PATH
      );
      const { contents } = data;
      return this.convertResponseToDomainObject(contents);
    } catch (_) {
      return [];
    }
  }

  private convertResponseToDomainObject(
    responseItems: LargeCarouselResponse[]
  ) {
    return responseItems.map(e =>
      LargeCarouselItem.valueOf(e, this.supportsWebP)
    );
  }
}

/**
 * @classdesc 小カルーセルの情報を取得する
 */
export class SmallCarouselRepository implements ISmallCarouselRepository {
  private readonly PATH = 'small-carousels';
  private readonly DEFAULT = {};

  /**
   * @constructor
   */
  public constructor(
    private readonly isSP: boolean,
    private readonly supportsWebP: boolean
  ) {}

  /**
   * MicroCMSのAPIリクエストオプションを作成する。
   * @returns {fetchListContentsOptions}
   * @param option {SmallCarouselRepositoryOption}
   */
  private generateRequestOption(option?: SmallCarouselRepositoryOption) {
    const requestOption: fetchListContentsOptions = this.DEFAULT;

    if (option?.showPage) {
      switch (option.showPage) {
        case 'MyPage':
          requestOption.filters = 'showMypage[equals]true';
          break;
        case 'TopPage':
          requestOption.filters = 'showToppage[equals]true';
          break;
      }
    }

    if (option?.limit) {
      requestOption.limit = option.limit;
    }

    return requestOption;
  }

  /**
   * 小カルーセルを取得する
   * @returns { SmallCarousel[] }
   */
  public async get(option?: SmallCarouselRepositoryOption) {
    const requestOption = this.generateRequestOption(option);

    try {
      const { data } = await fetchListContents<SmallCarouselResponse>(
        this.PATH,
        requestOption
      );
      const { contents } = data;
      return this.convertResponseToDomainObject(contents);
    } catch (_) {
      return [];
    }
  }

  private convertResponseToDomainObject(
    responseItems: SmallCarouselResponse[]
  ) {
    return responseItems.map(e =>
      SmallCarouselItem.valueOf(e, this.isSP, this.supportsWebP)
    );
  }
}
