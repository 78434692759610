import Vue from 'vue';
import { Validator } from 'vee-validate';
// @ts-ignore: implicitly has an 'any' type.
import VeeValidateJaLocale from 'vee-validate/dist/locale/ja';
import messages from './validators/messages';
import full_width_hira from './validators/full_width_hira';
import full_width_kana from './validators/full_width_kana';
import jis_x_0208 from './validators/jis_x_0208';
import full_width_chars from './validators/full_width_chars';
import allowed_email_domain from './validators/allowed_email_domain';

export default class Validate extends Vue {
  /**
   * 初期処理
   */
  public static init(): void {
    this.setLocale();
    this.extendValidators();
  }

  /**
   * ロケールの設定
   */
  private static setLocale(): void {
    // メッセージを日本語に設定
    Validator.localize('ja', VeeValidateJaLocale);
    Validator.localize(messages);
  }

  /**
   * カスタムルールの適用
   */
  private static extendValidators(): void {
    Validator.extend('full_width_hira', full_width_hira);
    Validator.extend('full_width_kana', full_width_kana);
    Validator.extend('jis_x_0208', jis_x_0208);
    Validator.extend('full_width_chars', full_width_chars);
    Validator.extend('allowed_email_domain', allowed_email_domain);
  }
}
