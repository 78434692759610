import { NewsResponse } from '@/models/cms/news';
import {
  fetchListContents,
  fetchListContentsOptions,
  selectContent
} from '@/common/cms';

import { NewsItem } from '@/models/NewsItem';

import {
  NewsOption,
  INewsRepository
} from '@/repositories/interface/INewsRepository';

/**
 * @classdesc お知らせの情報を取得する
 */
export class NewsRepository implements INewsRepository {
  private readonly PATH = 'news';
  private readonly LIMIT = 15;
  private readonly ORDER_LIST = '-publishedAt'; // 公開日降順
  private readonly DEFAULT = {
    limit: this.LIMIT,
    orders: this.ORDER_LIST
  };

  /**
   * @constructor
   */
  public constructor(
    private readonly isSP: boolean,
    private readonly supportsWebP: boolean
  ) {}

  /**
   * microCMSのAPIリクエストオプションを作成する。
   * @returns {fetchListContentsOptions}
   * @param option {NewsRepositoryOption}
   */
  private generateRequestOption(option?: NewsOption) {
    let requestOption: fetchListContentsOptions = this.DEFAULT;

    if (option?.showPage) {
      switch (option.showPage) {
        case 'RegardlessPage':
          requestOption.filters = 'showRegardlessPage[equals]true';
          break;
        case 'LoggedInPage':
          requestOption.filters = 'showLoggedInPage[equals]true';
          break;
      }
    }

    if (option?.limit) {
      requestOption.limit = option.limit;
    }

    if (option?.orders) {
      requestOption.orders = option.orders;
    }

    return requestOption;
  }

  /**
   * お知らせリストを取得する
   * @returns { NewsResponse[] }
   */
  public async get(option?: NewsOption) {
    const requestOption = this.generateRequestOption(option);

    try {
      const { data } = await fetchListContents<NewsResponse>(
        this.PATH,
        requestOption
      );
      const { contents } = data;
      return contents.map(e => this.convertResponseToDomainObject(e));
    } catch (_) {
      return [];
    }
  }

  /**
   * お知らせをID指定で取得する
   * @returns { NewsResponse }
   */
  public async select(id: string) {
    try {
      const { data } = await selectContent<NewsResponse>(this.PATH, id);
      return this.convertResponseToDomainObject(data);
    } catch (_) {
      return null;
    }
  }

  private convertResponseToDomainObject(response: NewsResponse) {
    return NewsItem.valueOf(response, this.isSP, this.supportsWebP);
  }
}
