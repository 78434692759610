import dayjs from 'dayjs';
import numeral from 'numeral';
import moji from 'moji';

const FULL_TO_HALF_CHARCODE = 65248; //全角英数から半角英数に変更するための文字コード用の数字

const dateFuncs = {
  /**
   * 整形された日付文字列を返す。
   * 例: "2019-09-10T12:34:56+09:00" → "2019年09月"
   *
   * @param {string} val 整形対象の値
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された日付文字列
   */
  formatYm(val: string, nullVal = ''): string {
    if (val == null) {
      return nullVal;
    }
    return dayjs(val).format('YYYY年MM月');
  },
  /**
   * 整形された日付文字列を返す。
   * 例: "2019-09-10T12:34:56+09:00" → "2019/09"
   *
   * @param {string} val 整形対象の値
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された日付文字列
   */
  formatYmSlash(val: string, nullVal = ''): string {
    if (val == null) {
      return nullVal;
    }
    return dayjs(val).format('YYYY/MM');
  },
  /**
   * 整形された日付文字列を返す。
   * 例: "2019-09-10T12:34:56+09:00" → "2019年09月10日"
   *
   * @param {string} val 整形対象の値
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された日付文字列
   */
  formatYmd(val: string, nullVal = ''): string {
    if (val == null) {
      return nullVal;
    }
    return dayjs(val).format('YYYY年MM月DD日');
  },
  /**
   * 整形された日付文字列を返す。
   * 例: "2019-09-10T12:34:56+09:00" → "2019/09/10"
   *
   * @param {string} val 整形対象の値
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された日付文字列
   */
  formatYmdSlash(val: string, nullVal = ''): string {
    if (val == null) {
      return nullVal;
    }
    return dayjs(val).format('YYYY/MM/DD');
  },
  /**
   * 整形された日付文字列を返す。
   * 例: "2019-09-10T12:34:56+09:00" → "2019.9.10"
   *
   * @param {string} val 整形対象の値
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された日付文字列
   */
  formatYmdDot(val: string, nullVal = ''): string {
    return val ? dayjs(val).format('YYYY.M.D') : nullVal;
  },
  /**
   * 整形された日付文字列を返す。
   * 例: "2019-09-02T12:34:56+09:00" → "2019.09.02"
   *
   * @param {string} val 整形対象の値
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された日付文字列
   */
  formatYmdDotWithZero(val: string, nullVal = ''): string {
    return val ? dayjs(val).format('YYYY.MM.DD') : nullVal;
  },
  /**
   * 整形された日付文字列を返す。
   * 例: "2019-09-10T12:34:56+09:00" → "9月"
   * @param {string} val 整形対象の値
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された日付文字列
   */
  formatM(val: string, nullVal = '') {
    return val ? dayjs(val).format('M月') : nullVal;
  },
  /**
   * 整形された日付文字列を返す。
   * 例: "2019-09-10T12:34:56+09:00" → "9月10日"
   * @param {string} val 整形対象の値
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された日付文字列
   */
  formatMD(val: string, nullVal = '') {
    return val ? dayjs(val).format('M月D日') : nullVal;
  },
  /**
   * 整形された日付文字列を返す。
   * 例: "2019-09-10T12:34:56+09:00" → "2019.9.10"
   *
   * @param {string} val 整形対象の値
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された日付文字列
   */
  formatYMDDot(val: string, nullVal = ''): string {
    const FORMAT = 'YYYY.M.D';
    return dayjs(val).isValid() ? dayjs(val).format(FORMAT) : nullVal;
  },

  /**
   * 曜日を日本語で返す。
   * 例: "2019-09-10T12:34:56+09:00" → "火"
   * @param {string} val 評価対象の値
   * @param {string} nullVal 評価対象がnullの場合の表示文字列
   * @return {string} 曜日文字列
   */
  formatDayOfWeek(val: string, nullVal = ''): string {
    if (val == null) {
      return nullVal;
    }
    switch (dayjs(val).day()) {
      case 0:
        return '日';
      case 1:
        return '月';
      case 2:
        return '火';
      case 3:
        return '水';
      case 4:
        return '木';
      case 5:
        return '金';
      case 6:
        return '土';
      default:
        return nullVal;
    }
  }
};

const timeFuncs = {
  /**
   * 整形された時刻文字列を返す。
   * 例: "2019-09-10T12:34:56+09:00" → "12時34分"
   *
   * @param {string} val 整形対象の値
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された時刻文字列
   */
  formatHm(val: string, nullVal = ''): string {
    if (val == null) {
      return nullVal;
    }
    return dayjs(val).format('HH時mm分');
  },
  /**
   * 整形された時刻文字列を返す。
   * 例: "2019-09-10T12:34:56+09:00" → "12:34"
   *
   * @param {string} val 整形対象の値
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された時刻文字列
   */
  formatHmColon(val: string, nullVal = ''): string {
    if (val == null) {
      return nullVal;
    }
    return dayjs(val).format('HH:mm');
  },
  /**
   * 整形された時刻文字列を返す。
   * 例: "2019-09-10T12:34:56+09:00" → "12時34分56秒"
   *
   * @param {string} val 整形対象の値
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された時刻文字列
   */
  formatHms(val: string, nullVal = ''): string {
    if (val == null) {
      return nullVal;
    }
    return dayjs(val).format('HH時mm分ss秒');
  },
  /**
   * 整形された時刻文字列を返す。
   * 例: "2019-09-10T12:34:56+09:00" → "12:34:56"
   *
   * @param {string} val 整形対象の値
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された時刻文字列
   */
  formatHmsColon(val: string, nullVal = ''): string {
    if (val == null) {
      return nullVal;
    }
    return dayjs(val).format('HH:mm:ss');
  }
};

const dateTimeFuncs = {
  /**
   * 整形された日付時刻文字列を返す。
   * 例: "2019-09-10T12:34:56+09:00" → "2019年09月10日 12時34分"
   *
   * @param {string} val 整形対象の値
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された日付時刻文字列
   */
  formatYmdHm(val: string, nullVal = ''): string {
    if (val == null) {
      return nullVal;
    }
    return dayjs(val).format('YYYY年MM月DD日 HH時mm分');
  },
  /**
   * 整形された日付時刻文字列を返す。
   * 例: "2019-09-10T12:34:56+09:00" → "2019年09月10日 12:34"
   *
   * @param {string} val 整形対象の値
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された日付時刻文字列
   */
  formatYmdHmColon(val: string, nullVal = ''): string {
    if (val == null) {
      return nullVal;
    }
    return dayjs(val).format('YYYY年MM月DD日 HH:mm');
  },
  /**
   * 整形された日付時刻文字列を返す。
   * 例: "2019-09-10T12:34:56+09:00" → "2019/09/10 12:34"
   *
   * @param {string} val 整形対象の値
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された日付時刻文字列
   */
  formatYmdHmSlash(val: string, nullVal = ''): string {
    if (val == null) {
      return nullVal;
    }
    return dayjs(val).format('YYYY/MM/DD HH:mm');
  },
  /**
   * 整形された日付時刻文字列を返す。
   * 例: "2019-09-10T12:34:56+09:00" → "2019年09月10日 12時34分56秒"
   *
   * @param {string} val 整形対象の値
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された日付時刻文字列
   */
  formatYmdHms(val: string, nullVal = ''): string {
    if (val == null) {
      return nullVal;
    }
    return dayjs(val).format('YYYY年MM月DD日 HH時mm分ss秒');
  },
  /**
   * 整形された日付時刻文字列を返す。
   * 例: "2019-09-10T12:34:56+09:00" → "2019/09/10 12:34:56"
   *
   * @param {string} val 整形対象の値
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された日付時刻文字列
   */
  formatYmdHmsSlash(val: string, nullVal = ''): string {
    if (val == null) {
      return nullVal;
    }
    return dayjs(val).format('YYYY/MM/DD HH:mm:ss');
  }
};

const numeralFuncs = {
  /**
   * 整形された数値文字列を返す。
   * ただし、引数が数値以外の文字列の場合、"0"を返す。
   * 例: "1234567" → "1,234,567", "abc" → "0"
   *     小数点以下桁数を2桁に指定した場合 "1234567" → "1,234,567.00"
   *
   * @param {string|number} val 整形対象の値
   * @param {number} scale 小数点以下桁数
   * @param {string} nullVal 整形対象はnullかundefinedの場合の表示文字列
   * @return {string} 整形された数値文字列
   */
  formatNumeral(val: string | number, scale: number = 0, nullVal = ''): string {
    if (val == null) {
      return nullVal;
    }
    const numeralFormats: any = { 0: '0,0' };
    const numeralFormat =
      numeralFormats[scale] ||
      (numeralFormats[scale] = '0,0.' + '0'.repeat(scale));
    return numeral(val).format(numeralFormat);
  }
};

const stringFuncs = {
  /**
   * 半角大文字の文字列を返す。
   *
   * @param {string} val 整形対象の値
   */
  formatToHalfUpperCase(val: string): string {
    return val
      .replace(
        /[Ａ-Ｚａ-ｚ０-９]/g,
        (s: string) =>
          String.fromCharCode(s.charCodeAt(0) - FULL_TO_HALF_CHARCODE) //全角英数の文字コードから65248個前が半角英数の文字コードである為、文字コードから65248を引く。
      )
      .toUpperCase();
  },
  /**
   * 半角文字を全角文字に変換した文字列を返す。
   *
   * @param {string} val 整形対象の値
   */
  formatHalfWidthToFullWidth(val: string): string {
    return moji(val)
      .convert('HE', 'ZE') //半角英数記号→全角英数記号
      .convert('HK', 'ZK') //半角ｶﾅ→全角カナ
      .convert('HS', 'ZS') //半角スペース→全角スペース
      .toString()
      .replace(
        //半角ハイフン系→全角ハイフン
        /[\ufe63\u2043\u2011-\u2014\ufe58\u23af\u23e4\u02d7\u2796\u207b\u208B]/g,
        '－'
      );
  },
  /**
   * カタカナをひらがなに変換した文字列を返す。
   *
   * @param {string} val 整形対象の値
   */
  formatKanaToHira(val: string): string {
    return moji(val)
      .convert('HK', 'ZK') //半角ｶﾅ→全角カナ
      .convert('KK', 'HG') //カタカナ→ひらがな
      .toString();
  }
};

export default class FilterUtils {
  public static funcs: any = Object.assign(
    {},
    dateFuncs,
    timeFuncs,
    dateTimeFuncs,
    numeralFuncs,
    stringFuncs
  );
}
