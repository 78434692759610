




































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CouponItem } from '@/models/CouponItem';

@Component
export default class CouponCard extends Vue {
  @Prop() public coupon!: CouponItem;
}
