import { ResCurrentUser } from '@/gen';
import { IUserForm } from './IUserForm';

export class UserForm implements IUserForm {
  lastName!: string;
  firstName!: string;
  hiraLastName!: string;
  hiraFirstName!: string;
  genderTyp!: string;
  zipcode1!: string;
  zipcode2!: string;
  pref!: string;
  city!: string;
  town!: string;
  building!: string;
  promoMailFromOneReceiveFlg!: boolean;
  promoMailFromOpCardReceiveFlg!: boolean;
  birthYear!: string;
  birthMonth!: string;
  birthDay!: string;
  phone1_1!: string;
  phone1_2!: string;
  phone1_3!: string;
  phone2_1?: string;
  phone2_2?: string;
  phone2_3?: string;
  shokugyouTyp!: string;
}

export class UserSignupForm extends UserForm {
  // 新規登録時、小田急お客さま番号 or OPデジタルカードを登録する
  opCardNum1!: string;
  opCardNum2!: string;
  opCardNum3!: string;
  securityCode!: string;

  constructor() {
    super();
    this.firstName = '';
    this.lastName = '';
    this.hiraLastName = '';
    this.hiraFirstName = '';
    this.genderTyp = '';
    this.zipcode1 = '';
    this.zipcode2 = '';
    this.pref = '';
    this.city = '';
    this.town = '';
    this.building = '';
    this.promoMailFromOneReceiveFlg = true;
    this.promoMailFromOpCardReceiveFlg = true;
    this.birthYear = '';
    this.birthMonth = '';
    this.birthDay = '';
    this.phone1_1 = '';
    this.phone1_2 = '';
    this.phone1_3 = '';
    this.phone2_1 = '';
    this.phone2_2 = '';
    this.phone2_3 = '';
    this.shokugyouTyp = '';
    this.opCardNum1 = '';
    this.opCardNum2 = '';
    this.opCardNum3 = '';
    this.securityCode = '';
  }
}

export class UserEditForm extends UserForm {
  constructor(data: ResCurrentUser) {
    super();
    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.hiraFirstName = data.hira_first_name;
    this.hiraLastName = data.hira_last_name;
    this.genderTyp = data.gender_typ;
    this.zipcode1 = data.zipcode1;
    this.zipcode2 = data.zipcode2;
    this.pref = data.pref;
    this.city = data.city;
    this.town = data.town;
    this.building = data.building;
    this.promoMailFromOneReceiveFlg = data.promo_mail_from_one_receive_flg;
    this.promoMailFromOpCardReceiveFlg =
      data.promo_mail_from_op_card_receive_flg;
    this.birthYear = data.birth_year;
    this.birthMonth = data.birth_month;
    this.birthDay = data.birth_day;
    this.phone1_1 = data.phone1_1;
    this.phone1_2 = data.phone1_2;
    this.phone1_3 = data.phone1_3;
    this.phone2_1 = data.phone2_1 ? data.phone2_1 : '';
    this.phone2_2 = data.phone2_2 ? data.phone2_2 : '';
    this.phone2_3 = data.phone2_3 ? data.phone2_3 : '';
    this.shokugyouTyp = data.shokugyou_typ;
  }
}

export class UserInfoForAutoInput extends UserForm {
  odakyuCustomerNo: string;
  constructor(data: any) {
    super();
    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.hiraFirstName = data.hira_first_name;
    this.hiraLastName = data.hira_last_name;
    this.genderTyp = data.gender_typ;
    this.zipcode1 = data.zipcode1;
    this.zipcode2 = data.zipcode2;
    this.pref = data.pref;
    this.city = data.city;
    this.town = data.town;
    this.building = data.building;
    this.promoMailFromOneReceiveFlg = true;
    this.promoMailFromOpCardReceiveFlg = true;
    this.birthYear = data.birth_year;
    this.birthMonth = data.birth_month;
    this.birthDay = data.birth_day;
    this.phone1_1 = data.phone1_1;
    this.phone1_2 = data.phone1_2;
    this.phone1_3 = data.phone1_3;
    this.shokugyouTyp = data.shokugyou_typ;
    this.odakyuCustomerNo = data.odakyu_customer_no;
  }
}

export class OpappAirCard {
  odakyu_customer_no: string;
  security_cd: string;
  constructor(odakyu_customer_no: string, security_cd: string) {
    this.odakyu_customer_no = odakyu_customer_no || '';
    this.security_cd = security_cd || '';
  }
}
