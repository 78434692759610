import { fetchListContents, fetchListContentsOptions } from '@/common/cms';
import { ArticleResponse } from '@/models/cms/article';
import { ArticleItem } from '@/models/ArticleItem';

export class ArticleRepository {
  private readonly PATH = 'article';
  public constructor(
    private readonly isSP: boolean,
    private readonly supportsWebP: boolean
  ) {}

  /**
   * 一覧取得
   */
  public async get() {
    //「トップページおよび小田急ポイントページおよび一覧ページへ掲載しない」にチェックが入っているものは取り込まない
    const requestOption: fetchListContentsOptions = {
      filters: `isNotShow[equals]false`
    };

    try {
      const { data } = await fetchListContents<ArticleResponse>(
        this.PATH,
        requestOption
      );
      const { contents } = data;
      return this.convertResponseToDomainObject(contents);
    } catch (_) {
      return new Array<ArticleItem>();
    }
  }

  /**
   * コンテンツの単体取得
   */
  public async select(id: string) {
    const requestOption: fetchListContentsOptions = {
      filters: `id[equals]${id}`
    };

    try {
      const { data } = await fetchListContents<ArticleResponse>(
        this.PATH,
        requestOption
      );
      const { contents } = data;
      return contents.length
        ? ArticleItem.valueOf(contents[0], this.isSP, this.supportsWebP)
        : null; // 先頭を返す
    } catch (_) {
      // selectメソッドの呼び出し元では返却された値がもしnullであれば `/notfound ` に遷移させる処理を行う。
      return null;
    }
  }

  private convertResponseToDomainObject(responseItems: ArticleResponse[]) {
    return responseItems.map(e =>
      ArticleItem.valueOf(e, this.isSP, this.supportsWebP)
    );
  }
}
