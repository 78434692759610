import { fetchListContents, fetchListContentsOptions } from '@/common/cms';
import { EventResponse } from '@/models/cms/event';
import { EventItem } from '@/models/EventItem';
import { EventListItem } from '@/models/EventListItem';
import { IEventCmsRepository } from '@/repositories/interface/IEventCmsRepository';
import classification from '@/common/classification';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
const TIMEZONE_UTC = 'UTC';

export class EventCmsRepository implements IEventCmsRepository {
  private readonly PATH = 'events';
  private readonly LIMIT = 10;
  public constructor(
    private readonly isSP: boolean,
    private readonly supportsWebP: boolean
  ) {}

  public static empty() {
    return new Array<EventItem>();
  }

  /**
   * イベント情報を取得する(イベント履歴画面用)
   */
  public async getList(eventIDList: string[]) {
    let filtersList = '';
    eventIDList.forEach((i, idx, array) => {
      // 最後の場合、[or]を付けない
      if (idx === array.length - 1) {
        filtersList += 'eventID[equals]' + i;
        return;
      }
      filtersList += 'eventID[equals]' + i + '[or]';
    });
    try {
      const { data } = await fetchListContents<EventResponse>(this.PATH, {
        limit: this.LIMIT,
        filters: filtersList
      });
      const { contents } = data;
      return this.convertResponseToDomainObject(contents);
    } catch (_) {
      return new Array<EventItem>();
    }
  }
  private convertResponseToDomainObject(response: EventResponse[]) {
    return response.map(e =>
      EventItem.valueOf(e, this.isSP, this.supportsWebP)
    );
  }

  /**
   * イベントの単体取得(イベント詳細画面用)
   * @param eventID イベントID
   */
  public async select(eventID: string) {
    try {
      const { data } = await fetchListContents<EventResponse>(this.PATH, {
        filters: `eventID[equals]${eventID}`
      });

      const { contents } = data;
      return contents.length
        ? EventItem.valueOf(contents[0], this.isSP, this.supportsWebP)
        : null; // 先頭を返す
    } catch (_) {
      return null;
    }
  }

  /**
   * イベント情報をページ単位で取得する(イベント一覧画面、過去イベント一覧画面用)
   */
  public async getPage(limit: number, offSet: number, pathName: string) {
    const requestOption: fetchListContentsOptions = {
      limit: limit,
      offset: offSet
    };

    // 全て表示(終了年月日>=現在時刻のイベント)
    if (pathName === classification.EVENT_TAB_NAME_CLS.TOP.PATH_NAME) {
      // microCMSへのAPIリクエストのクエリパラメータ（filter）としてJSTのタイムゾーン付きtimestampを送った場合、
      // microCMS側でタイムゾーン情報を無視してUTCとして取り扱われる
      // そのため、filterへ時刻を設定する際UTCにする必要がある
      requestOption.filters =
        'privateFlg[not_equals]true[and](entryEndDateTime[greater_than]' +
        this.getCurrentTimeUTC() +
        '[or]entryEndDateTime[equals]' +
        this.getCurrentTimeUTC() +
        ')';
      // 過去のイベント(終了年月日<現在時刻のイベント)
    } else if (
      pathName === classification.EVENT_TAB_NAME_CLS.ARCHIVE.PATH_NAME
    ) {
      // microCMSへのAPIリクエストのクエリパラメータ（filter）への時刻設定のため、UTCで設定
      requestOption.filters =
        'privateFlg[not_equals]true[and]entryEndDateTime[less_than]' +
        this.getCurrentTimeUTC();
      requestOption.orders = '-eventDateTime,-publishedAt'; // 開催日降順、公開日降順
    }
    try {
      const { data } = await fetchListContents<EventResponse>(
        this.PATH,
        requestOption
      );
      const { contents, totalCount } = data;
      return contents.length
        ? EventListItem.valueOf(
            contents,
            totalCount,
            this.isSP,
            this.supportsWebP
          )
        : null;
    } catch (_) {
      return null;
    }
  }

  /**
   * 現在時刻をUTCで取得する
   */
  private getCurrentTimeUTC() {
    return dayjs()
      .tz(TIMEZONE_UTC)
      .format();
  }
}
