/**
 * 住所項目に関連するフォームにおいて、OPアプリ対応で半角文字が入らなくなるため、
 * 半角全角が混在したデータがDBへ入らないようにする
 */
const full_width_chars: any = {
  validate: (value: any): boolean => {
    if (Array.isArray(value)) {
      return value.every((v: any) => full_width_chars.validate(v));
    }
    if (value === null || value === undefined || value === '') {
      return true;
    }
    if (typeof value !== 'string') {
      return false;
    }
    return Array.prototype.every.call(value, full_width_chars.contains);
  },
  contains: (c: string): boolean => {
    // 文字列が全て全角だったらtrueを返す
    return /[^\x01-\x7E\uFF65-\uFF9F]/.test(c);
  }
};
export default full_width_chars;
