




























import { Component, Prop, Vue } from 'vue-property-decorator';
import { ArticleItem } from '@/models/ArticleItem';

@Component
export default class ArticleBlock extends Vue {
  @Prop() public data!: ArticleItem;
}
