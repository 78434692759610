import { LargeCarouselResponse, Image } from '@/models/cms';
import { convertOptimizedUrl } from '@/common/cmsUtils';
export class LargeCarouselItem {
  static valueOf(v: LargeCarouselResponse, supportsWebP: boolean) {
    return new LargeCarouselItem(
      supportsWebP,
      v.id,
      v.imgPc,
      v.imgSp,
      v.link,
      v.createdAt,
      v.updatedAt,
      v.publishedAt,
      v.alt
    );
  }

  private constructor(
    private readonly supportsWebP: boolean,
    readonly id: string,
    readonly imgPc: Image,
    readonly imgSp: Image,
    readonly link: string,
    readonly createdAt: string,
    readonly updatedAt: string,
    readonly publishedAt: string,
    readonly alt?: string
  ) {}

  get imgPcUrl() {
    return convertOptimizedUrl(this.imgPc, false, this.supportsWebP);
  }

  get imgSpUrl() {
    return convertOptimizedUrl(this.imgSp, true, this.supportsWebP);
  }
}
