import { fetchListContents, fetchListContentsOptions } from '@/common/cms';
import { CampaignResponse } from '@/models/cms/campaign';
import { CampaignItem } from '@/models/CampaignItem';
import { CampaignListItem } from '@/models/CampaignListItem';
import { ICampaignRepository } from '@/repositories/interface/ICampaignRepository';

export class CampaignRepository implements ICampaignRepository {
  private readonly PATH = 'campaign';
  private readonly FETCH_CONTENTS_LIMIT = 1;
  private readonly HIDDEN_ON_LIST = 'hiddenOnList[equals]false';
  private readonly ORDER_LIST = '-publishedAt'; // 公開日降順

  public constructor(
    private readonly isSP: boolean,
    private readonly supportsWebP: boolean
  ) {}

  /**
   * キャンペーンの単体取得
   */
  public async select(campaignId: string) {
    const requestOption: fetchListContentsOptions = {
      limit: this.FETCH_CONTENTS_LIMIT,
      filters: `campaignId[equals]${campaignId}`
    };

    try {
      const { data } = await fetchListContents<CampaignResponse>(
        this.PATH,
        requestOption
      );
      const { contents } = data;
      return contents.length
        ? CampaignItem.valueOf(contents[0], this.isSP, this.supportsWebP)
        : null; // 先頭を返す
    } catch (_) {
      // selectメソッドの呼び出し元では返却された値がもしnullであれば `/notfound ` に遷移させる処理を行う。
      return null;
    }
  }

  /**
   * キャンペーンの一覧取得
   */
  public async get(limit: number, offSet: number) {
    const requestOption: fetchListContentsOptions = {
      limit: limit,
      offset: offSet,
      filters: this.HIDDEN_ON_LIST,
      orders: this.ORDER_LIST
    };

    try {
      const { data } = await fetchListContents<CampaignResponse>(
        this.PATH,
        requestOption
      );
      const { contents, totalCount } = data;
      return contents.length
        ? CampaignListItem.valueOf(
            contents,
            totalCount,
            this.isSP,
            this.supportsWebP
          )
        : null;
    } catch (_) {
      return null;
    }
  }
}
