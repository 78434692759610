/**
 * 拡張validatorの各メッセージ定義
 */
export default {
  ja: {
    messages: {
      digits(field: string, e: any) {
        return e[0] + '桁の半角数字でご入力ください';
      },
      full_width_hira(field: string) {
        return `${field}はひらがなのみ使用できます`;
      },
      full_width_kana(field: string) {
        return `${field}は全角カナのみ使用できます`;
      },
      numeric(field: string) {
        return `${field}は半角数字のみ使用できます`;
      },
      integer(field: string) {
        return `${field}は半角数字の整数のみ使用できます`;
      },
      jis_x_0208(field: string) {
        return `${field}に使用できない文字が含まれています`;
      },
      full_width_chars(field: string) {
        return `${field}は全角文字のみ使用できます`;
      },
      length(field: string, e: any) {
        return `${field}は` + e[0] + '桁でご入力ください';
      },
      allowed_email_domain() {
        return 'このメールアドレスは登録できません。@以降が正しいか、ご確認ください。';
      }
    },
    custom: {
      newPassword: {
        regex: 'パスワードの入力規則に従っていません'
      },
      newPasswordConfirm: {
        regex: 'パスワードの入力規則に従っていません'
      }
    }
  }
};
