import Vue from 'vue';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import AuthenticationError from '@auth0/auth0-spa-js/dist/typings/errors';
import { Auth0ClientThruOpApp } from './auth0ClientThruOpApp';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);
const ADDITIONAL_SCOPE: string =
  'read:current_user update:current_user_metadata';

// 型定義をVueにすると「Property 'xxx' does not exist on type 'Vue'.」エラーになるためあえてanyにする
let instance: any;

export const getInstance = () => instance;

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}: any) => {
  if (instance) {
    return instance;
  }

  instance = new Vue({
    data(): {
      loading: boolean;
      isAuthenticated: boolean;
      user: any;
      auth0Client: Auth0Client | Auth0ClientThruOpApp | null;
      popupOpen: boolean;
      error: typeof AuthenticationError | null;
    } {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null
      };
    },
    methods: {
      async loginWithPopup(o: any) {
        // 利用箇所が存在しないため、OPアプリSSO対応は未実施。
        if (this.auth0Client == null) {
          return;
        }

        this.popupOpen = true;
        try {
          await this.auth0Client.loginWithPopup(o);
        } catch (e) {
          // auth0 error
        } finally {
          this.popupOpen = false;
        }

        this.user = await this.auth0Client.getUser();
        this.isAuthenticated = true;
      },
      async handleRedirectCallback() {
        // 利用箇所が存在しないため、OPアプリSSO対応は未実施。
        if (this.auth0Client == null) {
          return;
        }

        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      loginWithRedirect(o: any) {
        if (this.auth0Client == null) {
          return;
        }
        return this.auth0Client.loginWithRedirect(o);
      },
      getIdTokenClaims(o: any) {
        // 利用箇所が存在しないため、OPアプリSSO対応は未実施。
        if (this.auth0Client == null) {
          return;
        }
        return this.auth0Client.getIdTokenClaims(o);
      },
      async getTokenSilently(o: any) {
        if (this.auth0Client == null) {
          return;
        }
        const token = await this.auth0Client.getTokenSilently(o);
        this.user = await this.auth0Client.getUser();
        return token;
      },
      getTokenWithPopup(o: any) {
        // 利用箇所が存在しないため、OPアプリSSO対応は未実施。
        if (this.auth0Client == null) {
          return;
        }
        return this.auth0Client.getTokenWithPopup(o);
      },
      async getToken(o: any) {
        if (this.auth0Client == null) {
          return;
        }
        try {
          return await this.auth0Client.getTokenSilently(o);
        } catch (error) {
          return await this.auth0Client.getTokenWithPopup(o);
        }
      },
      logout(o: any) {
        if (this.auth0Client == null) {
          return;
        }
        return this.auth0Client.logout(o);
      },
      isOpApp() {
        if (this.auth0Client == null) {
          return false;
        }
        return 'isOpApp' in this.auth0Client ? this.auth0Client.isOpApp : false;
      }
    },
    async created() {
      this.auth0Client =
        createAuth0ClientForAndroidOPApp() ||
        createAuth0ClientForIosOPApp() ||
        (await createAuth0Client({
          domain: options.domain,
          client_id: options.clientId,
          audience: options.audience,
          redirect_uri: redirectUri,
          scope: ADDITIONAL_SCOPE
        }));
      try {
        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          // SSO対応によりOPアプリWebviewでログイン及びそれに伴うリダイレクトは行われないため、OPアプリWebviewでのアクセスの場合、この分岐に入ることはない
          const { appState } = await this.auth0Client.handleRedirectCallback();
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.user = await this.auth0Client.getUser();
        this.loading = false;
      }
    }
  });
  return instance;
};

const createAuth0ClientForAndroidOPApp = () => {
  if (
    window.OPApp &&
    window.OPApp.refreshToken &&
    window.OPApp.closeWindow &&
    window.OPApp.closeWindowAndLogout
  ) {
    return new Auth0ClientThruOpApp(
      (requestOptions: string) => {
        if (window.OPApp && window.OPApp.refreshToken) {
          window.OPApp.refreshToken(requestOptions);
        }
      },
      (message: string) => {
        if (window.OPApp && window.OPApp.closeWindow) {
          window.OPApp.closeWindow(message);
        }
      },
      (message: string) => {
        if (window.OPApp && window.OPApp.closeWindowAndLogout) {
          window.OPApp.closeWindowAndLogout(message);
        }
      }
    );
  }
  return null;
};
const createAuth0ClientForIosOPApp = () => {
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.refreshToken &&
    window.webkit.messageHandlers.refreshToken.postMessage &&
    window.webkit.messageHandlers.closeWindow &&
    window.webkit.messageHandlers.closeWindow.postMessage &&
    window.webkit.messageHandlers.closeWindowAndLogout &&
    window.webkit.messageHandlers.closeWindowAndLogout.postMessage
  ) {
    return new Auth0ClientThruOpApp(
      (requestOptions: string) => {
        if (
          window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.refreshToken &&
          window.webkit.messageHandlers.refreshToken.postMessage
        ) {
          window.webkit.messageHandlers.refreshToken.postMessage(
            requestOptions
          );
        }
      },
      (message: string) => {
        if (
          window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.closeWindow &&
          window.webkit.messageHandlers.closeWindow.postMessage
        ) {
          window.webkit.messageHandlers.closeWindow.postMessage(message);
        }
      },
      (message: string) => {
        if (
          window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.closeWindowAndLogout &&
          window.webkit.messageHandlers.closeWindowAndLogout.postMessage
        ) {
          window.webkit.messageHandlers.closeWindowAndLogout.postMessage(
            message
          );
        }
      }
    );
  }
  return null;
};
