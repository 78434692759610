import { NewsResponse } from '@/models/cms';
import { Image } from '@/models/cms/index';
import * as cmsUtils from '@/common/cmsUtils';
import { CardTypeItem } from './CardTypeItem';
import { GroupCardTypeItem } from './GroupCardTypeItem';

export class NewsItem {
  static valueOf(n: NewsResponse, isSP: boolean, supportsWebP: boolean) {
    return new NewsItem(
      isSP,
      supportsWebP,
      n.id,
      n.abstract,
      n.detail,
      n.isImportant,
      n.listImg,
      n.detailImg,
      n.showRegardlessPage,
      n.showLoggedInPage,
      n.permitType[0] ? n.permitType[0] : cmsUtils.permitAllValue(),
      n.permitCardType
        ? n.permitCardType.map(c => CardTypeItem.valueOf(c))
        : [],
      n.permitCardTypeGroup
        ? n.permitCardTypeGroup.map(g => GroupCardTypeItem.valueOf(g))
        : [],
      n.permitCardTypeText ? n.permitCardTypeText : '',
      n.publishedAt
    );
  }

  private constructor(
    readonly isSP: boolean,
    readonly supportsWebP: boolean,
    readonly id: string,
    readonly abstract: string,
    readonly detail: string,
    readonly isImportant: boolean,
    readonly listImg: Image,
    readonly detailImg: Image,
    readonly showRegardlessPage: boolean,
    readonly showLoggedInPage: boolean,
    readonly permitType: string,
    readonly permitCardTypes: Array<CardTypeItem>,
    readonly permitCardTypeGroups: Array<GroupCardTypeItem>,
    readonly permitCardTypeText: string,
    readonly publishedAt: string
  ) {}

  get publishedAtJST() {
    return cmsUtils.toJSTString(this.publishedAt);
  }

  get listImgUrl() {
    return this.listImg
      ? cmsUtils.convertOptimizedUrl(this.listImg, this.isSP, this.supportsWebP)
      : '';
  }

  get detailImgUrl() {
    return this.detailImg
      ? cmsUtils.convertOptimizedUrl(
          this.detailImg,
          this.isSP,
          this.supportsWebP
        )
      : '';
  }

  get isPermitTargetGroup() {
    return cmsUtils.isPermitTargetGroup(this.permitType);
  }

  get isPermitTargetSingle() {
    return cmsUtils.isPermitTargetSingle(this.permitType);
  }

  get isPermitTargetAll() {
    return cmsUtils.isPermitTargetAll(this.permitType);
  }

  get isNew() {
    return cmsUtils.isNewContent(this.publishedAt);
  }
}
