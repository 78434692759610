


























































































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class OpAppDownloadPanel extends Vue {
  @Prop()
  readonly isModal?: boolean;

  @Prop()
  readonly isHiddenCaution?: boolean;

  @Prop()
  readonly isCampaignText?: boolean;
}
