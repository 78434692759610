






















































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import * as api from '@/gen/api';
import CFooter from '@/components/root/CFooter.vue';

//サブスクCSS
import '../public/static/assets/css/subsc.css';

//新規CSS
import '../public/static/assets/fonts/icomoon_renew/style.css';
import '../public/static/assets/css/vuetify_overwrite.css';
import '../public/static/assets/css/base_renew.css';
import '../public/static/assets/css/common_renew.css';
import '../public/static/assets/css/style_renew.css';
import '../public/static/assets/css/lp.css';
import '../public/static/assets/css/lp_ebi.css';

import Utils from './common/utils';
import { mapState, mapGetters } from 'vuex';
import { translateErr } from '@/common/translateErr';
import { OpCardRepository } from '@/repositories/OpCardRepository';
import { CardTypeRepository } from '@/repositories/CardTypeRepository';
import PersonalMenu from '@/components/PersonalMenu.vue';
import HamburgerMenu from '@/components/HamburgerMenu.vue';

import { OpCardList } from '@/models/opcards/OpCard';

@Component({
  components: {
    CFooter,
    PersonalMenu,
    HamburgerMenu
  },
  computed: {
    ...mapState(['op']),
    ...mapGetters(['getOpBalanceSuccess'])
  }
})
export default class App extends Vue {
  private alert = false;
  private op: any;

  readonly TIMEOUT_LOGOUT = 5000;

  // OPWEB移行ユーザの識別が必要なログイン後画面
  readonly OPWEB_IDENTIFYING_PAGES_AFTER_LOGIN = [
    'confirm', // 認証メール送信済み画面
    'sign-up', // ECOP認証用画面・本登録画面
    'sign-up-complete' // 登録完了画面
  ];
  // OPWEB移行ユーザの識別が必要なログイン状態に関わらずアクセスできる画面（仮登録画面はAuth0ホストのため、web-app/frontendでの識別は対象外）
  readonly OPWEB_IDENTIFYING_PAGES_REGARDLESS_LOGIN = [
    'opweb-switch' // OPWEB移行ユーザ用案内画面
  ];

  //ページ下部にキャンペーン用のフローティングバナーがあるページ
  readonly FLOATING_BANNER_PAGES = ['odepo-arigatou1'];

  /**
   * クエリパラメータに認証エラーがあれば強制ログアウト
   */
  @Watch('$route.query.error')
  onQueryErrorChanged(error: string) {
    if (error) {
      this.alert = true;
      if (this.$route.query.error === 'unauthorized') {
        if (
          this.$route.query.error_description ===
          'You did not agree to the terms of service'
        ) {
          this.$router.push(
            `/?error=${this.$route.query.error}&error_description=${this.$route.query.error_description}`
          );
          return;
        }
        this.forceLogout();
      }
      return;
    }

    this.alert = false;
  }

  forceLogout() {
    setTimeout(this.logout, this.TIMEOUT_LOGOUT);
  }

  @Watch('$auth.loading', { immediate: true })
  async onLoadingChanged(loading: boolean) {
    // this.$route.queryが構築される前であるため、document.locationで判定する
    const isErr = document.location.search.match(/^\?error/);
    if (loading || isErr || !this.$auth.isAuthenticated) {
      return;
    }

    if (
      this.$auth.user['https://one-odakyu.com/isSignupCompleted'] &&
      this.isEmailVerified
    ) {
      await this.fetchUserData();
    }

    const key = 'token';
    const token = Utils.readCookie().get(key);
    if (token) {
      // Cookie に token がある場合はバックエンドへPOSTする
      this.storeToken(token)
        .then(() => Utils.deleteCookie(key))
        .catch(this.$tokenErrHandler);
    }
  }

  async fetchUserData() {
    this.fetchCurrentUser();
    this.getOpCards();

    this.$store.commit('setOpBalance', await this.opCardRepo.getOpBalance());
    this.$store.commit('setOpFetched', true);
  }

  async fetchMicroCMSData() {
    this.$store.commit('setMicroCMSCardType', await this.cardTypeRepo.allMap());
    this.$store.commit('setMicroCMSFetched', true);
  }

  // TODO(j.oiwa.zx@future.co.jp): OPアプリ対応。本来OPカード情報をstoreで管理する必要はないが、互換性のために格納する。
  getOpCards() {
    const ret = this.$auth.user['https://one-odakyu.com/op_cards'];
    this.$store.commit('setOpCards', ret);
  }

  /**
   * 電ガスの会員情報紐付け用 token をバックエンドへ POST する
   */
  async storeToken(token: string) {
    const config = await this.$getConfigWithToken(this.$auth);
    const tokenApi = new api.EnergyApi(config);
    await tokenApi.postEnergyTokenStore({ token: token });
  }

  /**
   * ユーザ取得API
   */
  private async fetchCurrentUser() {
    const config = await this.$getConfigWithToken(this.$auth);
    const currentUserApi = new api.CurrentUserApi(config);
    try {
      const res = await currentUserApi
        .getCurrentUser()
        .catch(this.$tokenErrHandler);
      const { data } = res;
      this.$store.commit('setLastName', data.last_name);
      this.$store.commit('setUid', data.uid);
    } catch (_) {
      return;
    }
  }

  /**
   * 他画面への遷移を有効にするか判断する
   */
  private get isTransitionEnable() {
    return (
      this.$route.name !== 'sign-up' &&
      this.$route.name !== 'confirm' &&
      this.$route.name !== 'not-available'
    );
  }

  private get isAuthenticatedOk() {
    return this.$auth.isAuthenticated && !this.$auth.loading;
  }

  private get isNotAuthenticatedOk() {
    return !this.$auth.isAuthenticated && !this.$auth.loading;
  }

  private get isEmailVerified() {
    return this.$auth.user['email_verified'];
  }

  private get isSignupCompleted() {
    return this.$auth.user['https://one-odakyu.com/isSignupCompleted'];
  }

  private get isOpwebUserSigningUp() {
    const currentPage = this.$route.name ? this.$route.name : '';
    const isOpwebUser = this.isAuthenticatedOk
      ? this.$auth.user['https://one-odakyu.com/isOpwebUser']
      : false;
    return (
      this.OPWEB_IDENTIFYING_PAGES_REGARDLESS_LOGIN.includes(currentPage) ||
      (isOpwebUser &&
        this.OPWEB_IDENTIFYING_PAGES_AFTER_LOGIN.includes(currentPage))
    );
  }

  //ページ下部のフローティングバナー存在フラグ（trueでバナー有り）
  private get isFloatingBanner() {
    const currentPage = this.$route.name ? this.$route.name : '';
    return this.FLOATING_BANNER_PAGES.includes(currentPage);
  }

  private login() {
    this.$auth.loginWithRedirect({ appState: { targetUrl: '/' } });
  }

  private logout() {
    // 明示的にLogout後のURLを指定
    const returnTo = `${window.location.origin}/`;
    this.$auth.logout({ returnTo });
  }

  private signup() {
    this.$auth.loginWithRedirect({
      initialDisplay: 'signup'
    });
  }

  get logoutBtnLabel() {
    return this.$auth.isOpApp() ? 'アプリに戻る' : 'ログアウト';
  }
  private get opCardRepo() {
    return new OpCardRepository();
  }

  private get cardTypeRepo() {
    return new CardTypeRepository();
  }

  get translateErr() {
    return translateErr;
  }
  private async created() {
    await this.fetchMicroCMSData();
  }

  //OPカード 券種取得用
  get opCards() {
    return OpCardList.valueOf(
      this.$auth.user['https://one-odakyu.com/op_cards']
    );
  }

  //URLにハッシュが含まれる場合の処理をjqueryを使わないものに変更
  private updated() {
    let anchor = this.$route.hash;
    if (anchor !== '') {
      if (anchor !== null) {
        anchor = anchor.replace('#', '');
      }

      let elem = document.getElementById(anchor) as HTMLElement;
      let top =
        elem instanceof HTMLElement === true
          ? window.pageYOffset + elem.getBoundingClientRect().top
          : 0;

      if (top > 0) {
        setTimeout(function() {
          window.scrollTo({
            top: top,
            behavior: 'smooth'
          });
        }, 50);
      }
    }
  }

  // showHeaderAndFooter は共通ヘッダー・フッターを表示すべきであれば true を返す。
  get showHeaderAndFooter(): boolean {
    return !this.$route?.meta?.hideHeaderAndFooter;
  }
}
