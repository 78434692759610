import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export const isAvailableEntryTime = (
  startDatetime: string,
  finishDatetime: string
) => {
  return currentDatetime().isBetween(startDatetime, finishDatetime, null, '[]');
};

export const isBeforeAvailableEntryTime = (startDatetime: string) => {
  return currentDatetime().isBefore(dayjs(startDatetime));
};

export const isAfterAvailableEntryTime = (finishDatetime: string) => {
  return currentDatetime().isAfter(dayjs(finishDatetime));
};

export const currentDatetime = () => {
  return dayjs();
};
