import { ArticleResponse } from '@/models/cms/article';
import { Image } from '@/models/cms/index';

export class ArticleItem {
  static valueOf(n: ArticleResponse, isSP: boolean, supportsWebP: boolean) {
    //コンテンツ中の「{box}」文字列を白枠のスタイリングを施したdiv要素に変換
    const regex = /{#(.+?)}/gm;
    let result;
    let contents_buffer = n.contents;
    while (contents_buffer.indexOf('{box}') > 0) {
      contents_buffer = contents_buffer.replace(
        '{box}',
        '<div class="entry_body_box">'
      );
      contents_buffer = contents_buffer.replace('{/box}', '</div>');
    }

    //コンテンツ内の「{#ID_NAME」をIDを付与したdiv要素に変換（アンカーリンク対応のため）
    while ((result = regex.exec(contents_buffer)) !== null) {
      contents_buffer = contents_buffer.replace(
        result[0],
        '<div id="' + result[1] + '" class="u-sect"></div>'
      );
    }

    return new ArticleItem(
      isSP,
      supportsWebP,
      n.id,
      n.createdAt,
      n.updatedAt,
      n.publishedAt,
      n.revisedAt,
      n.title,
      contents_buffer,
      n.image,
      n.relatedArticles,
      n.isNotShow
    );
  }

  private constructor(
    readonly isSP: boolean,
    readonly supportsWebP: boolean,
    readonly id: string,
    readonly createdAt: string,
    readonly updatedAt: string,
    readonly publishedAt: string,
    readonly revisedAt: string,
    readonly title: string,
    readonly contents: string,
    readonly image: Image,
    readonly relatedArticles: Array<ArticleResponse>,
    readonly isNotShow: boolean
  ) {}
}
