

























import { Component, Vue } from 'vue-property-decorator';
import { ArticleRepository } from '@/repositories/ArticleRepository';
import { ArticleItem } from '@/models/ArticleItem';
import ArticleBlock from '@/components/ArticleBlock.vue';

@Component({
  components: { ArticleBlock }
})
export default class StartNav extends Vue {
  articleList: Array<ArticleItem> = [] as Array<ArticleItem>;

  created() {
    this.initialize();
  }

  initialize() {
    this.getArticleList();
  }

  get ArticleRepository() {
    return new ArticleRepository(
      this.$store.state.isSP,
      this.$store.state.supportsWebP
    );
  }

  async getArticleList() {
    this.articleList = await this.ArticleRepository.get();
  }
}
