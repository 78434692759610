
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { AllianceServiceItem } from '@/models/AllianceServiceItem';

@Component
export default class ServiceCard extends Vue {
  @Prop({ required: true })
  readonly service!: AllianceServiceItem;
}
