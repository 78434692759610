import { CouponResponse, Image } from '@/models/cms';
import {
  getServiceTagSvgPath,
  getServiceTagNameJp,
  getServiceTagCssClass
} from '@/common/couponUtils';
import { convertOptimizedUrl } from '@/common/cmsUtils';

const STT_DEFAULT = 0;
const STT_GO_TO_EXTERNAL_PAGE = 1;
const STT_COMING_SOON = 3;

const INVALID_ID = -1;

export class CouponItem {
  static valueOf(c: CouponResponse, isSP: boolean, supportsWebP: boolean) {
    return new CouponItem(
      isSP,
      supportsWebP,
      c.id,
      c.coupon_coupon,
      c.coupon_name,
      c.hidden_from_list,
      c.coupon_explanation,
      c.coupon_summary,
      c.coupon_detail ? c.coupon_detail : '',
      c.coupon_imgleft,
      c.coupon_thumbnail,
      c.coupon_logo,
      c.coupon_tag,
      c.coupon_code ? c.coupon_code : '',
      c.coupon_url,
      c.coupon_stt,
      c.alliance_services_id ? c.alliance_services_id : INVALID_ID,
      c.data_color ? c.data_color : ''
    );
  }

  private constructor(
    readonly isSP: boolean,
    readonly supportsWebP: boolean,
    readonly id: string,
    readonly coupon_coupon: string,
    readonly coupon_name: string,
    readonly hidden_from_list: boolean,
    readonly coupon_explanation: string,
    readonly coupon_summary: string,
    readonly coupon_detail: string,
    readonly coupon_imgleft: Image,
    readonly coupon_thumbnail: Image,
    readonly coupon_logo: Image,
    readonly coupon_tag: string,
    readonly coupon_code: string,
    readonly coupon_url: string,
    readonly coupon_stt: number,
    readonly alliance_services_id: number,
    readonly data_color: string
  ) {}

  get tagJp() {
    return getServiceTagNameJp(this.coupon_tag);
  }

  get svgPath() {
    return getServiceTagSvgPath(this.coupon_tag);
  }

  get tagCssClass() {
    return 'tags ' + getServiceTagCssClass(this.coupon_tag);
  }

  get isDefault() {
    switch (this.coupon_stt) {
      case STT_DEFAULT:
        return true;
      default:
        return false;
    }
  }

  get isGoToExternalPage() {
    switch (this.coupon_stt) {
      case STT_GO_TO_EXTERNAL_PAGE:
        return true;
      default:
        return false;
    }
  }

  get isComingSoon() {
    switch (this.coupon_stt) {
      case STT_COMING_SOON:
        return true;
      default:
        return false;
    }
  }

  get imgLeftUrl() {
    return convertOptimizedUrl(
      this.coupon_imgleft,
      this.isSP,
      this.supportsWebP
    );
  }

  get imgThumbnailUrl() {
    return convertOptimizedUrl(
      this.coupon_thumbnail,
      this.isSP,
      this.supportsWebP
    );
  }

  get imgLogoUrl() {
    return convertOptimizedUrl(this.coupon_logo, this.isSP, this.supportsWebP);
  }
}
