import { GroupCardTypeResponse } from '@/models/cms/groupCardType';
/**
 * @classdesc ループ券種マスタを選択するフィールドを設定したAPIにおける、当該フィールドの情報を管理する
 */
export class GroupCardTypeItem {
  static valueOf(g: GroupCardTypeResponse) {
    return new GroupCardTypeItem(
      g.id,
      g.name,
      g.cardTypes.map(c => c.id)
    );
  }

  private constructor(
    readonly id: string, // microCMSシステム項目
    readonly name: string, // 名称
    readonly cardTypes: Array<string> // 個別券種マスタシステムID
  ) {}
}
