




































































































































import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { CouponItem } from '@/models/CouponItem';

@Component
export default class CouponDialog extends Vue {
  @Prop({ required: true })
  readonly coupon!: CouponItem;

  @Model('dialog', { type: Boolean })
  readonly dialog!: boolean;

  /**
   * ダイアログを下へスクロールした後に別クーポンのダイアログを開いた場合、スクロール移動をリセットする https://github.com/vuetifyjs/vuetify/issues/1687
   */
  updated() {
    const elements = document.getElementsByClassName('v-dialog--active');
    if (!elements || !elements.length) {
      // 要素が取得できなかった場合は終了
      return;
    }
    elements[0].scrollTop = 0;
  }

  get isAuthenticated() {
    return this.$auth.isAuthenticated && !this.$auth.loading;
  }

  get wrapperDialog() {
    return this.dialog;
  }

  set wrapperDialog(flag: boolean) {
    this.$emit('dialog', flag);
  }
}
