











































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import CFeatureCommon from '@/components/CFeatureCommon.vue';
import { ACT_ON_SCT_ID } from '@/common/constants';

@Component({
  components: {
    CFeatureCommon
  }
})
export default class CFooter extends Vue {
  // フッターシンプルなバージョンフラグ
  @Prop()
  readonly isSimple?: boolean;

  // ページ下部のフローティングバナー存在フラグ（trueでバナー有り）
  @Prop()
  readonly isFloatingBanner?: boolean;

  // 規約などの表示用フラグ
  isShowDocs = false;
  // 特定商取引法のコンテンツID
  readonly actOnSctId = ACT_ON_SCT_ID;
  // 規約表示・非表示
  toggleDocs() {
    this.isShowDocs = !this.isShowDocs;
  }

  // ページトップへ
  pagetop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  // ページトップボタンのステータス（非表示、フロート表示、固定表示の3パターン）
  pagetop_status = '';

  mounted() {
    const footer_elem = document.getElementById('js-gFooter') as HTMLElement;
    const pagetop_offset_bottom = 40;

    var isScrollReady = false;

    const eventScroll = () => {
      if (isScrollReady) {
        return false;
      }

      isScrollReady = true;

      window.requestAnimationFrame(() => {
        if (
          window.scrollY + window.innerHeight >
          footer_elem.getBoundingClientRect().top +
            window.pageYOffset +
            pagetop_offset_bottom
        ) {
          this.pagetop_status = 'fixed';
        } else if (window.scrollY > 100) {
          this.pagetop_status = 'float';
        } else {
          this.pagetop_status = 'hidden';
        }

        if (this.$route.name === 'subscription-lp-ebina-passport') {
          this.pagetop_status = 'fixed';
        }

        isScrollReady = false;
      });
    };

    window.addEventListener('scroll', () => {
      eventScroll();
    });

    eventScroll();
  }

  get isCampaignServicePath() {
    // キャンペーン用の固定footerを表示したい場合はtrueを返却させる
    return false;
  }
}
