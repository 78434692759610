

























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { NewsRepository } from '@/repositories/NewsRepository';
import { NewsItem } from '@/models/NewsItem';
import { OpCardList, OpCard } from '@/models/opcards/OpCard';
import * as cmsUtils from '@/common/cmsUtils';

type ShowPage = 'RegardlessPage' | 'LoggedInPage';
@Component({
  components: {}
})
export default class NewsScroll extends Vue {
  newsList: Array<NewsItem> = [] as Array<NewsItem>;

  readonly LIMIT_NUM = 30; // 取得するお知らせの件数

  @Prop({ required: true })
  readonly showPage?: ShowPage;

  @Prop()
  readonly isMarginShort?: boolean;

  created() {
    this.initialize();
  }
  initialize() {
    this.getNewsList();
  }

  /**
   * お知らせではロード時のみmicroCMSを呼び出す
   * ページャーを押しても呼び出しは実施せず、取得済みのお知らせリストを操作する
   */
  async getNewsList() {
    this.newsList = (
      await this.newsRepository.get({
        showPage: this.showPage,
        limit: this.LIMIT_NUM
      })
    )
      .filter(news => this.isPermittedEntry(news))
      .slice(0, 4);
  }

  get newsRepository() {
    return new NewsRepository(
      this.$store.state.isSP,
      this.$store.state.supportsWebP
    );
  }

  // 表示許可券種かどうかを判定
  // TODO(a.takahashi.xz@future.co.jp): お知らせ一覧とのロジック共通化
  isPermittedEntry(news: NewsItem) {
    if (news.isPermitTargetAll) {
      return true;
    }
    if (!this.$auth.isAuthenticated) {
      return false;
    }
    if (news.isPermitTargetGroup) {
      return this.$store.state.microCMS.isMicroCMSFetched
        ? cmsUtils.isIncludedGroupCardTypes(
            this.mainOpCard.odakyuCustomerNo,
            news.permitCardTypeGroups,
            this.cardType
          )
        : false;
    }
    if (news.isPermitTargetSingle) {
      return cmsUtils.isIncludedCardTypes(
        this.mainOpCard.odakyuCustomerNo,
        news.permitCardTypes
      );
    }
    return false;
  }

  get mainOpCard() {
    if (!this.$auth.isAuthenticated) {
      return {} as OpCard;
    }
    return OpCardList.valueOf(
      this.$auth.user['https://one-odakyu.com/op_cards']
    ).selectMainCard;
  }

  get cardType() {
    return this.$store.state.microCMS.cardType;
  }

  get cardTypeMap() {
    return this.$store.state.microCMS.cardType;
  }

  permitCardTypeText(news: NewsItem) {
    if (news.isPermitTargetAll) {
      return '全券種';
    }
    if (news.permitCardTypeText) {
      return news.permitCardTypeText;
    }
    if (news.isPermitTargetGroup) {
      return news.permitCardTypeGroups[0].name;
    }
    if (news.isPermitTargetSingle) {
      return news.permitCardTypes[0].name;
    }
    return '';
  }

  isMultipleCardType(news: NewsItem) {
    if (news.isPermitTargetAll) {
      return false;
    }
    if (news.permitCardTypeText) {
      return false;
    }
    if (news.isPermitTargetGroup) {
      return news.permitCardTypeGroups.length > 1;
    }
    if (news.isPermitTargetSingle) {
      return news.permitCardTypes.length > 1;
    }
    return false;
  }
}
