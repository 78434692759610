import '@/plugins/vuetify';

import Vue from 'vue';
import VeeValidate from 'vee-validate';
import { RawLocation } from 'vue-router';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import AuthPlugin from '@/plugins/auth';
import ApiConfigPlugin from '@/plugins/api-config';
import MsgPlugin from '@/plugins/msg';
import ClsPlugin from '@/plugins/cls';
import ErrorHandler from '@/plugins/err-handler';
import FilterUtils from '@/common/filterUtils';
import validator from '@/common/validator';
import VueLazyload from 'vue-lazyload';
import VueScrollTo from 'vue-scrollto';
import VueRouter from 'vue-router';
import { gtmDataLayer } from '@/plugins/gtm-datalayer';

import supportsWebP from 'supports-webp';
import MobileDetect from 'mobile-detect';

Vue.use(VueLazyload);
Vue.use(VueScrollTo);

Vue.use(VeeValidate, {
  events: 'blur',
  locale: 'ja'
});
validator.init();

Vue.use(AuthPlugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN!,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  onRedirectCallback: (appState: { targetUrl: RawLocation } | null) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.use(ApiConfigPlugin);
Vue.use(MsgPlugin);
Vue.use(ClsPlugin);
Vue.use(ErrorHandler);
Vue.use(gtmDataLayer);

// クエリを無視してパスが一致しているかのみをaria-currentの付与条件として判断、付与・削除を行う関数
const configureAriaCurrent = (el: HTMLElement, vnode: any) => {
  const href = el.getAttribute('href');
  if (!href) {
    return;
  }
  const url = new URL(href, process.env.VUE_APP_SERVICE_URL);
  const hrefPath = url.pathname;
  const router: VueRouter = vnode.context.$router;

  Vue.nextTick(() => {
    // router-linkに設定されているURLと、遷移先のURLについて、パスが一致しているがクエリが異なる場合でもaria-current='page'を設定する。
    if (router.currentRoute.path === hrefPath) {
      el.setAttribute('aria-current', 'page');
    } else {
      el.removeAttribute('aria-current');
    }
  });
};
// このカスタムディレクティブが付与されたコンポーネントが作成・更新されるたび、DOMの更新完了次第aria-currentを設定する。
Vue.directive('custom-aria-current-ignore-query', {
  bind(el: HTMLElement, binding: any, vnode: any) {
    configureAriaCurrent(el, vnode);
  },
  update(el: HTMLElement, binding: any, vnode: any) {
    configureAriaCurrent(el, vnode);
  }
});

Object.keys(FilterUtils.funcs).forEach(key => {
  Vue.filter(key, FilterUtils.funcs[key]);
});

Vue.config.productionTip = false;

// スマホデバイス判定
const md = new MobileDetect(window.navigator.userAgent);
md.phone() && store.commit('setIsSP', !!md.phone()); // モバイルの場合はtrueをセット

const createApp = async () => {
  // WebPサポートのチェックが非同期処理のため、事前に同期を取ってからVueインスタンスを作成する
  store.commit('setSupportsWebP', await supportsWebP.catch(() => false));

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
};

createApp();
