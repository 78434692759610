import {
  getServiceTagNameJp,
  getServiceTagCssClass
} from '@/common/couponUtils.ts';

/**
 * id-partners.jsonと同じ構想にする
 */
export class AllianceServiceItem {
  static valueOf({
    id,
    name,
    caption_for_linked,
    caption_for_not_linked,
    tag,
    img_main,
    img_logo,
    describe,
    attention,
    path_in_one,
    is_show_list,
    top_url,
    linkage_window_url,
    sort,
    available_use_op,
    available_earn_op
  }: {
    id: number;
    name: string;
    caption_for_linked?: string;
    caption_for_not_linked?: string;
    tag: string;
    img_main: string;
    img_logo: string;
    describe: string;
    attention: string;
    path_in_one: string;
    is_show_list: boolean;
    top_url: string;
    linkage_window_url: string;
    sort: number;
    available_use_op?: boolean;
    available_earn_op?: boolean;
  }) {
    return new AllianceServiceItem(
      id,
      name,
      caption_for_linked || '', // undefined,null --> empty string
      caption_for_not_linked || '', // undefined,null --> empty string
      tag,
      img_main,
      img_logo,
      describe,
      attention,
      path_in_one,
      is_show_list,
      top_url,
      linkage_window_url,
      sort,
      !!available_use_op, // undefined,null --> boolean
      !!available_earn_op // undefined,null --> boolean
    );
  }

  private constructor(
    readonly id: number,
    readonly name: string,
    readonly caption_for_linked: string,
    readonly caption_for_not_linked: string,
    readonly tag: string,
    readonly img_main: string,
    readonly img_logo: string,
    readonly describe: string,
    readonly attention: string,
    readonly path_in_one: string,
    readonly is_show_list: boolean,
    readonly top_url: string,
    readonly linkage_window_url: string,
    readonly sort: number,
    readonly available_use_op: boolean,
    readonly available_earn_op: boolean
  ) {}

  get tagJp() {
    return getServiceTagNameJp(this.tag);
  }

  get tagCssClass() {
    return 'tag ' + getServiceTagCssClass(this.tag);
  }
}
