import { EventResponse, EventOption, Image } from '@/models/cms';
import { SubscriptionPackageResponse } from '@/models/cms/subscriptionPackage';
import * as cmsUtils from '@/common/cmsUtils';
import Classification from '@/common/classification';

export class EventItem {
  private static readonly EVENT_OPTIONS_TYPE_MULTIPLE_SELECT =
    'チェックボックス（複数選択）';
  private static readonly DEFAULT_USED_BTN_TEXT = '消し込み';

  static valueOf(e: EventResponse, isSP: boolean, supportsWebP: boolean) {
    return new EventItem(
      isSP,
      supportsWebP,
      e.id,
      e.createdAt,
      e.publishedAt,
      e.updatedAt,
      e.revisedAt,
      e.eventID,
      e.title,
      e.listContent,
      e.content1,
      e.content2,
      e.content5,
      e.eventOptions ? e.eventOptions.map(o => EventOptionItem.valueOf(o)) : [],
      e.entryEndDateTime,
      e.productFlg,
      e.allowedSubsPkgs,
      e.discountSubsPkgs,
      e.privateFlg,
      e.readOnlyFlg,
      e.usedFlg,
      e.usedBtnText,
      e.isAuthCodeRequired,
      e.eventOptionsType,
      e.entrySeparateAgeFlg,
      e.eventScheduleContent,
      e.img
    );
  }

  static new(e: EventItem) {
    return new EventItem(
      e.isSP,
      e.supportsWebP,
      e.id,
      e.createdAt,
      e.publishedAt,
      e.updatedAt,
      e.revisedAt,
      e.eventID,
      e.title,
      e.listContent,
      e.content1,
      e.content2,
      e.content5,
      e.eventOptions.map(o => EventOptionItem.new(o)),
      e.entryEndDateTime,
      e.productFlg,
      e.allowedEntrySubscriptionPackages,
      e.discountSubscriptionPackages,
      e.privateFlg,
      e.readOnlyFlg,
      e.usedFlg,
      e.usedBtnText ? e.usedBtnText : this.DEFAULT_USED_BTN_TEXT,
      e.isAuthCodeRequired,
      e.eventOptionsType,
      e.entrySeparateAgeFlg,
      e.eventScheduleContent,
      e.img
    );
  }

  private constructor(
    readonly isSP: boolean,
    readonly supportsWebP: boolean,
    readonly id: string, // microCMSシステム項目
    readonly createdAt: string, // microCMSシステム項目
    readonly publishedAt: string, // microCMSシステム項目
    readonly updatedAt: string, // microCMSシステム項目
    readonly revisedAt: string, // microCMSシステム項目
    readonly eventID: string,
    readonly title: string,
    readonly listContent: string,
    readonly content1: string,
    readonly content2: string,
    readonly content5: string,
    readonly eventOptions: Array<EventOptionItem>,
    readonly entryEndDateTime: string,
    readonly productFlg: boolean,
    readonly allowedEntrySubscriptionPackages: Array<
      SubscriptionPackageResponse
    >,
    readonly discountSubscriptionPackages: Array<SubscriptionPackageResponse>,
    readonly privateFlg: boolean,
    readonly readOnlyFlg: boolean,
    readonly usedFlg: boolean,
    readonly usedBtnText: string,
    readonly isAuthCodeRequired: boolean,
    readonly eventOptionsType: Array<String>,
    readonly entrySeparateAgeFlg: boolean,
    readonly eventScheduleContent: string,
    readonly img: Image
  ) {}

  get isNew() {
    return cmsUtils.isNewContent(this.publishedAt);
  }

  get imgUrl() {
    return this.img
      ? cmsUtils.convertOptimizedUrl(this.img, this.isSP, this.supportsWebP)
      : '';
  }

  get isMultipleSelect() {
    return (
      this.eventOptionsType[0] === EventItem.EVENT_OPTIONS_TYPE_MULTIPLE_SELECT
    );
  }

  // 全てのイベント内選択肢の残数が0である場合のみ true を返却
  get isNoSeat() {
    for (const e of this.eventOptions) {
      if (e.remainSeat !== 0) {
        return false;
      }
    }
    return true;
  }

  // 全てのイベント内選択肢が申込期間前である場合のみ true を返却
  get isBeforeEntryDt() {
    for (const e of this.eventOptions) {
      if (new Date() > new Date(e.entryStartDt)) {
        return false;
      }
    }
    return true;
  }

  get isAfterEntryDt() {
    return new Date() > new Date(this.entryEndDateTime);
  }
}

export class EventOptionItem {
  static valueOf(e: EventOption) {
    return new EventOptionItem(
      e.eventOptionID,
      e.eventOptionName,
      e.eventOptionDt,
      e.adultFee,
      e.childFee,
      e.discountedAdultFee,
      e.discountedChildFee,
      e.stockLimit,
      e.entryStartDt,
      e.entryEndDt,
      e.entryLimitPerUser,
      e.content3,
      e.content4,
      null,
      false,
      false,
      0,
      0,
      ''
    );
  }

  static new(e: EventOptionItem) {
    return new EventOptionItem(
      e.eventOptionID,
      e.eventOptionName,
      e.eventOptionDt,
      e.adultFee,
      e.childFee,
      e.discountedAdultFee,
      e.discountedChildFee,
      e.stockLimit,
      e.entryStartDt,
      e.entryEndDt,
      e.entryLimitPerUser,
      e.content3,
      e.content4,
      e.remainSeat,
      e.isSelectedFlag,
      e.inputDisabled,
      e.adultNumber,
      e.childNumber,
      e.errorMsg
    );
  }

  private constructor(
    readonly eventOptionID: string,
    readonly eventOptionName: string,
    readonly eventOptionDt: string,
    readonly adultFee: number,
    readonly childFee: number,
    readonly discountedAdultFee: number,
    readonly discountedChildFee: number,
    readonly stockLimit: number,
    readonly entryStartDt: string,
    readonly entryEndDt: string,
    readonly entryLimitPerUser: number,
    readonly content3: string,
    readonly content4: string,
    public remainSeat: number | null,
    public isSelectedFlag: boolean,
    public inputDisabled: boolean,
    private adultNumber: number,
    private childNumber: number,
    public errorMsg: string
  ) {}

  public get isSelected(): boolean {
    return this.isSelectedFlag;
  }

  public set isSelected(flag: boolean) {
    this.isSelectedFlag = flag;
  }

  public get adultNum(): number {
    return this.adultNumber;
  }

  public set adultNum(num: number) {
    this.adultNumber = num;
  }

  public get childNum(): number {
    return this.childNumber;
  }

  public set childNum(num: number) {
    this.childNumber = num;
  }

  get isFeeSeparated() {
    return (
      this.adultFee !== this.discountedAdultFee ||
      this.childFee !== this.discountedChildFee
    );
  }

  public adultPrice(eventFeeClassCode: string) {
    if (eventFeeClassCode === Classification.EVENT_FEE_CLS.GENERAL.CD) {
      return this.adultFee;
    } else {
      return this.discountedAdultFee;
    }
  }

  public childPrice(eventFeeClassCode: string) {
    if (eventFeeClassCode === Classification.EVENT_FEE_CLS.GENERAL.CD) {
      return this.childFee;
    } else {
      return this.discountedChildFee;
    }
  }
}
