















import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { Image } from '@/models/cms';
import { DialogContent } from '@/models/DialogContent';

@Component
export default class CarouselDialog extends Vue {
  @Prop({ required: true })
  readonly dialogContent!: DialogContent;

  @Prop({ required: true })
  readonly image!: Image;

  @Model('dialog', { type: Boolean })
  readonly dialog!: boolean;

  get wrapperDialog() {
    return this.dialog;
  }

  set wrapperDialog(flag: boolean) {
    this.$emit('dialog', flag);
  }

  /**
   * ダイアログを下へスクロールした後に別クーポンのダイアログを開いた場合、スクロール移動をリセットする https://github.com/vuetifyjs/vuetify/issues/1687
   */
  updated() {
    const elements = document.getElementsByClassName('v-dialog --active');
    if (!elements || !elements.length) {
      // 要素が取得できなかった場合は終了
      return;
    }
    elements[0].scrollTop = 0;
  }
}
