import { CampaignResponse } from '@/models/cms/campaign';
import { CampaignItem } from '@/models/CampaignItem';
/**
 * @classdesc microCMSから受け取ったキャンペーンの一覧情報を管理する
 */
export class CampaignListItem {
  static valueOf(
    responses: CampaignResponse[],
    totalCount: number,
    isSP: boolean,
    supportsWebP: boolean
  ) {
    return new CampaignListItem(
      responses.map(c => CampaignItem.valueOf(c, isSP, supportsWebP)),
      totalCount
    );
  }

  private constructor(
    readonly items: Array<CampaignItem>,
    readonly totalCount: number
  ) {}
}
