<template functional>
  <!-- Attention!: This component is functional -->
  <nav class="gFootBnrNav">
    <div class="gFootBnrNav_inner u-inner">
      <ul class="gFootBnrNav_list">
        <li>
          <a href="https://odakyu-energy.jp/" target="_blank" rel="noopener"
            ><img
              src="/static/assets/img/common/bnr-foot-denki@2x.png"
              width="266"
              height="103"
              alt="小田急でんき"
          /></a>
        </li>
        <li>
          <a href="https://www.odakyu-card.jp/" target="_blank" rel="noopener"
            ><img
              src="/static/assets/img/common/bnr-foot-point@2x.png"
              width="266"
              height="103"
              alt="小田急ポイントカード"
          /></a>
        </li>
        <li>
          <a
            href="https://odakyu-kurashisupport.jp/"
            target="_blank"
            rel="noopener"
            ><img
              src="/static/assets/img/common/bnr-foot-kurashi@2x.png"
              width="266"
              height="103"
              alt="くらしサポート"
          /></a>
        </li>
        <li>
          <a
            href="https://shop.odakyu-dept.co.jp/ec/top"
            target="_blank"
            rel="noopener"
            ><img
              src="/static/assets/img/common/bnr-foot-shopping@2x.png"
              width="266"
              height="103"
              alt="ODAKYUオンラインショッピング"
          /></a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {};
</script>
