import { CardTypeResponse } from '@/models/cms/cardType';
/**
 * @classdesc microCMSから受け取ったカード種別マスタの情報を管理する
 */
export class CardTypeItem {
  static valueOf(c: CardTypeResponse) {
    return new CardTypeItem(c.id, c.name, c.bin);
  }

  private constructor(
    readonly id: string, // microCMSシステム項目
    readonly name: string, // 名称
    readonly bin: string //BIN帯
  ) {}
}
