import { DialogContent } from '@/models/DialogContent';
import { SmallCarouselResponse, Image } from '@/models/cms';
import { convertOptimizedUrl } from '@/common/cmsUtils';
export class SmallCarouselItem {
  static valueOf(
    v: SmallCarouselResponse,
    isSP: boolean,
    supportsWebP: boolean
  ) {
    return new SmallCarouselItem(
      isSP,
      supportsWebP,
      v.id,
      v.img,
      v.dialogContent,
      v.showMypage,
      v.showTop,
      v.pattern,
      v.createdAt,
      v.updatedAt,
      v.publishedAt,
      v.revisedAt,
      v.internalLink,
      v.externalLink,
      v.alt
    );
  }

  private constructor(
    private readonly isSP: boolean,
    private readonly supportsWebP: boolean,
    readonly id: string,
    readonly img: Image,
    readonly dialogContent: DialogContent | null,
    readonly showMypage: boolean,
    readonly showTop: boolean,
    readonly pattern: number,
    readonly createdAt: string,
    readonly updatedAt: string,
    readonly publishedAt: string,
    readonly revisedAt: string,
    readonly internalLink?: string,
    readonly externalLink?: string,
    readonly alt?: string
  ) {}

  get imgUrl() {
    return convertOptimizedUrl(this.img, this.isSP, this.supportsWebP);
  }
}
