import { CardTypeItem } from '@/models/CardTypeItem';
import { GroupCardTypeItem } from '@/models/GroupCardTypeItem';
import { Image } from '@/models/cms';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const WIDTH_SP = '750';
// コンテンツの新着表示期間はビジネスルールより7日間とする
const DISPLAY_NEW_CONTENT_TERM = 7;

const PERMIT_TYPE_GROUP = 'グループ券種許可';
const PERMIT_TYPE_SINGLE = '個別券種許可';
const PERMIT_TYPE_ALL = '全券種許可';

const TIMEZONE_JST = 'Asia/Tokyo';

/**
 * フラグによって最適化したurlの文字列を返す
 * @param image microCMSから取得する画像イメージ
 * @param isSP スマホフラグ
 * @param supportsWebP WebPフラグ
 * @returns url文字列: String
 */
export function convertOptimizedUrl(
  image: Image,
  isSP: boolean,
  supportsWebP: boolean
) {
  const newUrl = new URL(image.url);
  if (supportsWebP) {
    newUrl.searchParams.append('fm', 'webp');
  }
  if (isSP && image.width > 750) {
    newUrl.searchParams.append('w', WIDTH_SP);
    newUrl.searchParams.append('fit', 'clip');
  }
  return newUrl.toString();
}

export const currentDatetime = () => {
  return dayjs();
};

/**
 * 公開日から7日以内の場合は新しいコンテンツとみなしtrueを返す
 * ※7日以内はビジネスルールより規定
 * @param publishedAt microCMS
 */
export function isNewContent(publishedAt: string) {
  return dayjs(publishedAt).isValid()
    ? currentDatetime().isBefore(
        dayjs(publishedAt).add(DISPLAY_NEW_CONTENT_TERM, 'd')
      )
    : false;
}

/**
 * 小田急お客さま番号が個別券種マスタの一覧に該当するかを判別する
 * @param odakyuCustomerNo 小田急お客さま番号
 * @param permitCardTypes 許可個別券種
 * @returns 含まれている場合true
 */
export function isIncludedCardTypes(
  odakyuCustomerNo: string,
  permitCardTypes: Array<CardTypeItem>
) {
  return permitCardTypes
    .map(c => c.bin)
    .some(bin => odakyuCustomerNo.startsWith(bin));
}

/**
 * 小田急お客さま番号がグループ券種マスタの一覧に該当するかを判別する
 * @param odakyuCustomerNo 小田急お客さま番号
 * @param permitGroupCardTypes 許可グループ券種
 * @param cardTypes 個別券種マスタ
 * @returns 含まれている場合true
 */
export function isIncludedGroupCardTypes(
  odakyuCustomerNo: string,
  permitGroupCardTypes: Array<GroupCardTypeItem>,
  cardTypes: Map<string, CardTypeItem>
) {
  const bins = permitGroupCardTypes.flatMap(g =>
    g.cardTypes.map(c => cardTypes.get(c)?.bin)
  );
  return bins.some(b => (!b ? false : odakyuCustomerNo.startsWith(b)));
}

/**
 * 全券種許可の文字列を返す
 * （許可券種区分が未入力の場合のデフォルト値として利用することを想定)
 */
export function permitAllValue() {
  return PERMIT_TYPE_ALL;
}

/**
 * 許可区分がグループ券種許可であるかを判別する
 * @param permitType 許可区分
 * @returns グループ券種許可である場合true
 */
export function isPermitTargetGroup(permitType: string) {
  return permitType === PERMIT_TYPE_GROUP;
}

/**
 * 許可区分が個別券種許可であるかを判別する
 * @param permitType 許可区分
 * @returns 個別券種許可である場合true
 */
export function isPermitTargetSingle(permitType: string) {
  return permitType === PERMIT_TYPE_SINGLE;
}

/**
 * 許可区分が全券種許可であるかを判別する
 * @param permitType 許可区分
 * @returns 全券種許可である場合true
 */
export function isPermitTargetAll(permitType: string) {
  return permitType === PERMIT_TYPE_ALL;
}

/**
 * microCMSから取得した日時文字列をJSTの日時文字列に変換する
 * @param utcDateTime utc日時文字列
 */
export function toJSTString(utcDateTime: string) {
  return dayjs
    .utc(utcDateTime)
    .tz(TIMEZONE_JST)
    .toString();
}
