import mustache from 'mustache';

export default class Message {
  /** メッセージ文にパラメータを埋め込んで返却 */
  public static get(message: string, ...args: any[]): string {
    const msg = this.find(message) || { TEXT: '' };
    if (args.length === 1 && args[0] instanceof Object) {
      return mustache.render(msg.TEXT, args[0]);
    }
    return mustache.render(msg.TEXT, args);
  }

  /** メッセージ一覧
   * - CD: コード
   *   - 200XXXX (下位4桁は1から連番)
   * - TEXT: メッセージ文
   * - TYP: メッセージ種別
   *   - INFO/WARNING/ERROR
   */
  private static readonly msgList: Array<{
    CD: string;
    TEXT: string;
    TYP: string;
  }> = [
    {
      CD: '2000001',
      TEXT: '{{ process }}が完了しました。',
      TYP: 'I'
    },
    {
      CD: '2000002',
      TEXT: '{{ item }}を選択してください。',
      TYP: 'E'
    },
    {
      CD: '2000003',
      TEXT: '{{ page }}に戻ります。',
      TYP: 'I'
    },
    {
      CD: '2000004',
      TEXT: 'パスワードは8桁以上・英大小文字・数字を含め再入力してください。',
      TYP: 'E'
    },
    {
      CD: '2000005',
      TEXT:
        '該当する住所が見つかりません。郵便番号に誤りがないかご確認ください。誤りがない場合、大変お手数ですが住所を直接入力してください。',
      TYP: 'W'
    },
    {
      CD: '2000006',
      TEXT:
        '小田急お客さま番号が正しくありません。ご確認のうえ、再度ご入力ください。',
      TYP: 'E'
    },
    {
      CD: '2000007',
      TEXT:
        'このOPカードはすでに他の小田急ONE IDに登録済みです。1枚のOPカードを複数の小田急ONE IDに登録することはできません。',
      TYP: 'E'
    },
    {
      CD: '2000008',
      TEXT: '有効なOPカードではありません。',
      TYP: 'E'
    },
    {
      CD: '2000009',
      TEXT:
        'OPカード認証に失敗しました。小田急ONEの会員情報とOPカードに登録いただいている会員情報が異なっている場合がございます。登録している情報と以下の入力内容が一致しているかご確認ください。\n・ふりがな\n・生年月日\n・電話番号\nまた、家族会員カードを登録する場合は、小田急ONE会員情報（ご利用者情報）本会員カードに登録されている電話番号を登録してください。',
      TYP: 'E'
    },
    {
      CD: '2000010',
      TEXT: 'メンテナンス中です。',
      TYP: 'E'
    },
    {
      CD: '2000011',
      TEXT: 'サーバエラーが発生しました。時間を空けて再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000012',
      TEXT: 'リクエスト値が不正です',
      TYP: 'E'
    },
    {
      CD: '2000013',
      TEXT: 'タイムアウトが発生しました。時間を空けて再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000014',
      TEXT: '現在通信が混雑しております。時間を空けて再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000016',
      TEXT: 'サービスメンテナンス中です。時間を空けて再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000017',
      TEXT:
        'お客さまが小田急ONEにご登録いただいた会員情報と、OPカードにご登録頂いている情報で、お名前、電話番号、生年月日のいずれかが一致しません。\n登録情報が一致していないとOPポイントの付与ができません。\n登録情報を変更した際は、OPカード（小田急お客さま番号）の再登録を行ってください。',
      TYP: 'E'
    },
    {
      CD: '2000018',
      TEXT:
        '登録情報を変更した際は、OPカード（小田急お客さま番号）の再登録を行ってください。',
      TYP: 'E'
    },
    {
      CD: '2000019',
      TEXT: 'このキャンペーンの応募はすでに取り消されています。',
      TYP: 'E'
    },
    {
      CD: '2000020',
      TEXT: 'エントリー済みです',
      TYP: 'I'
    },
    {
      CD: '2000021',
      TEXT: 'メール再送信に失敗しました。',
      TYP: 'E'
    },
    {
      CD: '2000022',
      TEXT:
        '小田急お客さま番号を入力するか、もしくはOPポイント専用カード（デジタルタイプ）を選択してください。',
      TYP: 'E'
    },
    {
      CD: '2000023',
      TEXT: '性別は必須項目です',
      TYP: 'E'
    },
    {
      CD: '2000024',
      TEXT: 'ポイント履歴はありません。',
      TYP: 'I'
    },
    {
      CD: '2000026',
      TEXT:
        '履歴取得に失敗しました。しばらく経ってからもう一度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000027',
      TEXT: 'メインカードを変更しました。',
      TYP: 'I'
    },
    {
      CD: '2000028',
      TEXT: 'OPカードとの連携を解除しました。',
      TYP: 'I'
    },
    {
      CD: '2000029',
      TEXT: '小田急ONEへのOPカード\n追加登録が完了しました。',
      TYP: 'I'
    },
    {
      CD: '2000030',
      TEXT: 'OPカードを6枚以上登録することはできません。',
      TYP: 'I'
    },
    {
      CD: '2000031',
      TEXT: '15歳未満の方は本サービスを利用することはできません。',
      TYP: 'E'
    },
    {
      CD: '2000032',
      TEXT: '入力されたOPギフトコードは現在登録できません。({{ errorCode }})',
      TYP: 'E'
    },
    {
      CD: '2000033',
      TEXT:
        '入力されたOPギフトコードは期限切れのため登録できません。({{ errorCode }})',
      TYP: 'E'
    },
    {
      CD: '2000034',
      TEXT:
        '入力されたOPギフトコードの登録上限に達したため登録できません。({{ errorCode }})',
      TYP: 'E'
    },
    {
      CD: '2000036',
      TEXT: '入力されたOPギフトコードが不正です。({{ errorCode }})',
      TYP: 'E'
    },
    {
      CD: '2000037',
      TEXT:
        '入力されたOPギフトコードは無効のため登録できません。({{ errorCode }})',
      TYP: 'E'
    },
    {
      CD: '2000039',
      TEXT:
        'OPギフトコードの登録に失敗しました。時間を空けて再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000040',
      TEXT: 'PASMO ID番号を登録しました。',
      TYP: 'I'
    },
    {
      CD: '2000041',
      TEXT: 'PASMO ID番号を解除しました。',
      TYP: 'I'
    },
    {
      CD: '2000042',
      TEXT: '入力されたPASMO ID番号が不正です。番号をご確認ください。',
      TYP: 'E'
    },
    {
      CD: '2000043',
      TEXT:
        '入力されたPASMO ID番号のPASMOは他ユーザーによってすでに登録済みです。番号をご確認のうえ、お問い合わせください。',
      TYP: 'E'
    },
    {
      CD: '2000044',
      TEXT:
        '大人用PASMOをすでに登録済みです。他の大人用PASMOをご登録される場合は、現在登録されているPASMOの登録解除後に再度登録してください。',
      TYP: 'E'
    },
    {
      CD: '2000045',
      TEXT:
        '入力されたPASMO ID番号のPASMOは本日登録解除されています。登録解除されたPASMOを同日中に再登録することはできません。明日以降に登録してください。',
      TYP: 'E'
    },
    {
      CD: '2000046',
      TEXT:
        '本日、すでにPASMOの登録を実施しています。大人用PASMOは1日に1枚のみ登録可能です。明日以降に再度登録してください。',
      TYP: 'E'
    },
    {
      CD: '2000047',
      TEXT: 'PASMOの登録に失敗しました。時間を空けて再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000048',
      TEXT:
        '登録されたPASMOの情報取得に失敗しました。時間を空けて再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000049',
      TEXT:
        '小児用PASMOをすでに3つ登録済みです。他の小児用PASMOをご登録される場合は、現在登録されているPASMOいずれかの登録解除後に再度登録してください。',
      TYP: 'E'
    },
    {
      CD: '2000050',
      TEXT:
        '入力されたPASMO ID番号のPASMOはすでに大人用PASMOとして登録済みです。小児用PASMOとしてご登録される場合は、現在登録されている大人用PASMOの登録解除後に再度登録してください。',
      TYP: 'E'
    },
    {
      CD: '2000051',
      TEXT: '入力されたPASMO ID番号のPASMOはすでに登録済みです。',
      TYP: 'E'
    },
    {
      CD: '2000052',
      TEXT: 'PASMOの登録解除に失敗しました。時間を空けて再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000053',
      TEXT:
        '入力されたPASMO ID番号のPASMOはすでに小児用PASMOとして登録済みです。大人用PASMOとしてご登録される場合は、現在登録されている小児用PASMOの登録解除後に再度登録してください。',
      TYP: 'E'
    },
    {
      CD: '2000054',
      TEXT: '情報取得に失敗しました。時間を空けて、再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000055',
      TEXT:
        'すでに今月のチャージ申請可能ポイント上限に達しているため、チャージできません。',
      TYP: 'E'
    },
    {
      CD: '2000056',
      TEXT:
        '小田急ポイント残高が、チャージ申請に必要な最低ポイント（{{ num }}ポイント）を下回っているため、チャージできません。',
      TYP: 'E'
    },
    {
      CD: '2000057',
      TEXT:
        'チャージ申請ポイントが小田急ポイント残高を超えています。小田急ポイント残高以下の数を入力してください。',
      TYP: 'E'
    },
    {
      CD: '2000058',
      TEXT:
        'チャージ申請ポイントが今月のチャージ申請可能ポイントを超えています。今月のチャージ申請可能ポイント以下の数を入力してください。',
      TYP: 'E'
    },
    {
      CD: '2000059',
      TEXT:
        'チャージ申請ポイント数は{{ num }}ポイント以上の数のみ入力できます。',
      TYP: 'E'
    },
    {
      CD: '2000060',
      TEXT:
        'チャージ申請ポイント数は{{ num }}ポイント単位の数のみ入力できます。',
      TYP: 'E'
    },
    {
      CD: '2000061',
      TEXT: 'チャージ申請に失敗しました。時間を空けて、再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000062',
      TEXT: 'チャージ申請ポイント数は入力必須項目です。',
      TYP: 'E'
    },
    {
      CD: '2000063',
      TEXT: 'チャージ申請ポイント数は半角数字の整数のみ入力できます。',
      TYP: 'E'
    },
    {
      CD: '2000070',
      TEXT: '入力されたカード情報が不正です。({{ errorCode }})',
      TYP: 'E'
    },
    {
      CD: '2000071',
      TEXT:
        'サーバエラーが発生しました。時間を空けて再度お試しください。({{ errorCode }})',
      TYP: 'E'
    },
    {
      CD: '2000072',
      TEXT: 'すでに登録済みです。',
      TYP: 'E'
    },
    {
      CD: '2000073',
      TEXT: '登録に失敗しました。時間を空けて再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000074',
      TEXT: '不正なリクエストです。({{ errorCode }})',
      TYP: 'E'
    },
    {
      CD: '2000075',
      TEXT: 'カード変更に失敗しました。時間を空けて再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000076',
      TEXT:
        '解約予約に失敗しました。時間を空けて再度お試しください。({{ errorCode }})',
      TYP: 'E'
    },
    {
      CD: '2000077',
      TEXT: '解約予約に失敗しました。時間を空けて再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000078',
      TEXT: '情報取得に失敗しました。時間を空けて再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000079',
      TEXT:
        '配布対象クーポンが不足しているため、登録処理ができませんでした。大変申し訳ございませんが、お時間をおいて再度お試しいただくか、小田急ONEの「お問い合わせ」フォームからお問い合わせください。',
      TYP: 'E'
    },
    {
      CD: '2000080',
      TEXT: 'イベントを申し込みしました。',
      TYP: 'I'
    },
    {
      CD: '2000081',
      TEXT: '申し込み開始前です。',
      TYP: 'I'
    },
    {
      CD: '2000082',
      TEXT: '申し込み数が上限に達しました。',
      TYP: 'E'
    },
    {
      CD: '2000083',
      TEXT: '申し込み期間が終了しました。',
      TYP: 'I'
    },
    {
      CD: '2000084',
      TEXT: '1以上の数量をご入力ください。',
      TYP: 'E'
    },
    {
      CD: '2000086',
      TEXT: '申し込み上限数を超えています。',
      TYP: 'E'
    },
    {
      CD: '2000091',
      TEXT: 'イベント申込みに失敗しました。時間を空けて再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000092',
      TEXT: 'お申込み内容が不正です。({{ errorCode }})',
      TYP: 'E'
    },
    {
      CD: '2000094',
      TEXT: 'このイベントの申し込みに必要な有料サービスのご利用がありません。',
      TYP: 'E'
    },
    {
      CD: '2000095',
      TEXT: '申込期間外のお申込みがございます。',
      TYP: 'I'
    },
    {
      CD: '2000096',
      TEXT: 'お申し込み数が残数を超えている選択肢がございます。',
      TYP: 'E'
    },
    {
      CD: '2000097',
      TEXT:
        'お申し込み上限数を超えている選択肢がございます。同一イベントに複数回お申し込みいただいた場合でも、お一人さまあたりの上限数を超えたお申し込みはできません。',
      TYP: 'E'
    },
    {
      CD: '2000098',
      TEXT: '合計金額が1円以上になるように数量を指定してください。',
      TYP: 'E'
    },
    {
      CD: '2000100',
      TEXT: 'イベント情報取得に失敗しました。時間を空けて再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000102',
      TEXT:
        '申込み上限はお一人さま{{ limit }}です。{{ limit }}以下の数量をご入力ください。',
      TYP: 'E'
    },
    {
      CD: '2000103',
      TEXT: 'エントリー済のイベントはありません。',
      TYP: 'I'
    },
    {
      CD: '2000104',
      TEXT:
        'サーバエラーが発生しました。時間を空けて再度退会手続きをお願いします。なお、退会手続きが完了している場合は次回ログインはできません。',
      TYP: 'E'
    },
    {
      CD: '2000105',
      TEXT: 'イベントはありません。',
      TYP: 'I'
    },
    {
      CD: '2000106',
      TEXT:
        '契約更新時の決済が失敗しました。\nカード情報をお確かめの上、AM6時以降に再度お申し込みください。',
      TYP: 'E'
    },
    {
      CD: '2000107',
      TEXT:
        '契約更新時にサーバエラーが発生しました。\n復旧までしばらくお待ちください。\nお急ぎのお客さまはお手数ですが更新日時のAM6時以降に再度お申し込みください。',
      TYP: 'E'
    },
    {
      CD: '2000108',
      TEXT:
        '契約更新時の決済が失敗しました。\nカード情報をお確かめの上、再度お申し込みください。',
      TYP: 'E'
    },
    {
      CD: '2000109',
      TEXT:
        '契約更新時にサーバエラーが発生しました。\n復旧までしばらくお待ちください。\nお急ぎのお客さまはお手数ですが再度お申し込みください。',
      TYP: 'E'
    },
    {
      CD: '2000110',
      TEXT: '確認番号は入力必須項目です。',
      TYP: 'E'
    },
    {
      CD: '2000111',
      TEXT: '確認番号が正しくありません。',
      TYP: 'E'
    },
    {
      CD: '2000113',
      TEXT: '{{ usedBtnText }}に失敗しました。時間を空けて再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000114',
      TEXT: '一つ以上の選択肢を選択してください',
      TYP: 'E'
    },
    {
      CD: '2000115',
      TEXT:
        '入力されたOPギフトコードは現在登録できません。時間を空けて再度お試しください。({{ errorCode }})',
      TYP: 'E'
    },
    {
      CD: '2000117',
      TEXT:
        '入力されたOPギフトコードは登録対象外のため利用できません。({{ errorCode }})',
      TYP: 'E'
    },
    {
      CD: '2000118',
      TEXT:
        '検索の結果が表示上限(100件）を超えました。検索の条件を絞り込んでください。',
      TYP: 'E'
    },
    {
      CD: '2000119',
      TEXT:
        '現在この有料サービスにはお申し込みいただけません。大変申し訳ございませんが、お時間をおいて再度お試しいただくか、小田急ONEの「お問い合わせ」フォームからお問い合わせください。({{ errorCode }})',
      TYP: 'E'
    },
    // 2000200～2000399のメッセージコードはOPWEB案件が使用する
    {
      CD: '2000200',
      TEXT: 'セキュリティコードを入力してください',
      TYP: 'E'
    },
    {
      CD: '2000201',
      TEXT:
        '入力されたセキュリティコードが不正です。ご確認のうえ、再度ご入力ください。',
      TYP: 'E'
    },
    {
      CD: '2000202',
      TEXT: 'OPカードのWEB登録に失敗しました。時間を空けて再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000204',
      TEXT: '交換するポイント数は入力必須項目です',
      TYP: 'E'
    },
    {
      CD: '2000205',
      TEXT: '交換するポイント数は半角数字の整数のみ入力できます',
      TYP: 'E'
    },
    {
      CD: '2000206',
      TEXT: '交換するポイント数は{{ num }}ポイント以上の数のみ入力できます',
      TYP: 'E'
    },
    {
      CD: '2000207',
      TEXT: '交換するポイント数は{{ num }}ポイント単位の数のみ入力できます',
      TYP: 'E'
    },
    {
      CD: '2000208',
      TEXT:
        '交換するポイント数が小田急ポイント残高を超えています。小田急ポイント残高以下の数を入力してください。',
      TYP: 'E'
    },
    {
      CD: '2000209',
      TEXT: 'コースは一つ以上選択してください',
      TYP: 'E'
    },
    {
      CD: '2000210',
      TEXT:
        'お客さまの情報を確認できませんでした。\n入力内容をご確認ください。',
      TYP: 'E'
    },
    {
      CD: '2000211',
      TEXT:
        '小田急百貨店ポイント情報取得に失敗しました。しばらく経ってからもう一度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000212',
      TEXT:
        '小田急百貨店ポイント取得情報に失敗しました。数分後にもう一度お試しください。',
      TYP: 'E'
    },
    // 2000400～2000499のメッセージコードはモバイルPASMO案件が使用する
    {
      CD: '2000400',
      TEXT: 'エラーが発生しました。再度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000401',
      TEXT:
        'チャージするポイント数は{{ num }}ポイント単位の数のみ入力できます。',
      TYP: 'E'
    },
    {
      CD: '2000402',
      TEXT:
        'チャージするポイント数は{{ num }}ポイント以上の数のみ入力できます。',
      TYP: 'E'
    },
    {
      CD: '2000403',
      TEXT:
        'チャージするポイントが今月のチャージ可能ポイントを超えています。今月のチャージ可能ポイント以下の数を入力してください。',
      TYP: 'E'
    },
    {
      CD: '2000404',
      TEXT:
        'チャージするポイントが小田急ポイント残高を超えています。小田急ポイント残高以下の数を入力してください。',
      TYP: 'E'
    },
    {
      CD: '2000405',
      TEXT: 'チャージするポイント数は入力必須項目です。',
      TYP: 'E'
    },
    {
      CD: '2000406',
      TEXT:
        'すでに今月のチャージ可能ポイント上限に達しているため、チャージできません。',
      TYP: 'E'
    },
    {
      CD: '2000407',
      TEXT:
        'チャージ後PASMO残高が{{ num }}円以下になるように入力してください。',
      TYP: 'E'
    },
    {
      CD: '2000408',
      TEXT:
        '小田急ポイント残高が、{{ num }}ポイント未満のため、チャージできません。',
      TYP: 'E'
    },
    {
      CD: '2000409',
      TEXT:
        'ポイントチャージが使用不可能な状態になっています。お時間をおいて再度お試しいただくか、小田急ＯＮＥの「お問い合わせ」フォームからお問い合わせください。',
      TYP: 'E'
    },
    {
      CD: '2000410',
      TEXT:
        '再度操作をお試しいただき、引き続きエラーが解消しない場合は、お手数ですが、サポートポータルをご確認ください。',
      TYP: 'E'
    },
    {
      CD: '2000411',
      TEXT:
        '再度操作をお試しいただき、引き続きエラーが解消しない場合は、お手数ですが、小田急ＯＮＥの「お問い合わせ」フォームからお問い合わせください。',
      TYP: 'E'
    },
    {
      CD: '2000412',
      TEXT:
        '前回SF（電子マネー）を入金(チャージ)の際に、処理が正常に終了しませんでした。「PASMO一覧」から操作し、復旧処理を行ってください。',
      TYP: 'E'
    },
    {
      CD: '2000413',
      TEXT:
        '携帯情報端末、パソコン、もしくはモバイルPASMOサポートセンターのいずれかで同時に処理が行われていますので、この先の処理を行えません。他での処理を確認してください。',
      TYP: 'E'
    },
    {
      CD: '2000414',
      TEXT:
        'エラーが発生しました。お手数ですが、時間をおいてから再度実行してください。',
      TYP: 'E'
    },
    {
      CD: '2000415',
      TEXT:
        '前回退会を実施の際に、処理が正常に終了していませんでした。「PASMO一覧」から操作し、復旧処理を行ってください。',
      TYP: 'E'
    },
    {
      CD: '2000416',
      TEXT:
        '前回エラーとなった処理が終了していないため、復旧処理ができませんでした。前回エラーの処理を終了します。時間をおいて安定した通信環境でもう一度「PASMO一覧」から操作し、復旧処理を行ってください。',
      TYP: 'E'
    },
    {
      CD: '2000417',
      TEXT:
        'ログインできませんでした。しばらく経ってからログインするか、モバイルPASMOサポートセンターまでお問い合わせください。',
      TYP: 'E'
    },
    {
      CD: '2000418',
      TEXT:
        'このPASMOは、すでに再発行登録またはPASMOの払いもどし手続きを行っています。',
      TYP: 'E'
    },
    {
      CD: '2000419',
      TEXT:
        'モバイルPASMOが使用不可能な状態になっています。詳細は、モバイルPASMOサポートセンターまでお問い合わせください。',
      TYP: 'E'
    },
    {
      CD: '2000420',
      TEXT:
        '前回の処理が完了していません。「PASMO一覧」から操作し、復旧処理を行ってください。',
      TYP: 'E'
    },
    {
      CD: '2000421',
      TEXT: '現在払いもどし手続き処理中です。ログインすることはできません。',
      TYP: 'E'
    },
    {
      CD: '2000422',
      TEXT:
        '再発行登録が完了していません。パソコンから再発行登録を実行するか、モバイルPASMOサポートセンターまでお問い合わせください。',
      TYP: 'E'
    },
    {
      CD: '2000423',
      TEXT:
        'お客様のPASMOは使用不能の状態です。サポートポータルをご確認ください。',
      TYP: 'E'
    },
    {
      CD: '2000424',
      TEXT: 'サービス提供時間外です',
      TYP: 'E'
    },
    {
      CD: '2000425',
      TEXT:
        '現在、アクセス集中またはメンテナンスのため、一時的にサービスをご利用いただけません。大変申し訳ございませんが、しばらく時間をあけて操作をお試しください。',
      TYP: 'E'
    },
    {
      CD: '2000426',
      TEXT:
        'サーバエラーが発生しました。PASMO残高をご確認の上、チャージが完了していない場合はお時間をおいて再度お試しいただくか、小田急ONEの「お問い合わせ」フォームからお問い合わせください。',
      TYP: 'E'
    },
    // 2000500～2000509のメッセージコードはポイント交換案件が使用する
    {
      CD: '2000500',
      TEXT:
        '申込を正常に受け付けることができませんでした。画面を再読み込みし、もう一度お試しください。',
      TYP: 'E'
    },
    {
      CD: '2000501',
      TEXT: 'ポイント残高が不足しています。',
      TYP: 'E'
    },
    {
      CD: '2000502',
      TEXT: 'ポイント交換処理に失敗しました。',
      TYP: 'E'
    },
    {
      CD: '2000510',
      TEXT: `パスワードを再設定しました。
新しいパスワードで再度ログインしてください。`,
      TYP: 'I'
    },
    {
      CD: '2000511',
      TEXT: `退会手続きが完了しました。
ご利用いただきありがとうございました。
またのご利用を心よりお待ちしております。
なお、アプリは自動では削除されないので、不要な場合はアンインストールをお願いします。`,
      TYP: 'I'
    },
    {
      CD: '2000512',
      TEXT: `メールアドレスの再設定はまだ完了しておりません。
確認メールを送信しました。
確認メール本文のURLをタップし、新しいメールアドレスでログインしてください。`,
      TYP: 'I'
    },
    // 初期構築時の設定。900XXXX番台は追加しない。
    {
      CD: '9000001',
      TEXT: 'セッションタイムアウト、再度ログインしてください。',
      TYP: 'E'
    }
  ];

  /** コードを元にメッセージオブジェクトを検索 */
  private static find(cd: string) {
    return this.msgList.find((e: any) => e.CD === cd);
  }
}
