/**
 * 全角ひらがなかどうか検証する
 * https://ja.wikipedia.org/wiki/Unicode一覧_3000-3FFF
 */
const full_width_hira: any = {
  validate: (value: any): boolean => {
    if (Array.isArray(value)) {
      return value.every((v: any) => full_width_hira.validate(v));
    }
    if (value == null || value === '') {
      return true;
    }
    if (typeof value !== 'string') {
      return false;
    }
    return Array.prototype.every.call(value, full_width_hira.contains);
  },
  contains: (c: string): boolean => {
    return (c >= 'ぁ' && c <= 'ゖ') || c === 'ー';
  }
};
export default full_width_hira;
