import { getInstance } from '@/common/auth/authWrapper';
import { NavigationGuard, Route } from 'vue-router';
/*
  authGuardしている各pathに入る前に、ログイン状態(isAuthenticated)と個人情報の入力有無(isSignupCompleted)のチェックを行う
  Loginしていなければ、Login処理へ
  個人情報の入力がなければ、sign-upページへ (sign-upの一連のパスSignUpWhiteListは、isSignupCompleted==falseのみ許可)
 */
const signUpWhiteList = ['/sign-up'];
const signUpPath = '/sign-up';
const mainPagePath = '/';
const errorPagePath = '/error';
const isSignupCompletedClaimKey = 'https://one-odakyu.com/isSignupCompleted';
// LocalStorageのKeyとして利用。未ログイン状態でregister-op-giftに遷移した場合に文字列trueを格納する。
const opGiftKey = 'register_op_gift';
// LocalStorageのKeyとして利用。未ログイン状態でregister-op-giftに遷移した場合にクエリパラメータgiftcodeの中身を格納する。
const giftCodeKey = 'giftcode';
const KURASAPO_APPLICATION_NAME = '小田急くらしサポート';
const KURASAPO_FLAG_KEY = 'from_kurasapo';
// LocalStorageのKeyとして利用。未ログイン状態でregister-subscriptionに遷移した場合にパスパラメータに含まれるパッケージIDを格納する。
const subscriptionKey = 'subscription_package_id';
// LocalStorageのKeyとして利用。未ログイン状態でodekake-pointに遷移した場合にクエリパラメータadult-pasmo-idiを格納する。
const adultPasmoIDiKey = 'input_adult_pasmo_idi';
// LocalStorageのKeyとして利用。未ログイン状態でregister-op-giftに遷移した場合にクエリパラメータauto-useの中身を格納する。
const autoUseKey = 'auto_use';

// loading==falseまでを待って、ログイン成功かどうかを返す
const isAuthenticated = async (authService: any) => {
  // wait for loading
  await new Promise<void>(resolve => {
    if (authService.loading === false) {
      return resolve();
    }
    authService.$watch('loading', (loading: boolean) => {
      if (loading === false) {
        return resolve();
      }
    });
  });

  return authService.isAuthenticated;
};

// ログイン不要なページのcall-back時、エラーハンドリングのため
export const errorCheckGuard = async (to: any, from: any, next: any) => {
  if (to.query.success === 'false') {
    return next({
      path: errorPagePath,
      replace: true,
      query: { error_description: to.query.message }
    });
  }

  return next();
};

// ログイン必要なページのため。ユーザ情報未入力(!isSignupCompleted)の場合、sign-upページに遷移(signUpWhiteListのpathは除き)
export const authGuard = async (to: any, from: any, next: any) => {
  if (to.query.success === 'false') {
    return next({
      path: errorPagePath,
      replace: true,
      query: { error_description: to.query.message }
    });
  }

  const authService = getInstance();

  if (!(await isAuthenticated(authService))) {
    return authService.loginWithRedirect({
      appState: { targetUrl: to.fullPath },
      email: to.query.email
    });
  }

  const isSignupCompleted = authService.user[isSignupCompletedClaimKey];
  if (!isSignupCompleted) {
    if (signUpWhiteList.includes(to.path)) {
      // くらさぽからの新規登録の場合は、リダイレクト先を変更するため、LocalStorageにフラグを格納する
      if (to.query && to.query.from_application === KURASAPO_APPLICATION_NAME) {
        localStorage.setItem(KURASAPO_FLAG_KEY, 'true');
      }
      return next();
    }
    return next({ path: signUpPath, replace: true });
  }

  if (signUpWhiteList.includes(to.path)) {
    return next({ path: mainPagePath, replace: true });
  }

  return next();
};

// ログイン必要なページのため。ログイン後はTOP画面に遷移する。ユーザ情報未入力(!isSignupCompleted)の場合、sign-upページに遷移(signUpWhiteListのpathは除き)
export const authGuardTop = async (to: any, from: any, next: any) => {
  if (to.query.success === 'false') {
    return next({
      path: errorPagePath,
      replace: true,
      query: { error_description: to.query.message }
    });
  }

  const authService = getInstance();

  if (!(await isAuthenticated(authService))) {
    return authService.loginWithRedirect({
      appState: { targetUrl: mainPagePath },
      email: to.query.email
    });
  }

  const isSignupCompleted = authService.user[isSignupCompletedClaimKey];
  if (!isSignupCompleted) {
    if (signUpWhiteList.includes(to.path)) {
      return next();
    }
    return next({ path: signUpPath, replace: true });
  }

  if (signUpWhiteList.includes(to.path)) {
    return next({ path: mainPagePath, replace: true });
  }

  return next();
};

// OPギフトページからサインアップした場合のみフローを変更する必要があるため個別に定義
export const opGiftGuard = async (to: any, from: any, next: any) => {
  if (to.query.success === 'false') {
    return next({
      path: errorPagePath,
      replace: true,
      query: { error_description: to.query.message }
    });
  }

  const authService = getInstance();

  // クエリパラメータにgiftcodeが含まれる場合、localStorageに保存しておく。
  storeQueryToLocalStorage('giftcode', giftCodeKey, to);

  // クエリパラメータにauto-useが含まれる場合、localStorageに保存しておく。
  storeQueryToLocalStorage('auto-use', autoUseKey, to);

  if (!(await isAuthenticated(authService))) {
    // OPギフトへのURLに遷移したことをLocalStorageに保存。サインアップ時のボタン出し分けに利用。
    localStorage.setItem(opGiftKey, 'true');
    return authService.loginWithRedirect({
      appState: { targetUrl: to.fullPath },
      email: to.query.email
    });
  }
  const isSignupCompleted = authService.user[isSignupCompletedClaimKey];
  if (!isSignupCompleted) {
    if (signUpWhiteList.includes(to.path)) {
      return next();
    }
    return next({ path: signUpPath, replace: true });
  }

  if (signUpWhiteList.includes(to.path)) {
    return next({ path: mainPagePath, replace: true });
  }

  return next();
};

// サブスク登録画面ページからサインアップした場合
export const subscriptionGuard = async (to: any, from: any, next: any) => {
  if (to.query.success === 'false') {
    return next({
      path: errorPagePath,
      replace: true,
      query: { error_description: to.query.message }
    });
  }

  const authService = getInstance();

  if (!(await isAuthenticated(authService))) {
    // サブスク登録画面に遷移したことをLocalStorageに保存。サインアップ時のボタン出し分けに利用。
    // パスパラメータとしてサブスクパッケージIDを受け取った場合のみ保存する。
    if (to.params.id) {
      localStorage.setItem(subscriptionKey, to.params.id);
    }
    return authService.loginWithRedirect({
      appState: { targetUrl: to.fullPath },
      email: to.query.email
    });
  }

  const isSignupCompleted = authService.user[isSignupCompletedClaimKey];
  if (!isSignupCompleted) {
    if (signUpWhiteList.includes(to.path)) {
      return next();
    }
    return next({ path: signUpPath, replace: true });
  }

  if (signUpWhiteList.includes(to.path)) {
    return next({ path: mainPagePath, replace: true });
  }

  return next();
};

// ログイン前後とも表示できるが、ログイン後なら、ユーザ情報未入力(!isSignupCompleted)の場合、sign-upページに遷移
export const signupGuard = async (to: any, from: any, next: any) => {
  if (to.query.success === 'false') {
    return next({
      path: errorPagePath,
      replace: true,
      query: { error_description: to.query.message }
    });
  }

  const authService = getInstance();

  if (!(await isAuthenticated(authService))) {
    return next();
  }

  const isSignupCompleted = authService.user[isSignupCompletedClaimKey];
  if (!isSignupCompleted) {
    return next({ path: signUpPath, replace: true });
  }

  return next();
};

// メール未認証時に/confirmページに遷移させる
export const emailVerifyGuard = async (to: any, from: any, next: any) => {
  if (to.query.success === 'false') {
    return next({
      path: errorPagePath,
      replace: true,
      query: { error_description: to.query.message }
    });
  }

  const authService = getInstance();

  if (!(await isAuthenticated(authService))) {
    if (to.path === '/confirm') {
      return next({ path: signUpPath, replace: true });
    }
    return next();
  }

  const isVerifyCompleted = authService.user['email_verified'];
  if (!isVerifyCompleted) {
    // /confirmページにアクセスしたときはそのまま/confirmページを出す(無限リダイレクト対策)
    // https://router.vuejs.org/ja/guide/advanced/navigation-guards.html
    if (to.path === '/confirm') {
      return next();
    }
    return next({ path: '/confirm', replace: true });
  } else {
    if (to.path === '/confirm') {
      return next({ path: '/sign-up', replace: true });
    }
    return next();
  }
};

// モバイルPASMOチャージ完了後に送付されるメールから遷移する場合、フローを変更する必要があるため個別に定義
export const adultPasmoIDiGuard: NavigationGuard = async (to, from, next) => {
  if (to.query.success === 'false') {
    return next({
      path: errorPagePath,
      replace: true,
      query: { error_description: to.query.message }
    });
  }

  const authService = getInstance();

  // クエリパラメータにadult-pasmo-idiが含まれる場合、localStorageに保存しておく。
  if (to.query && to.query['adult-pasmo-idi']) {
    const idi = Array.isArray(to.query['adult-pasmo-idi'])
      ? to.query['adult-pasmo-idi'].shift() || ''
      : to.query['adult-pasmo-idi'];
    localStorage.setItem(adultPasmoIDiKey, idi);
  }

  if (!(await isAuthenticated(authService))) {
    return next();
  }

  const isSignupCompleted = authService.user[isSignupCompletedClaimKey];
  if (!isSignupCompleted) {
    return next({ path: signUpPath, replace: true });
  }

  return next();
};

function storeQueryToLocalStorage(
  queryKey: string,
  storageKey: string,
  to: Route
) {
  if (!to.query || !to.query[queryKey]) {
    return;
  }
  const value = to.query[queryKey];

  // 複数の同名のクエリパラメータが含まれるとき、全て無視する
  if (Array.isArray(value)) {
    return;
  }

  // valueに値が設定されていれば、localStorageに値を設定する
  if (value) {
    localStorage.setItem(storageKey, value);
  }
}
