// tslint:disable
/**
 * titan
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.7-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Error2
 */
export interface Error2 {
    /**
     * Error Code
     * @type {number}
     * @memberof Error2
     */
    code: number;
    /**
     * Error Message
     * @type {string}
     * @memberof Error2
     */
    message: string;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 時刻の操作 - `fix`     時刻を固定する操作（時刻が止まる） - `set`     時刻を指定する操作（時刻が進む） - `add`     設定されている時刻に時間を足す操作 - `restore` 時刻を現在時刻にする操作
     * @type {string}
     * @memberof InlineObject
     */
    operation: InlineObjectOperationEnum;
    /**
     * 時刻の設定値（タイムゾーンも指定すること） - `2022-02-09T08:00:00` operation が fix, set の場合はこの形式 - `2h`                  operation が add の場合は golang の duration にパースできる形式 - operation が restore の場合は入力不要（入力してもパラメタが使われない）
     * @type {string}
     * @memberof InlineObject
     */
    time?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineObjectOperationEnum {
    Fix = 'fix',
    Restore = 'restore',
    Set = 'set',
    Add = 'add'
}

/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 小田急お客様番号
     * @type {string}
     * @memberof InlineObject1
     */
    cardno: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    message?: InlineResponse200MessageEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse200MessageEnum {
    OK = 'OK'
}

/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001
     */
    time?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse2003
     */
    result: boolean;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2004
     */
    cardno: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2004
     */
    oneid_link_st: InlineResponse2004OneidLinkStEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse2004OneidLinkStEnum {
    _0 = '0',
    _1 = '1'
}

/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2005
     */
    cardno: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2006
     */
    new_cardno: string;
}
/**
 * 
 * @export
 * @interface InlineResponse500
 */
export interface InlineResponse500 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse500
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * Error Code
     * @type {number}
     * @memberof ModelError
     */
    code: number;
    /**
     * Error Message
     * @type {string}
     * @memberof ModelError
     */
    message: string;
}
/**
 * APIを有効にしなかった場合
 * @export
 * @interface NotFound404
 */
export interface NotFound404 {
    /**
     * 
     * @type {number}
     * @memberof NotFound404
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof NotFound404
     */
    message: string;
}
/**
 * APIを有効にしなかった場合
 * @export
 * @interface NotFoundError404
 */
export interface NotFoundError404 {
    /**
     * 
     * @type {number}
     * @memberof NotFoundError404
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof NotFoundError404
     */
    message: string;
}
/**
 * エアカード紐付リクエスト
 * @export
 * @interface ReqAirCardLink
 */
export interface ReqAirCardLink {
    /**
     * 小田急お客様番号
     * @type {string}
     * @memberof ReqAirCardLink
     */
    odakyu_customer_no: string;
    /**
     * セキュリティコード
     * @type {string}
     * @memberof ReqAirCardLink
     */
    security_cd: string;
}
/**
 * 認証リクエスト
 * @export
 * @interface ReqAuth
 */
export interface ReqAuth {
    /**
     * 小田急お客様番号 
     * @type {string}
     * @memberof ReqAuth
     */
    odakyu_customer_no: string;
}
/**
 * Auth0 Management API request
 * @export
 * @interface ReqAuth0Admin
 */
export interface ReqAuth0Admin {
    /**
     * 
     * @type {string}
     * @memberof ReqAuth0Admin
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof ReqAuth0Admin
     */
    method?: ReqAuth0AdminMethodEnum;
    /**
     * 
     * @type {object}
     * @memberof ReqAuth0Admin
     */
    body?: object;
}

/**
    * @export
    * @enum {string}
    */
export enum ReqAuth0AdminMethodEnum {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH'
}

/**
 * OPカード紐付/会員登録リクエスト
 * @export
 * @interface ReqLinkWithOpCard
 */
export interface ReqLinkWithOpCard {
    /**
     * 氏(全角のみ)
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    last_name: string;
    /**
     * 名(全角のみ)
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    first_name: string;
    /**
     * 氏（ひらがな）(全角のみ)
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    hira_last_name: string;
    /**
     * 名（ひらがな）(全角のみ)
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    hira_first_name: string;
    /**
     * 郵便番号（前半3桁）
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    zipcode1: string;
    /**
     * 郵便番号（後半4桁）
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    zipcode2: string;
    /**
     * 住所（都道府県） - `01` 北海道 - `02` 青森県 - `03` 岩手県 - `04` 宮城県 - `05` 秋田県 - `06` 山形県 - `07` 福島県 - `08` 茨城県 - `09` 栃木県 - `10` 群馬県 - `11` 埼玉県 - `12` 千葉県 - `13` 東京都 - `14` 神奈川県 - `15` 新潟県 - `16` 富山県 - `17` 石川県 - `18` 福井県 - `19` 山梨県 - `20` 長野県 - `21` 岐阜県 - `22` 静岡県 - `23` 愛知県 - `24` 三重県 - `25` 滋賀県 - `26` 京都府 - `27` 大阪府 - `28` 兵庫県 - `29` 奈良県 - `30` 和歌山県 - `31` 鳥取県 - `32` 島根県 - `33` 岡山県 - `34` 広島県 - `35` 山口県 - `36` 徳島県 - `37` 香川県 - `38` 愛媛県 - `39` 高知県 - `40` 福岡県 - `41` 佐賀県 - `42` 長崎県 - `43` 熊本県 - `44` 大分県 - `45` 宮崎県 - `46` 鹿児島 - `47` 沖縄県
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    pref: ReqLinkWithOpCardPrefEnum;
    /**
     * 住所（市区町村・大字）(全角のみ)
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    city: string;
    /**
     * 住所（丁目）(全角のみ)
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    town: string;
    /**
     * 住所（番地、建物名、部屋番号）(全角のみ)
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    building: string;
    /**
     * 電話番号1_1(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    phone1_1: string;
    /**
     * 電話番号1_2(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    phone1_2: string;
    /**
     * 電話番号1_3(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    phone1_3: string;
    /**
     * 電話番号2_1(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    phone2_1?: string;
    /**
     * 電話番号2_2(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    phone2_2?: string;
    /**
     * 電話番号2_3(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    phone2_3?: string;
    /**
     * 生年月日（年）（パラメータ整合性チェックをアプリで行う）
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    birth_year: string;
    /**
     * 生年月日（月）（パラメータ整合性チェックをアプリで行う）
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    birth_month: string;
    /**
     * 生年月日（日）（パラメータ整合性チェックをアプリで行う）
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    birth_day: string;
    /**
     * 性別区分 - `00` 男性 - `01` 女性 - `02` その他 - `03` 回答しない
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    gender_typ: ReqLinkWithOpCardGenderTypEnum;
    /**
     * ONEからのお知らせメール受信フラグ
     * @type {boolean}
     * @memberof ReqLinkWithOpCard
     */
    promo_mail_from_one_receive_flg: boolean;
    /**
     * OPカードからのお知らせメール受信フラグ
     * @type {boolean}
     * @memberof ReqLinkWithOpCard
     */
    promo_mail_from_op_card_receive_flg: boolean;
    /**
     * 職業区分 - `00` その他 - `01` 会社員 - `02` 公務員、学校、医療機関 - `03` 自営業 - `05` 自由業 - `06` 学生 - `09` パート・アルバイト - `10` 年金生活 - `11` 不動産生活 - `12` 無職、配偶者
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    shokugyou_typ: ReqLinkWithOpCardShokugyouTypEnum;
    /**
     * 小田急お客様番号
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    odakyu_customer_no: string;
    /**
     * セキュリティコード
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    security_cd?: string;
    /**
     * 紐付OPカード区分 - `01` エアカード - `02` 板カード 
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    request_card_type: ReqLinkWithOpCardRequestCardTypeEnum;
    /**
     * 住所カナ（番地、建物名、部屋番号）(半角のみ) 会員管理への属性登録要求でJitakuBanchiKanaとして送るための項目だが、入力なし（スペースのみで埋めた状態）でもよいことをNESに確認済み。 そのためONEからの入力（POST /one/users、 PATCH /one/users）のパラメータには含まれない。 ただしOPアプリからの入力（POST /op/cards/link_with_opcard）のパラメータには含まれており、任意項目として送られる。 https://opcard.backlog.com/view/SYS_IFQA-69#comment-39457503 
     * @type {string}
     * @memberof ReqLinkWithOpCard
     */
    building_kana?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ReqLinkWithOpCardPrefEnum {
    _01 = '01',
    _02 = '02',
    _03 = '03',
    _04 = '04',
    _05 = '05',
    _06 = '06',
    _07 = '07',
    _08 = '08',
    _09 = '09',
    _10 = '10',
    _11 = '11',
    _12 = '12',
    _13 = '13',
    _14 = '14',
    _15 = '15',
    _16 = '16',
    _17 = '17',
    _18 = '18',
    _19 = '19',
    _20 = '20',
    _21 = '21',
    _22 = '22',
    _23 = '23',
    _24 = '24',
    _25 = '25',
    _26 = '26',
    _27 = '27',
    _28 = '28',
    _29 = '29',
    _30 = '30',
    _31 = '31',
    _32 = '32',
    _33 = '33',
    _34 = '34',
    _35 = '35',
    _36 = '36',
    _37 = '37',
    _38 = '38',
    _39 = '39',
    _40 = '40',
    _41 = '41',
    _42 = '42',
    _43 = '43',
    _44 = '44',
    _45 = '45',
    _46 = '46',
    _47 = '47'
}
/**
    * @export
    * @enum {string}
    */
export enum ReqLinkWithOpCardGenderTypEnum {
    _00 = '00',
    _01 = '01',
    _02 = '02',
    _03 = '03'
}
/**
    * @export
    * @enum {string}
    */
export enum ReqLinkWithOpCardShokugyouTypEnum {
    _00 = '00',
    _01 = '01',
    _02 = '02',
    _03 = '03',
    _05 = '05',
    _06 = '06',
    _09 = '09',
    _10 = '10',
    _11 = '11',
    _12 = '12'
}
/**
    * @export
    * @enum {string}
    */
export enum ReqLinkWithOpCardRequestCardTypeEnum {
    _01 = '01',
    _02 = '02'
}

/**
 * 
 * @export
 * @interface ReqLinkWithOpCardAllOf
 */
export interface ReqLinkWithOpCardAllOf {
    /**
     * 小田急お客様番号
     * @type {string}
     * @memberof ReqLinkWithOpCardAllOf
     */
    odakyu_customer_no: string;
    /**
     * セキュリティコード
     * @type {string}
     * @memberof ReqLinkWithOpCardAllOf
     */
    security_cd?: string;
    /**
     * 紐付OPカード区分 - `01` エアカード - `02` 板カード 
     * @type {string}
     * @memberof ReqLinkWithOpCardAllOf
     */
    request_card_type: ReqLinkWithOpCardAllOfRequestCardTypeEnum;
    /**
     * 住所カナ（番地、建物名、部屋番号）(半角のみ) 会員管理への属性登録要求でJitakuBanchiKanaとして送るための項目だが、入力なし（スペースのみで埋めた状態）でもよいことをNESに確認済み。 そのためONEからの入力（POST /one/users、 PATCH /one/users）のパラメータには含まれない。 ただしOPアプリからの入力（POST /op/cards/link_with_opcard）のパラメータには含まれており、任意項目として送られる。 https://opcard.backlog.com/view/SYS_IFQA-69#comment-39457503 
     * @type {string}
     * @memberof ReqLinkWithOpCardAllOf
     */
    building_kana?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ReqLinkWithOpCardAllOfRequestCardTypeEnum {
    _01 = '01',
    _02 = '02'
}

/**
 * 
 * @export
 * @interface ReqMobilepasmoCharge
 */
export interface ReqMobilepasmoCharge {
    /**
     * 小田急お客様番号 ユーザの代表OPカード番号
     * @type {string}
     * @memberof ReqMobilepasmoCharge
     */
    odakyu_customer_no: string;
    /**
     * OP残高 ユーザの代表OPカードのOP残高
     * @type {number}
     * @memberof ReqMobilepasmoCharge
     */
    op_balance: number;
    /**
     * チャージ額
     * @type {number}
     * @memberof ReqMobilepasmoCharge
     */
    charge_amount: number;
    /**
     * パスモ事業者トークン WebView起動都度モバイルPASMOアプリから連携されるトークン
     * @type {string}
     * @memberof ReqMobilepasmoCharge
     */
    pasmo_token: string;
    /**
     * PASMO残高 WebView起動都度モバイルPASMOアプリから連携されるPASMO残高
     * @type {number}
     * @memberof ReqMobilepasmoCharge
     */
    pasmo_balance: number;
    /**
     * IDi WebView起動都度モバイルPASMOアプリから連携されるチャージ対象IDi
     * @type {string}
     * @memberof ReqMobilepasmoCharge
     */
    idi: string;
    /**
     * 無記名フラグ WebView起動都度モバイルPASMOアプリから連携される チャージ対象IDiが記名PASMOの場合、0となる
     * @type {string}
     * @memberof ReqMobilepasmoCharge
     */
    is_anonymous: ReqMobilepasmoChargeIsAnonymousEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ReqMobilepasmoChargeIsAnonymousEnum {
    _0 = '0',
    _1 = '1'
}

/**
 * Oneユーザ登録リクエスト
 * @export
 * @interface ReqOneUserCreate
 */
export interface ReqOneUserCreate {
    /**
     * 氏(全角のみ)
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    last_name: string;
    /**
     * 名(全角のみ)
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    first_name: string;
    /**
     * 氏（ひらがな）(全角のみ)
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    hira_last_name: string;
    /**
     * 名（ひらがな）(全角のみ)
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    hira_first_name: string;
    /**
     * 郵便番号（前半3桁）
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    zipcode1: string;
    /**
     * 郵便番号（後半4桁）
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    zipcode2: string;
    /**
     * 住所（都道府県） - `01` 北海道 - `02` 青森県 - `03` 岩手県 - `04` 宮城県 - `05` 秋田県 - `06` 山形県 - `07` 福島県 - `08` 茨城県 - `09` 栃木県 - `10` 群馬県 - `11` 埼玉県 - `12` 千葉県 - `13` 東京都 - `14` 神奈川県 - `15` 新潟県 - `16` 富山県 - `17` 石川県 - `18` 福井県 - `19` 山梨県 - `20` 長野県 - `21` 岐阜県 - `22` 静岡県 - `23` 愛知県 - `24` 三重県 - `25` 滋賀県 - `26` 京都府 - `27` 大阪府 - `28` 兵庫県 - `29` 奈良県 - `30` 和歌山県 - `31` 鳥取県 - `32` 島根県 - `33` 岡山県 - `34` 広島県 - `35` 山口県 - `36` 徳島県 - `37` 香川県 - `38` 愛媛県 - `39` 高知県 - `40` 福岡県 - `41` 佐賀県 - `42` 長崎県 - `43` 熊本県 - `44` 大分県 - `45` 宮崎県 - `46` 鹿児島 - `47` 沖縄県
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    pref: ReqOneUserCreatePrefEnum;
    /**
     * 住所（市区町村・大字）(全角のみ)
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    city: string;
    /**
     * 住所（丁目）(全角のみ)
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    town: string;
    /**
     * 住所（番地、建物名、部屋番号）(全角のみ)
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    building: string;
    /**
     * 電話番号1_1(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    phone1_1: string;
    /**
     * 電話番号1_2(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    phone1_2: string;
    /**
     * 電話番号1_3(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    phone1_3: string;
    /**
     * 電話番号2_1(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    phone2_1?: string;
    /**
     * 電話番号2_2(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    phone2_2?: string;
    /**
     * 電話番号2_3(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    phone2_3?: string;
    /**
     * 生年月日（年）（パラメータ整合性チェックをアプリで行う）
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    birth_year: string;
    /**
     * 生年月日（月）（パラメータ整合性チェックをアプリで行う）
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    birth_month: string;
    /**
     * 生年月日（日）（パラメータ整合性チェックをアプリで行う）
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    birth_day: string;
    /**
     * 性別区分 - `00` 男性 - `01` 女性 - `02` その他 - `03` 回答しない
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    gender_typ: ReqOneUserCreateGenderTypEnum;
    /**
     * ONEからのお知らせメール受信フラグ
     * @type {boolean}
     * @memberof ReqOneUserCreate
     */
    promo_mail_from_one_receive_flg: boolean;
    /**
     * OPカードからのお知らせメール受信フラグ
     * @type {boolean}
     * @memberof ReqOneUserCreate
     */
    promo_mail_from_op_card_receive_flg: boolean;
    /**
     * 職業区分 - `00` その他 - `01` 会社員 - `02` 公務員、学校、医療機関 - `03` 自営業 - `05` 自由業 - `06` 学生 - `09` パート・アルバイト - `10` 年金生活 - `11` 不動産生活 - `12` 無職、配偶者
     * @type {string}
     * @memberof ReqOneUserCreate
     */
    shokugyou_typ: ReqOneUserCreateShokugyouTypEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ReqOneUserCreatePrefEnum {
    _01 = '01',
    _02 = '02',
    _03 = '03',
    _04 = '04',
    _05 = '05',
    _06 = '06',
    _07 = '07',
    _08 = '08',
    _09 = '09',
    _10 = '10',
    _11 = '11',
    _12 = '12',
    _13 = '13',
    _14 = '14',
    _15 = '15',
    _16 = '16',
    _17 = '17',
    _18 = '18',
    _19 = '19',
    _20 = '20',
    _21 = '21',
    _22 = '22',
    _23 = '23',
    _24 = '24',
    _25 = '25',
    _26 = '26',
    _27 = '27',
    _28 = '28',
    _29 = '29',
    _30 = '30',
    _31 = '31',
    _32 = '32',
    _33 = '33',
    _34 = '34',
    _35 = '35',
    _36 = '36',
    _37 = '37',
    _38 = '38',
    _39 = '39',
    _40 = '40',
    _41 = '41',
    _42 = '42',
    _43 = '43',
    _44 = '44',
    _45 = '45',
    _46 = '46',
    _47 = '47'
}
/**
    * @export
    * @enum {string}
    */
export enum ReqOneUserCreateGenderTypEnum {
    _00 = '00',
    _01 = '01',
    _02 = '02',
    _03 = '03'
}
/**
    * @export
    * @enum {string}
    */
export enum ReqOneUserCreateShokugyouTypEnum {
    _00 = '00',
    _01 = '01',
    _02 = '02',
    _03 = '03',
    _05 = '05',
    _06 = '06',
    _09 = '09',
    _10 = '10',
    _11 = '11',
    _12 = '12'
}

/**
 * OPカード紐付/会員登録リクエスト
 * @export
 * @interface ReqOneUserCreateFromOpapp
 */
export interface ReqOneUserCreateFromOpapp {
    /**
     * 氏(全角のみ)
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    last_name: string;
    /**
     * 名(全角のみ)
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    first_name: string;
    /**
     * 氏（ひらがな）(全角のみ)
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    hira_last_name: string;
    /**
     * 名（ひらがな）(全角のみ)
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    hira_first_name: string;
    /**
     * 郵便番号（前半3桁）
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    zipcode1: string;
    /**
     * 郵便番号（後半4桁）
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    zipcode2: string;
    /**
     * 住所（都道府県） - `01` 北海道 - `02` 青森県 - `03` 岩手県 - `04` 宮城県 - `05` 秋田県 - `06` 山形県 - `07` 福島県 - `08` 茨城県 - `09` 栃木県 - `10` 群馬県 - `11` 埼玉県 - `12` 千葉県 - `13` 東京都 - `14` 神奈川県 - `15` 新潟県 - `16` 富山県 - `17` 石川県 - `18` 福井県 - `19` 山梨県 - `20` 長野県 - `21` 岐阜県 - `22` 静岡県 - `23` 愛知県 - `24` 三重県 - `25` 滋賀県 - `26` 京都府 - `27` 大阪府 - `28` 兵庫県 - `29` 奈良県 - `30` 和歌山県 - `31` 鳥取県 - `32` 島根県 - `33` 岡山県 - `34` 広島県 - `35` 山口県 - `36` 徳島県 - `37` 香川県 - `38` 愛媛県 - `39` 高知県 - `40` 福岡県 - `41` 佐賀県 - `42` 長崎県 - `43` 熊本県 - `44` 大分県 - `45` 宮崎県 - `46` 鹿児島 - `47` 沖縄県
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    pref: ReqOneUserCreateFromOpappPrefEnum;
    /**
     * 住所（市区町村・大字）(全角のみ)
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    city: string;
    /**
     * 住所（丁目）(全角のみ)
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    town: string;
    /**
     * 住所（番地、建物名、部屋番号）(全角のみ)
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    building: string;
    /**
     * 電話番号1_1(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    phone1_1: string;
    /**
     * 電話番号1_2(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    phone1_2: string;
    /**
     * 電話番号1_3(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    phone1_3: string;
    /**
     * 電話番号2_1(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    phone2_1?: string;
    /**
     * 電話番号2_2(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    phone2_2?: string;
    /**
     * 電話番号2_3(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    phone2_3?: string;
    /**
     * 生年月日（年）（パラメータ整合性チェックをアプリで行う）
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    birth_year: string;
    /**
     * 生年月日（月）（パラメータ整合性チェックをアプリで行う）
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    birth_month: string;
    /**
     * 生年月日（日）（パラメータ整合性チェックをアプリで行う）
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    birth_day: string;
    /**
     * 性別区分 - `00` 男性 - `01` 女性 - `02` その他 - `03` 回答しない
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    gender_typ: ReqOneUserCreateFromOpappGenderTypEnum;
    /**
     * ONEからのお知らせメール受信フラグ
     * @type {boolean}
     * @memberof ReqOneUserCreateFromOpapp
     */
    promo_mail_from_one_receive_flg: boolean;
    /**
     * OPカードからのお知らせメール受信フラグ
     * @type {boolean}
     * @memberof ReqOneUserCreateFromOpapp
     */
    promo_mail_from_op_card_receive_flg: boolean;
    /**
     * 職業区分 - `00` その他 - `01` 会社員 - `02` 公務員、学校、医療機関 - `03` 自営業 - `05` 自由業 - `06` 学生 - `09` パート・アルバイト - `10` 年金生活 - `11` 不動産生活 - `12` 無職、配偶者
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    shokugyou_typ: ReqOneUserCreateFromOpappShokugyouTypEnum;
    /**
     * ユーザ入力板カードの小田急お客様番号
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    odakyu_customer_no?: string;
    /**
     * セキュリティコード
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    security_cd?: string;
    /**
     * 事前にOPアプリで発番されたエアカードの小田急客様番号
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    opapp_air_odakyu_customer_no?: string;
    /**
     * 事前にOPアプリで発番されたエアカードのセキュリティコード
     * @type {string}
     * @memberof ReqOneUserCreateFromOpapp
     */
    opapp_air_security_cd?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ReqOneUserCreateFromOpappPrefEnum {
    _01 = '01',
    _02 = '02',
    _03 = '03',
    _04 = '04',
    _05 = '05',
    _06 = '06',
    _07 = '07',
    _08 = '08',
    _09 = '09',
    _10 = '10',
    _11 = '11',
    _12 = '12',
    _13 = '13',
    _14 = '14',
    _15 = '15',
    _16 = '16',
    _17 = '17',
    _18 = '18',
    _19 = '19',
    _20 = '20',
    _21 = '21',
    _22 = '22',
    _23 = '23',
    _24 = '24',
    _25 = '25',
    _26 = '26',
    _27 = '27',
    _28 = '28',
    _29 = '29',
    _30 = '30',
    _31 = '31',
    _32 = '32',
    _33 = '33',
    _34 = '34',
    _35 = '35',
    _36 = '36',
    _37 = '37',
    _38 = '38',
    _39 = '39',
    _40 = '40',
    _41 = '41',
    _42 = '42',
    _43 = '43',
    _44 = '44',
    _45 = '45',
    _46 = '46',
    _47 = '47'
}
/**
    * @export
    * @enum {string}
    */
export enum ReqOneUserCreateFromOpappGenderTypEnum {
    _00 = '00',
    _01 = '01',
    _02 = '02',
    _03 = '03'
}
/**
    * @export
    * @enum {string}
    */
export enum ReqOneUserCreateFromOpappShokugyouTypEnum {
    _00 = '00',
    _01 = '01',
    _02 = '02',
    _03 = '03',
    _05 = '05',
    _06 = '06',
    _09 = '09',
    _10 = '10',
    _11 = '11',
    _12 = '12'
}

/**
 * 
 * @export
 * @interface ReqOneUserCreateFromOpappAllOf
 */
export interface ReqOneUserCreateFromOpappAllOf {
    /**
     * 事前にOPアプリで発番されたエアカードの小田急客様番号
     * @type {string}
     * @memberof ReqOneUserCreateFromOpappAllOf
     */
    opapp_air_odakyu_customer_no?: string;
    /**
     * 事前にOPアプリで発番されたエアカードのセキュリティコード
     * @type {string}
     * @memberof ReqOneUserCreateFromOpappAllOf
     */
    opapp_air_security_cd?: string;
}
/**
 * ONE退会リクエスト
 * @export
 * @interface ReqOneUserDelete
 */
export interface ReqOneUserDelete {
    /**
     * OPシステム退会小田急お客さま番号一覧
     * @type {Array<string>}
     * @memberof ReqOneUserDelete
     */
    opsys_unregistration_odakyu_customer_no_list: Array<string>;
}
/**
 * ONE新規登録リクエスト
 * @export
 * @interface ReqOneUserPost
 */
export interface ReqOneUserPost {
    /**
     * 氏(全角のみ)
     * @type {string}
     * @memberof ReqOneUserPost
     */
    last_name: string;
    /**
     * 名(全角のみ)
     * @type {string}
     * @memberof ReqOneUserPost
     */
    first_name: string;
    /**
     * 氏（ひらがな）(全角のみ)
     * @type {string}
     * @memberof ReqOneUserPost
     */
    hira_last_name: string;
    /**
     * 名（ひらがな）(全角のみ)
     * @type {string}
     * @memberof ReqOneUserPost
     */
    hira_first_name: string;
    /**
     * 郵便番号（前半3桁）
     * @type {string}
     * @memberof ReqOneUserPost
     */
    zipcode1: string;
    /**
     * 郵便番号（後半4桁）
     * @type {string}
     * @memberof ReqOneUserPost
     */
    zipcode2: string;
    /**
     * 住所（都道府県） - `01` 北海道 - `02` 青森県 - `03` 岩手県 - `04` 宮城県 - `05` 秋田県 - `06` 山形県 - `07` 福島県 - `08` 茨城県 - `09` 栃木県 - `10` 群馬県 - `11` 埼玉県 - `12` 千葉県 - `13` 東京都 - `14` 神奈川県 - `15` 新潟県 - `16` 富山県 - `17` 石川県 - `18` 福井県 - `19` 山梨県 - `20` 長野県 - `21` 岐阜県 - `22` 静岡県 - `23` 愛知県 - `24` 三重県 - `25` 滋賀県 - `26` 京都府 - `27` 大阪府 - `28` 兵庫県 - `29` 奈良県 - `30` 和歌山県 - `31` 鳥取県 - `32` 島根県 - `33` 岡山県 - `34` 広島県 - `35` 山口県 - `36` 徳島県 - `37` 香川県 - `38` 愛媛県 - `39` 高知県 - `40` 福岡県 - `41` 佐賀県 - `42` 長崎県 - `43` 熊本県 - `44` 大分県 - `45` 宮崎県 - `46` 鹿児島 - `47` 沖縄県
     * @type {string}
     * @memberof ReqOneUserPost
     */
    pref: ReqOneUserPostPrefEnum;
    /**
     * 住所（市区町村・大字）(全角のみ)
     * @type {string}
     * @memberof ReqOneUserPost
     */
    city: string;
    /**
     * 住所（丁目）(全角のみ)
     * @type {string}
     * @memberof ReqOneUserPost
     */
    town: string;
    /**
     * 住所（番地、建物名、部屋番号）(全角のみ)
     * @type {string}
     * @memberof ReqOneUserPost
     */
    building: string;
    /**
     * 電話番号1_1(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqOneUserPost
     */
    phone1_1: string;
    /**
     * 電話番号1_2(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqOneUserPost
     */
    phone1_2: string;
    /**
     * 電話番号1_3(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqOneUserPost
     */
    phone1_3: string;
    /**
     * 電話番号2_1(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqOneUserPost
     */
    phone2_1?: string;
    /**
     * 電話番号2_2(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqOneUserPost
     */
    phone2_2?: string;
    /**
     * 電話番号2_3(電話番号1/2/3で20桁以下となること)
     * @type {string}
     * @memberof ReqOneUserPost
     */
    phone2_3?: string;
    /**
     * 生年月日（年）（パラメータ整合性チェックをアプリで行う）
     * @type {string}
     * @memberof ReqOneUserPost
     */
    birth_year: string;
    /**
     * 生年月日（月）（パラメータ整合性チェックをアプリで行う）
     * @type {string}
     * @memberof ReqOneUserPost
     */
    birth_month: string;
    /**
     * 生年月日（日）（パラメータ整合性チェックをアプリで行う）
     * @type {string}
     * @memberof ReqOneUserPost
     */
    birth_day: string;
    /**
     * 性別区分 - `00` 男性 - `01` 女性 - `02` その他 - `03` 回答しない
     * @type {string}
     * @memberof ReqOneUserPost
     */
    gender_typ: ReqOneUserPostGenderTypEnum;
    /**
     * ONEからのお知らせメール受信フラグ
     * @type {boolean}
     * @memberof ReqOneUserPost
     */
    promo_mail_from_one_receive_flg: boolean;
    /**
     * OPカードからのお知らせメール受信フラグ
     * @type {boolean}
     * @memberof ReqOneUserPost
     */
    promo_mail_from_op_card_receive_flg: boolean;
    /**
     * 職業区分 - `00` その他 - `01` 会社員 - `02` 公務員、学校、医療機関 - `03` 自営業 - `05` 自由業 - `06` 学生 - `09` パート・アルバイト - `10` 年金生活 - `11` 不動産生活 - `12` 無職、配偶者
     * @type {string}
     * @memberof ReqOneUserPost
     */
    shokugyou_typ: ReqOneUserPostShokugyouTypEnum;
    /**
     * ユーザ入力板カードの小田急お客様番号
     * @type {string}
     * @memberof ReqOneUserPost
     */
    odakyu_customer_no?: string;
    /**
     * セキュリティコード
     * @type {string}
     * @memberof ReqOneUserPost
     */
    security_cd?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ReqOneUserPostPrefEnum {
    _01 = '01',
    _02 = '02',
    _03 = '03',
    _04 = '04',
    _05 = '05',
    _06 = '06',
    _07 = '07',
    _08 = '08',
    _09 = '09',
    _10 = '10',
    _11 = '11',
    _12 = '12',
    _13 = '13',
    _14 = '14',
    _15 = '15',
    _16 = '16',
    _17 = '17',
    _18 = '18',
    _19 = '19',
    _20 = '20',
    _21 = '21',
    _22 = '22',
    _23 = '23',
    _24 = '24',
    _25 = '25',
    _26 = '26',
    _27 = '27',
    _28 = '28',
    _29 = '29',
    _30 = '30',
    _31 = '31',
    _32 = '32',
    _33 = '33',
    _34 = '34',
    _35 = '35',
    _36 = '36',
    _37 = '37',
    _38 = '38',
    _39 = '39',
    _40 = '40',
    _41 = '41',
    _42 = '42',
    _43 = '43',
    _44 = '44',
    _45 = '45',
    _46 = '46',
    _47 = '47'
}
/**
    * @export
    * @enum {string}
    */
export enum ReqOneUserPostGenderTypEnum {
    _00 = '00',
    _01 = '01',
    _02 = '02',
    _03 = '03'
}
/**
    * @export
    * @enum {string}
    */
export enum ReqOneUserPostShokugyouTypEnum {
    _00 = '00',
    _01 = '01',
    _02 = '02',
    _03 = '03',
    _05 = '05',
    _06 = '06',
    _09 = '09',
    _10 = '10',
    _11 = '11',
    _12 = '12'
}

/**
 * 
 * @export
 * @interface ReqOneUserPostAllOf
 */
export interface ReqOneUserPostAllOf {
    /**
     * ユーザ入力板カードの小田急お客様番号
     * @type {string}
     * @memberof ReqOneUserPostAllOf
     */
    odakyu_customer_no?: string;
    /**
     * セキュリティコード
     * @type {string}
     * @memberof ReqOneUserPostAllOf
     */
    security_cd?: string;
}
/**
 * ポイント還元自動取消リクエスト
 * @export
 * @interface ReqOpAutoCancelUsePoint
 */
export interface ReqOpAutoCancelUsePoint {
    /**
     * 端末番号(TermID) 端末番号はクライアント側で任意に設定。 
     * @type {number}
     * @memberof ReqOpAutoCancelUsePoint
     */
    device_no: number;
    /**
     * 取引番号 ECOpに送る際もパディングは不要 
     * @type {number}
     * @memberof ReqOpAutoCancelUsePoint
     */
    tx_id: number;
    /**
     * 取引日(yyyymmdd)
     * @type {string}
     * @memberof ReqOpAutoCancelUsePoint
     */
    business_date: string;
    /**
     * 取引時刻(hhmmssmmm)
     * @type {string}
     * @memberof ReqOpAutoCancelUsePoint
     */
    time: string;
    /**
     * 小田急お客様番号(PointID) 
     * @type {string}
     * @memberof ReqOpAutoCancelUsePoint
     */
    odakyu_customer_no?: string | null;
    /**
     * 取消対象端末番号(CanTermID) 
     * @type {number}
     * @memberof ReqOpAutoCancelUsePoint
     */
    can_device_no: number | null;
    /**
     * 取消対象取引番号 ECOPに送る際もパディングは不要 
     * @type {number}
     * @memberof ReqOpAutoCancelUsePoint
     */
    can_tx_id: number | null;
    /**
     * 取消対象取引日(yyyymmdd)
     * @type {string}
     * @memberof ReqOpAutoCancelUsePoint
     */
    can_business_date: string | null;
    /**
     * 取消対象取引時刻(hhmmssmmm)
     * @type {string}
     * @memberof ReqOpAutoCancelUsePoint
     */
    can_time: string | null;
}
/**
 * ポイント付与取消リクエスト
 * @export
 * @interface ReqOpCancelGivePoint
 */
export interface ReqOpCancelGivePoint {
    /**
     * 端末番号(TermID) 端末番号はクライアント側で任意に設定。 
     * @type {number}
     * @memberof ReqOpCancelGivePoint
     */
    device_no: number;
    /**
     * 取引番号 ECOpに送る際もパディングは不要 
     * @type {number}
     * @memberof ReqOpCancelGivePoint
     */
    tx_id: number;
    /**
     * 取引日(yyyymmdd)
     * @type {string}
     * @memberof ReqOpCancelGivePoint
     */
    business_date: string;
    /**
     * 取引時刻(hhmmssmmm)
     * @type {string}
     * @memberof ReqOpCancelGivePoint
     */
    time: string;
    /**
     * ポイント対象金額
     * @type {number}
     * @memberof ReqOpCancelGivePoint
     */
    point_target_amount?: number | null;
    /**
     * ポイント非対象金額
     * @type {number}
     * @memberof ReqOpCancelGivePoint
     */
    point_exclude_amount?: number | null;
    /**
     * 付与ポイント数
     * @type {number}
     * @memberof ReqOpCancelGivePoint
     */
    award_point?: number | null;
    /**
     * 小田急お客様番号(PointID) 
     * @type {string}
     * @memberof ReqOpCancelGivePoint
     */
    odakyu_customer_no?: string | null;
    /**
     * 取消対象端末番号(CanTermID) 
     * @type {number}
     * @memberof ReqOpCancelGivePoint
     */
    can_device_no?: number | null;
    /**
     * 取消対象取引番号 ECOPに送る際もパディングは不要 
     * @type {number}
     * @memberof ReqOpCancelGivePoint
     */
    can_tx_id?: number | null;
    /**
     * 取消対象取引日(yyyymmdd)
     * @type {string}
     * @memberof ReqOpCancelGivePoint
     */
    can_business_date?: string | null;
    /**
     * 取消対象取引時刻(hhmmssmmm)
     * @type {string}
     * @memberof ReqOpCancelGivePoint
     */
    can_time?: string | null;
}
/**
 * ポイント還元取消リクエスト
 * @export
 * @interface ReqOpCancelUsePoint
 */
export interface ReqOpCancelUsePoint {
    /**
     * 端末番号(TermID) 端末番号はクライアント側で任意に設定。 
     * @type {number}
     * @memberof ReqOpCancelUsePoint
     */
    device_no: number;
    /**
     * 取引番号 ECOpに送る際もパディングは不要 
     * @type {number}
     * @memberof ReqOpCancelUsePoint
     */
    tx_id: number;
    /**
     * 取引日(yyyymmdd)
     * @type {string}
     * @memberof ReqOpCancelUsePoint
     */
    business_date: string;
    /**
     * 取引時刻(hhmmssmmm)
     * @type {string}
     * @memberof ReqOpCancelUsePoint
     */
    time: string;
    /**
     * 還元ポイント数 ※符号はトランザクションタイプで判定 
     * @type {number}
     * @memberof ReqOpCancelUsePoint
     */
    reward_point?: number | null;
    /**
     * 小田急お客様番号(PointID) 
     * @type {string}
     * @memberof ReqOpCancelUsePoint
     */
    odakyu_customer_no?: string | null;
    /**
     * 取消対象端末番号(CanTermID) 
     * @type {number}
     * @memberof ReqOpCancelUsePoint
     */
    can_device_no?: number | null;
    /**
     * 取消対象取引番号 ECOPに送る際もパディングは不要 
     * @type {number}
     * @memberof ReqOpCancelUsePoint
     */
    can_tx_id?: number | null;
    /**
     * 取消対象取引日(yyyymmdd)
     * @type {string}
     * @memberof ReqOpCancelUsePoint
     */
    can_business_date?: string | null;
    /**
     * 取消対象取引時刻(hhmmssmmm)
     * @type {string}
     * @memberof ReqOpCancelUsePoint
     */
    can_time?: string | null;
}
/**
 * OPカード番号リクエスト。登録/削除に使用する。
 * @export
 * @interface ReqOpCard
 */
export interface ReqOpCard {
    /**
     * 小田急お客様番号
     * @type {string}
     * @memberof ReqOpCard
     */
    odakyu_customer_no: string;
}
/**
 * ONE退会リクエスト
 * @export
 * @interface ReqOpCardDelete
 */
export interface ReqOpCardDelete {
    /**
     * 小田急お客様番号
     * @type {string}
     * @memberof ReqOpCardDelete
     */
    odakyu_customer_no: string;
    /**
     * OPシステム退会フラグ
     * @type {boolean}
     * @memberof ReqOpCardDelete
     */
    opsys_unregistration_flg: boolean;
}
/**
 * OPシステム退会情報
 * @export
 * @interface ReqOpCardDeleteAllOf
 */
export interface ReqOpCardDeleteAllOf {
    /**
     * OPシステム退会フラグ
     * @type {boolean}
     * @memberof ReqOpCardDeleteAllOf
     */
    opsys_unregistration_flg: boolean;
}
/**
 * 
 * @export
 * @interface ReqOpCardPost
 */
export interface ReqOpCardPost {
    /**
     * 小田急お客様番号
     * @type {string}
     * @memberof ReqOpCardPost
     */
    odakyu_customer_no: string;
    /**
     * 代表カードフラグ
     * @type {boolean}
     * @memberof ReqOpCardPost
     */
    main_flg: boolean;
    /**
     * セキュリティコード
     * @type {string}
     * @memberof ReqOpCardPost
     */
    security_cd?: string;
}
/**
 * 
 * @export
 * @interface ReqOpCardPostAllOf
 */
export interface ReqOpCardPostAllOf {
    /**
     * 代表カードフラグ
     * @type {boolean}
     * @memberof ReqOpCardPostAllOf
     */
    main_flg: boolean;
    /**
     * セキュリティコード
     * @type {string}
     * @memberof ReqOpCardPostAllOf
     */
    security_cd?: string;
}
/**
 * ポイント付与リクエスト
 * @export
 * @interface ReqOpGivePoint
 */
export interface ReqOpGivePoint {
    /**
     * 端末番号(TermID) 端末番号はクライアント側で任意に設定。 
     * @type {number}
     * @memberof ReqOpGivePoint
     */
    device_no: number;
    /**
     * 取引番号 ECOpに送る際もパディングは不要 
     * @type {number}
     * @memberof ReqOpGivePoint
     */
    tx_id: number;
    /**
     * 取引日(yyyymmdd)
     * @type {string}
     * @memberof ReqOpGivePoint
     */
    business_date: string;
    /**
     * 取引時刻(hhmmssmmm)
     * @type {string}
     * @memberof ReqOpGivePoint
     */
    time: string;
    /**
     * ポイント対象金額
     * @type {number}
     * @memberof ReqOpGivePoint
     */
    point_target_amount: number;
    /**
     * ポイント非対称金額
     * @type {number}
     * @memberof ReqOpGivePoint
     */
    point_exclude_amount: number;
    /**
     * 付与ポイント数
     * @type {number}
     * @memberof ReqOpGivePoint
     */
    award_point: number;
    /**
     * 小田急お客様番号(PointID) 
     * @type {string}
     * @memberof ReqOpGivePoint
     */
    odakyu_customer_no?: string | null;
}
/**
 * ポイント還元リクエスト
 * @export
 * @interface ReqOpUsePoint
 */
export interface ReqOpUsePoint {
    /**
     * 端末番号(TermID) 端末番号はクライアント側で任意に設定。 
     * @type {number}
     * @memberof ReqOpUsePoint
     */
    device_no: number;
    /**
     * 取引番号 ECOpに送る際もパディングは不要 
     * @type {number}
     * @memberof ReqOpUsePoint
     */
    tx_id: number;
    /**
     * 取引日(yyyymmdd)
     * @type {string}
     * @memberof ReqOpUsePoint
     */
    business_date: string;
    /**
     * 取引時刻(hhmmssmmm)
     * @type {string}
     * @memberof ReqOpUsePoint
     */
    time: string;
    /**
     * 還元ポイント数 （符号はトランザクションタイプで判定） 
     * @type {number}
     * @memberof ReqOpUsePoint
     */
    reward_point: number;
    /**
     * 小田急お客様番号(PointID) 
     * @type {string}
     * @memberof ReqOpUsePoint
     */
    odakyu_customer_no?: string | null;
}
/**
 * 
 * @export
 * @interface ReqPostCardSwitch
 */
export interface ReqPostCardSwitch {
    /**
     * 処理区分 \"22\":再発行・カードＳＷ・カード移行（固定）
     * @type {string}
     * @memberof ReqPostCardSwitch
     */
    syori_kbn: ReqPostCardSwitchSyoriKbnEnum;
    /**
     * 小田急お客様番号
     * @type {string}
     * @memberof ReqPostCardSwitch
     */
    cardno: string;
    /**
     * 集計番号
     * @type {string}
     * @memberof ReqPostCardSwitch
     */
    card_grpno: string;
    /**
     * 集計子番号
     * @type {string}
     * @memberof ReqPostCardSwitch
     */
    card_grp_kono: string;
    /**
     * 親子区分 - `0` 親カード - `1` 子カード 
     * @type {string}
     * @memberof ReqPostCardSwitch
     */
    oyako_kbn: ReqPostCardSwitchOyakoKbnEnum;
    /**
     * 親カード番号
     * @type {string}
     * @memberof ReqPostCardSwitch
     */
    oya_cardno?: string;
    /**
     * 旧カード番号
     * @type {string}
     * @memberof ReqPostCardSwitch
     */
    old_cardno: string;
    /**
     * 旧集計番号
     * @type {string}
     * @memberof ReqPostCardSwitch
     */
    old_card_grpno: string;
    /**
     * 旧子集計番号
     * @type {string}
     * @memberof ReqPostCardSwitch
     */
    old_card_grp_kono: string;
    /**
     * 口座ステータス - `00` 通常 - `07` 紛失 - `47` 退会 - `57` 有効期限満了 
     * @type {string}
     * @memberof ReqPostCardSwitch
     */
    koza_st: ReqPostCardSwitchKozaStEnum;
    /**
     * カード種別 - `10` 小田急ポイントクレジットカード - `11` 小田急ポイントＪＣＢカード - `12` 小田急ポイントＶＩＳＡカード - `13` 小田急ポイントＭａｓｔｅｒＣａｒｄ - `14` 小田急ポイントＪＣＢカードゴールド - `15` 小田急ポイントＶＩＳＡカードゴールド - `16` 小田急ポイントＭａｓｔｅｒＣａｒｄゴールド - `20` 小田急ポイントカード（旧ＯＰ） - `21` 小田急ポイントカード（新ＯＰ） - `22` 小田急ポイントカード（仮ＯＰ） - `31` 小田急ポイントＪＣＢカード（子） - `32` 小田急ポイントＵＦＪＣカード（子） - `40` 小田急ポイントカード（白） - `41` 小田急ポイントカード（白・仮） - `51` 小田急ポイントＪＣＢカード（白・子） - `52` 小田急ポイントＵＦＪＣカード（白・子） 
     * @type {string}
     * @memberof ReqPostCardSwitch
     */
    card_sbt: ReqPostCardSwitchCardSbtEnum;
    /**
     * 有効期限（MMYY） 有効期限が20790331 or 99999999の場合は、0000で返却する 
     * @type {string}
     * @memberof ReqPostCardSwitch
     */
    card_yuko_ym: string;
    /**
     * UUID
     * @type {string}
     * @memberof ReqPostCardSwitch
     */
    uuid: string;
    /**
     * 発生日付(YYYYMMDD)
     * @type {string}
     * @memberof ReqPostCardSwitch
     */
    cre_day: string;
    /**
     * 発生時刻(HHMMSSmmm)
     * @type {string}
     * @memberof ReqPostCardSwitch
     */
    cre_tme: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ReqPostCardSwitchSyoriKbnEnum {
    _22 = '22'
}
/**
    * @export
    * @enum {string}
    */
export enum ReqPostCardSwitchOyakoKbnEnum {
    _0 = '0',
    _1 = '1'
}
/**
    * @export
    * @enum {string}
    */
export enum ReqPostCardSwitchKozaStEnum {
    _00 = '00',
    _07 = '07',
    _47 = '47',
    _57 = '57'
}
/**
    * @export
    * @enum {string}
    */
export enum ReqPostCardSwitchCardSbtEnum {
    _10 = '10',
    _11 = '11',
    _12 = '12',
    _13 = '13',
    _14 = '14',
    _15 = '15',
    _16 = '16',
    _20 = '20',
    _21 = '21',
    _22 = '22',
    _31 = '31',
    _32 = '32',
    _40 = '40',
    _41 = '41',
    _51 = '51',
    _52 = '52'
}

/**
 * JALマイル交換リクエスト
 * @export
 * @interface ReqPostJalMileExchange
 */
export interface ReqPostJalMileExchange {
    /**
     * 端末番号(TermID) 端末番号はクライアント側で任意に設定
     * @type {number}
     * @memberof ReqPostJalMileExchange
     */
    device_no: number;
    /**
     * 交換ポイント
     * @type {number}
     * @memberof ReqPostJalMileExchange
     */
    exchange_point: number;
}
/**
 * ポイント交換リクエスト
 * @export
 * @interface ReqPostOpExchange
 */
export interface ReqPostOpExchange {
    /**
     * 端末番号(TermID) 端末番号はクライアント側で任意に設定
     * @type {number}
     * @memberof ReqPostOpExchange
     */
    device_no: number;
    /**
     * ポイント交換商品ID 画面で取得した商品のmicroCMS上のコンテンツID
     * @type {string}
     * @memberof ReqPostOpExchange
     */
    item_id: string;
    /**
     * SKU単位商品名 ユーザが選択したSKU単位商品名
     * @type {string}
     * @memberof ReqPostOpExchange
     */
    sku_item_name?: string;
    /**
     * 交換ポイント数 画面で取得した交換ポイント数
     * @type {number}
     * @memberof ReqPostOpExchange
     */
    point_amount: number;
    /**
     * 小田急お客様番号
     * @type {string}
     * @memberof ReqPostOpExchange
     */
    odakyu_customer_no: string;
}
/**
 * OPギフト登録リクエスト
 * @export
 * @interface ReqPostOpGift
 */
export interface ReqPostOpGift {
    /**
     * ギフトコード
     * @type {string}
     * @memberof ReqPostOpGift
     */
    gift_cd: string;
}
/**
 * 
 * @export
 * @interface ReqPostUpdateStatus
 */
export interface ReqPostUpdateStatus {
    /**
     * 小田急お客様番号
     * @type {string}
     * @memberof ReqPostUpdateStatus
     */
    cardno: string;
    /**
     * 口座ステータス - `00` 通常 - `07` 紛失 - `47` 退会 - `57` 有効期限満了 
     * @type {string}
     * @memberof ReqPostUpdateStatus
     */
    koza_st: ReqPostUpdateStatusKozaStEnum;
    /**
     * UUID
     * @type {string}
     * @memberof ReqPostUpdateStatus
     */
    uuid: string;
    /**
     * 発生日付(YYYYMMDD)
     * @type {string}
     * @memberof ReqPostUpdateStatus
     */
    cre_day: string;
    /**
     * 発生時刻(HHMMSSmmm)
     * @type {string}
     * @memberof ReqPostUpdateStatus
     */
    cre_tme: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ReqPostUpdateStatusKozaStEnum {
    _00 = '00',
    _07 = '07',
    _47 = '47',
    _57 = '57'
}

/**
 * 
 * @export
 * @interface ReqSevenbankRegister
 */
export interface ReqSevenbankRegister {
    /**
     * 小田急お客様番号 画面で取得したユーザの代表OPカード番号
     * @type {string}
     * @memberof ReqSevenbankRegister
     */
    odakyu_customer_no: string;
    /**
     * OP残高 画面で取得したユーザの代表OPカードのOP残高
     * @type {number}
     * @memberof ReqSevenbankRegister
     */
    balance: number;
    /**
     * メールアドレス 画面で取得したユーザのメールアドレス
     * @type {string}
     * @memberof ReqSevenbankRegister
     */
    mail_address: string;
    /**
     * 送金額 セブン銀行チャージの送金額（手数料を含まない）
     * @type {number}
     * @memberof ReqSevenbankRegister
     */
    charge_amount: number;
    /**
     * 手数料 画面でチャージ額制限情報取得APIから取得したセブン銀行チャージの手数料
     * @type {number}
     * @memberof ReqSevenbankRegister
     */
    commission: number;
}
/**
 * 認証レスポンス
 * @export
 * @interface ResAuth
 */
export interface ResAuth {
    /**
     * データ処理時刻 yyyy-mm-ddThh:mm:ss.mmm+09:00形式 
     * @type {string}
     * @memberof ResAuth
     */
    process_datetime: string;
    /**
     * 返送ステータス - `000` 正常 - `007` ステータスエラー(紛失) - `047` ステータスエラー(退会) - `057` ステータスエラー(有効期限終了) - `077` 存在チェックエラー - `117` ポイント口座番号抽出エラー - `127` 店舗未登録エラー - `907` ECOpシステムエラー - `909` サービス時間外 
     * @type {string}
     * @memberof ResAuth
     */
    return_status: ResAuthReturnStatusEnum;
    /**
     * エラーメッセージ 返送ステータスがエラーの場合、エラーメッセージが格納される。 
     * @type {string}
     * @memberof ResAuth
     */
    err_message?: string | null;
    /**
     * 小田急お客様番号(PointID) 
     * @type {string}
     * @memberof ResAuth
     */
    odakyu_customer_no: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ResAuthReturnStatusEnum {
    _000 = '000',
    _007 = '007',
    _047 = '047',
    _057 = '057',
    _077 = '077',
    _117 = '117',
    _127 = '127',
    _907 = '907',
    _909 = '909'
}

/**
 * Auth0 Management API response
 * @export
 * @interface ResAuth0Admin
 */
export interface ResAuth0Admin {
    /**
     * 
     * @type {number}
     * @memberof ResAuth0Admin
     */
    status?: number;
    /**
     * 
     * @type {object}
     * @memberof ResAuth0Admin
     */
    result?: object;
}
/**
 * Debug Info response
 * @export
 * @interface ResDebugInfo
 */
export interface ResDebugInfo {
    /**
     * 
     * @type {string}
     * @memberof ResDebugInfo
     */
    system_time?: string;
    /**
     * 
     * @type {string}
     * @memberof ResDebugInfo
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof ResDebugInfo
     */
    commit_rev?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResDebugInfo
     */
    env?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ResDebugInfo
     */
    mgr_token?: string;
    /**
     * 
     * @type {object}
     * @memberof ResDebugInfo
     */
    pubkey_cache?: object;
    /**
     * 
     * @type {object}
     * @memberof ResDebugInfo
     */
    mgr_token_cache?: object;
}
/**
 * キャンペーン登録可否取得レスポンス
 * @export
 * @interface ResGetCampaignPossibility
 */
export interface ResGetCampaignPossibility {
    /**
     * キャンペーン登録可否
     * @type {boolean}
     * @memberof ResGetCampaignPossibility
     */
    possibility: boolean;
}
/**
 * 百貨店ポイント取得レスポンス
 * @export
 * @interface ResGetDepartmentStorePoint
 */
export interface ResGetDepartmentStorePoint {
    /**
     * 本年お買い上げ金額
     * @type {string}
     * @memberof ResGetDepartmentStorePoint
     */
    this_year_purchase_amount: string;
    /**
     * 本年トータルポイント率（%） ※OP専用カードの場合、OP会員情報参照要求レスポンスのOPポイント率を返却する ※OPクレジットカードの場合、OP会員情報参照要求レスポンスのOPCポイント率項目を返却する
     * @type {string}
     * @memberof ResGetDepartmentStorePoint
     */
    this_year_total_rate: string;
    /**
     * ポイント積立期間開始
     * @type {string}
     * @memberof ResGetDepartmentStorePoint
     */
    point_accumulate_term_start: string;
    /**
     * ポイント積立期間終了
     * @type {string}
     * @memberof ResGetDepartmentStorePoint
     */
    point_accumulate_term_end: string;
    /**
     * 百貨店連携ファイルの対象日付 ※該当カードのデータが存在しない場合は、百貨店連携ファイルの他レコードの対象日付と同値を返却。
     * @type {string}
     * @memberof ResGetDepartmentStorePoint
     */
    calculated_date: string;
}
/**
 * OPカード取得結果
 * @export
 * @interface ResGetOpCard
 */
export interface ResGetOpCard {
    /**
     * 小田急お客様番号
     * @type {string}
     * @memberof ResGetOpCard
     */
    odakyu_customer_no: string;
    /**
     * カード種別
     * @type {string}
     * @memberof ResGetOpCard
     */
    card_type: string;
    /**
     * 認証済みフラグ
     * @type {boolean}
     * @memberof ResGetOpCard
     */
    is_authenticated: boolean;
    /**
     * 代表カードフラグ
     * @type {boolean}
     * @memberof ResGetOpCard
     */
    is_main: boolean;
    /**
     * 有効期限(MMYY)
     * @type {string}
     * @memberof ResGetOpCard
     */
    expire?: string;
    /**
     * ステータス - `00` 通常 - `07` 紛失（廃止済） - `47` キャンセル（廃止済） - `57` 有効期限満了 
     * @type {string}
     * @memberof ResGetOpCard
     */
    status?: ResGetOpCardStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ResGetOpCardStatusEnum {
    _00 = '00',
    _07 = '07',
    _47 = '47',
    _57 = '57'
}

/**
 * 自動入力用属性取得レスポンス
 * @export
 * @interface ResGetUserInfoForAutoInput
 */
export interface ResGetUserInfoForAutoInput {
    /**
     * 氏
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    last_name: string;
    /**
     * 名
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    first_name: string;
    /**
     * 氏（ひらがな）
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    hira_last_name: string;
    /**
     * 名（ひらがな）
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    hira_first_name: string;
    /**
     * 性別区分 - `00` 男性 - `01` 女性 - `02` その他 - `03` 回答しない
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    gender_typ: ResGetUserInfoForAutoInputGenderTypEnum;
    /**
     * 生年月日（年）
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    birth_year: string;
    /**
     * 生年月日（月）
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    birth_month: string;
    /**
     * 生年月日（日）
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    birth_day: string;
    /**
     * 職業区分 - `00` その他 - `01` 会社員 - `02` 公務員、学校、医療機関 - `03` 自営業 - `05` 自由業 - `06` 学生 - `09` パート・アルバイト - `10` 年金生活 - `11` 不動産生活 - `12` 無職、配偶者
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    shokugyou_typ: ResGetUserInfoForAutoInputShokugyouTypEnum;
    /**
     * 電話番号1_1
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    phone1_1: string;
    /**
     * 電話番号1_2
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    phone1_2: string;
    /**
     * 電話番号1_3
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    phone1_3: string;
    /**
     * 郵便番号（前半3桁）
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    zipcode1: string;
    /**
     * 郵便番号（後半4桁）
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    zipcode2: string;
    /**
     * 住所（都道府県） - `01` 北海道 - `02` 青森県 - `03` 岩手県 - `04` 宮城県 - `05` 秋田県 - `06` 山形県 - `07` 福島県 - `08` 茨城県 - `09` 栃木県 - `10` 群馬県 - `11` 埼玉県 - `12` 千葉県 - `13` 東京都 - `14` 神奈川県 - `15` 新潟県 - `16` 富山県 - `17` 石川県 - `18` 福井県 - `19` 山梨県 - `20` 長野県 - `21` 岐阜県 - `22` 静岡県 - `23` 愛知県 - `24` 三重県 - `25` 滋賀県 - `26` 京都府 - `27` 大阪府 - `28` 兵庫県 - `29` 奈良県 - `30` 和歌山県 - `31` 鳥取県 - `32` 島根県 - `33` 岡山県 - `34` 広島県 - `35` 山口県 - `36` 徳島県 - `37` 香川県 - `38` 愛媛県 - `39` 高知県 - `40` 福岡県 - `41` 佐賀県 - `42` 長崎県 - `43` 熊本県 - `44` 大分県 - `45` 宮崎県 - `46` 鹿児島 - `47` 沖縄県 - `99` 不正な都道府県名が登録されている場合
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    pref: ResGetUserInfoForAutoInputPrefEnum;
    /**
     * 住所（市区町村・大字）
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    city: string;
    /**
     * 住所（丁目）
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    town: string;
    /**
     * 住所（番地・建物名・部屋番号）
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    building: string;
    /**
     * 小田急お客様番号
     * @type {string}
     * @memberof ResGetUserInfoForAutoInput
     */
    odakyu_customer_no: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ResGetUserInfoForAutoInputGenderTypEnum {
    _00 = '00',
    _01 = '01',
    _02 = '02',
    _03 = '03'
}
/**
    * @export
    * @enum {string}
    */
export enum ResGetUserInfoForAutoInputShokugyouTypEnum {
    _00 = '00',
    _01 = '01',
    _02 = '02',
    _03 = '03',
    _05 = '05',
    _06 = '06',
    _09 = '09',
    _10 = '10',
    _11 = '11',
    _12 = '12'
}
/**
    * @export
    * @enum {string}
    */
export enum ResGetUserInfoForAutoInputPrefEnum {
    _01 = '01',
    _02 = '02',
    _03 = '03',
    _04 = '04',
    _05 = '05',
    _06 = '06',
    _07 = '07',
    _08 = '08',
    _09 = '09',
    _10 = '10',
    _11 = '11',
    _12 = '12',
    _13 = '13',
    _14 = '14',
    _15 = '15',
    _16 = '16',
    _17 = '17',
    _18 = '18',
    _19 = '19',
    _20 = '20',
    _21 = '21',
    _22 = '22',
    _23 = '23',
    _24 = '24',
    _25 = '25',
    _26 = '26',
    _27 = '27',
    _28 = '28',
    _29 = '29',
    _30 = '30',
    _31 = '31',
    _32 = '32',
    _33 = '33',
    _34 = '34',
    _35 = '35',
    _36 = '36',
    _37 = '37',
    _38 = '38',
    _39 = '39',
    _40 = '40',
    _41 = '41',
    _42 = '42',
    _43 = '43',
    _44 = '44',
    _45 = '45',
    _46 = '46',
    _47 = '47',
    _99 = '99'
}

/**
 * モバイルPASMOチャージ処理結果受領APIレスポンスボディ
 * @export
 * @interface ResMobilepasmoResult
 */
export interface ResMobilepasmoResult {
    /**
     * 
     * @type {ResMobilepasmoResultHeader}
     * @memberof ResMobilepasmoResult
     */
    header: ResMobilepasmoResultHeader;
}
/**
 * ヘッダー
 * @export
 * @interface ResMobilepasmoResultHeader
 */
export interface ResMobilepasmoResultHeader {
    /**
     * 結果コード ONEにて処理した結果 - `0000` 正常終了 - `4000` 業務エラー
     * @type {string}
     * @memberof ResMobilepasmoResultHeader
     */
    result_code: ResMobilepasmoResultHeaderResultCodeEnum;
    /**
     * 結果詳細コード ONEにて処理した結果詳細 - `000000` 正常終了 - `000001` 署名検証失敗エラー - `000002` JWTパース時その他エラー - `000003` \"exp\"がJWTヘッダに存在しない - `000004` \"exp\"に現在時刻より未来日時が設定されていない - `000005` \"alg\"がJWTヘッダに存在しない - `000006` \"alg\"に\"RS256\"が設定されていない - `000007` \"typ\"がJWTヘッダに存在しない - `000008` \"typ\"に\"JOSE\"が設定されていない - `000009` \"kid\"がJWTヘッダに存在しない - `000010` \"kid\"に\"MP23\"が設定されていない - `000011` API IDが存在しない - `000012` API IDが\"APPCSF02\"でない - `000013` 業務種別が存在しない - `000014` 業務種別が\"00\"でない - `000015` IDiが存在しない - `000016` iDiが17byteでない - `000017` 結果コードが存在しない - `000018` 結果コードが20byte以下でない - `000019` 結果詳細コードが存在しない - `000020` 結果詳細コードが\"000000\"でない - `000021` 受付IDが存在しない - `000022` 受付IDが58byteでない - `000023` 一件明細IDが存在しない - `000024` 一件明細IDが5byte以下でない - `000025` 年月日が存在しない - `000026` 年月日が8byteでない - `000027` 時間が存在しない - `000028` 時間が6byteでない
     * @type {string}
     * @memberof ResMobilepasmoResultHeader
     */
    result_detail_code: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ResMobilepasmoResultHeaderResultCodeEnum {
    _0000 = '0000',
    _4000 = '4000'
}

/**
 * OPカード紐付/会員登録レスポンス
 * @export
 * @interface ResOneUserCreateFromOpapp
 */
export interface ResOneUserCreateFromOpapp {
    /**
     * 本APIによるOPシステム属性登録済フラグ
     * @type {boolean}
     * @memberof ResOneUserCreateFromOpapp
     */
    is_attr_registered_to_opsys: boolean;
}
/**
 * ONE新規登録レスポンス
 * @export
 * @interface ResOneUserPost
 */
export interface ResOneUserPost {
    /**
     * 本APIによるOPシステム属性登録済フラグ
     * @type {boolean}
     * @memberof ResOneUserPost
     */
    is_attr_registered_to_opsys: boolean;
}
/**
 * ポイント還元自動取消レスポンス
 * @export
 * @interface ResOpAutoCancelUsePoint
 */
export interface ResOpAutoCancelUsePoint {
    /**
     * 端末番号(TermID) 端末番号はクライアント側で任意に設定。 
     * @type {number}
     * @memberof ResOpAutoCancelUsePoint
     */
    device_no?: number;
    /**
     * 取引番号 ECOpに送る際もパディングは不要 
     * @type {number}
     * @memberof ResOpAutoCancelUsePoint
     */
    tx_id?: number;
    /**
     * 取引日(yyyymmdd)
     * @type {string}
     * @memberof ResOpAutoCancelUsePoint
     */
    business_date?: string;
    /**
     * 取引時刻(hhmmssmmm)
     * @type {string}
     * @memberof ResOpAutoCancelUsePoint
     */
    time?: string;
    /**
     * データ処理時刻 yyyy-mm-ddThh:mm:ss.mmm+09:00形式 
     * @type {string}
     * @memberof ResOpAutoCancelUsePoint
     */
    process_datetime?: string;
    /**
     * 返送ステータス - `000` 正常 - `007` ステータスエラー(紛失) - `047` ステータスエラー(退会) - `057` ステータスエラー(有効期限終了) - `077` 存在チェックエラー - `117` ポイント口座番号抽出エラー - `127` 店舗未登録エラー - `907` ECOpシステムエラー - `909` サービス時間外 
     * @type {string}
     * @memberof ResOpAutoCancelUsePoint
     */
    return_status?: ResOpAutoCancelUsePointReturnStatusEnum;
    /**
     * エラーメッセージ 返送ステータスがエラーの場合、エラーメッセージが格納される。 
     * @type {string}
     * @memberof ResOpAutoCancelUsePoint
     */
    err_message?: string | null;
    /**
     * 小田急お客様番号(PointID) 
     * @type {string}
     * @memberof ResOpAutoCancelUsePoint
     */
    odakyu_customer_no?: string;
    /**
     * 会員ステータス - `00` 通常 - `07` 紛失 - `47` 退会 - `57` 有効期限満了 
     * @type {string}
     * @memberof ResOpAutoCancelUsePoint
     */
    member_status?: ResOpAutoCancelUsePointMemberStatusEnum;
    /**
     * 本年ポイント 会員ステータスが通常以外の時、設定しない 本年ポイント数がマイナスの時、 \"-\"を付ける(\"-50000\") 
     * @type {number}
     * @memberof ResOpAutoCancelUsePoint
     */
    this_year_balance?: number | null;
    /**
     * 昨年ポイント 会員ステータスが通常以外の時、設定しない 
     * @type {number}
     * @memberof ResOpAutoCancelUsePoint
     */
    last_year_balance?: number | null;
    /**
     * 前回までの累計ポイント 会員ステータスが通常以外の時、設定しない 
     * @type {number}
     * @memberof ResOpAutoCancelUsePoint
     */
    previous_balance?: number | null;
    /**
     * ポイント積み立て期限 ポイント積立終了日の年月（yyyymm形式) 会員ステータスが通常以外の時、設定しない 白カードの照会取引の時、\"0\"固定 白カードの更新取引の場合、（初回利用日）処理日（年月）から算出した値を設定 
     * @type {string}
     * @memberof ResOpAutoCancelUsePoint
     */
    point_accum_term?: string | null;
    /**
     * ポイント失効期限 ポイント失効日の年月（yyyymm形式) 会員ステータスが通常以外の時、設定しない 白カードの照会取引の時、\"0\"固定 白カードの更新取引の場合、（初回利用日）処理日（年月）から算出した値を設定 
     * @type {string}
     * @memberof ResOpAutoCancelUsePoint
     */
    point_invalid_date?: string | null;
    /**
     * 入会日 入会日の年月（\"yyyymm\"形式) 会員ステータスが通常以外の時、設定しない 白カードの照会取引の時、\"0\"固定 白カードの更新取引の場合、（初回利用日）処理日（年月） 
     * @type {string}
     * @memberof ResOpAutoCancelUsePoint
     */
    admission_date?: string | null;
    /**
     * 今回還元(取消)したポイント数 （符号はトランザクションタイプで判定） 会員ステータスが通常以外の時、設定しない 当要素が存在しない時は0を意味する 
     * @type {number}
     * @memberof ResOpAutoCancelUsePoint
     */
    reward_point?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ResOpAutoCancelUsePointReturnStatusEnum {
    _000 = '000',
    _007 = '007',
    _047 = '047',
    _057 = '057',
    _077 = '077',
    _117 = '117',
    _127 = '127',
    _907 = '907',
    _909 = '909'
}
/**
    * @export
    * @enum {string}
    */
export enum ResOpAutoCancelUsePointMemberStatusEnum {
    _00 = '00',
    _07 = '07',
    _47 = '47',
    _57 = '57'
}

/**
 * Op残高
 * @export
 * @interface ResOpBalance
 */
export interface ResOpBalance {
    /**
     * データ処理時刻 yyyy-mm-ddThh:mm:ss.mmm+09:00形式 
     * @type {string}
     * @memberof ResOpBalance
     */
    process_datetime: string;
    /**
     * 返送ステータス - `000` 正常 - `007` ステータスエラー(紛失) - `047` ステータスエラー(退会) - `057` ステータスエラー(有効期限終了) - `077` 存在チェックエラー - `117` ポイント口座番号抽出エラー - `127` 店舗未登録エラー - `907` ECOpシステムエラー - `909` サービス時間外 
     * @type {string}
     * @memberof ResOpBalance
     */
    return_status: ResOpBalanceReturnStatusEnum;
    /**
     * エラーメッセージ 返送ステータスがエラーの場合、エラーメッセージが格納される。 
     * @type {string}
     * @memberof ResOpBalance
     */
    err_message?: string | null;
    /**
     * 会員ステータス - `00` 通常 - `07` 紛失 - `47` 退会 - `57` 有効期限満了 
     * @type {string}
     * @memberof ResOpBalance
     */
    member_status: ResOpBalanceMemberStatusEnum;
    /**
     * 本年ポイント 会員ステータスが通常以外の時、設定しない 本年ポイント数がマイナスの時、 \"-\"を付ける(\"-50000\") 
     * @type {number}
     * @memberof ResOpBalance
     */
    this_year_balance?: number | null;
    /**
     * 昨年ポイント 会員ステータスが通常以外の時、設定しない 
     * @type {number}
     * @memberof ResOpBalance
     */
    last_year_balance?: number | null;
    /**
     * 前回までの累計ポイント 会員ステータスが通常以外の時、設定しない 
     * @type {number}
     * @memberof ResOpBalance
     */
    previous_balance?: number | null;
    /**
     * ポイント積み立て期限 ポイント積立終了日の年月（yyyymm形式) 会員ステータスが通常以外の時、設定しない 白カードの照会取引の時、\"0\"固定 白カードの更新取引の場合、（初回利用日）処理日（年月）から算出した値を設定 
     * @type {string}
     * @memberof ResOpBalance
     */
    point_accum_term?: string | null;
    /**
     * ポイント失効期限 ポイント失効日の年月（yyyymm形式) 会員ステータスが通常以外の時、設定しない 白カードの照会取引の時、\"0\"固定 白カードの更新取引の場合、（初回利用日）処理日（年月）から算出した値を設定 
     * @type {string}
     * @memberof ResOpBalance
     */
    point_invalid_date?: string | null;
    /**
     * 入会日 入会日の年月（\"yyyymm\"形式) 会員ステータスが通常以外の時、設定しない 白カードの照会取引の時、\"0\"固定 白カードの更新取引の場合、（初回利用日）処理日（年月） 
     * @type {string}
     * @memberof ResOpBalance
     */
    admission_date?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ResOpBalanceReturnStatusEnum {
    _000 = '000',
    _007 = '007',
    _047 = '047',
    _057 = '057',
    _077 = '077',
    _117 = '117',
    _127 = '127',
    _907 = '907',
    _909 = '909'
}
/**
    * @export
    * @enum {string}
    */
export enum ResOpBalanceMemberStatusEnum {
    _00 = '00',
    _07 = '07',
    _47 = '47',
    _57 = '57'
}

/**
 * ポイント付与取消レスポンス
 * @export
 * @interface ResOpCancelGivePoint
 */
export interface ResOpCancelGivePoint {
    /**
     * 端末番号(TermID) 端末番号はクライアント側で任意に設定。 
     * @type {number}
     * @memberof ResOpCancelGivePoint
     */
    device_no?: number;
    /**
     * 取引番号 ECOpに送る際もパディングは不要 
     * @type {number}
     * @memberof ResOpCancelGivePoint
     */
    tx_id?: number;
    /**
     * 取引日(yyyymmdd)
     * @type {string}
     * @memberof ResOpCancelGivePoint
     */
    business_date?: string;
    /**
     * 取引時刻(hhmmssmmm)
     * @type {string}
     * @memberof ResOpCancelGivePoint
     */
    time?: string;
    /**
     * データ処理時刻 yyyy-mm-ddThh:mm:ss.mmm+09:00形式 
     * @type {string}
     * @memberof ResOpCancelGivePoint
     */
    process_datetime?: string;
    /**
     * ポイント対象金額
     * @type {number}
     * @memberof ResOpCancelGivePoint
     */
    point_target_amount?: number;
    /**
     * ポイント非対象金額
     * @type {number}
     * @memberof ResOpCancelGivePoint
     */
    point_exclude_amount?: number;
    /**
     * 小田急お客様番号(PointID) 
     * @type {string}
     * @memberof ResOpCancelGivePoint
     */
    odakyu_customer_no?: string;
    /**
     * 付与ポイント数
     * @type {number}
     * @memberof ResOpCancelGivePoint
     */
    award_point?: number;
    /**
     * 返送ステータス - `000` 正常 - `007` ステータスエラー(紛失) - `047` ステータスエラー(退会) - `057` ステータスエラー(有効期限終了) - `077` 存在チェックエラー - `117` ポイント口座番号抽出エラー - `127` 店舗未登録エラー - `907` ECOpシステムエラー - `909` サービス時間外 
     * @type {string}
     * @memberof ResOpCancelGivePoint
     */
    return_status?: ResOpCancelGivePointReturnStatusEnum;
    /**
     * エラーメッセージ 返送ステータスがエラーの場合、エラーメッセージが格納される。 
     * @type {string}
     * @memberof ResOpCancelGivePoint
     */
    err_message?: string | null;
    /**
     * 会員ステータス - `00` 通常 - `07` 紛失 - `47` 退会 - `57` 有効期限満了 
     * @type {string}
     * @memberof ResOpCancelGivePoint
     */
    member_status?: ResOpCancelGivePointMemberStatusEnum;
    /**
     * 本年ポイント 会員ステータスが通常以外の時、設定しない 本年ポイント数がマイナスの時、 \"-\"を付ける(\"-50000\") 
     * @type {number}
     * @memberof ResOpCancelGivePoint
     */
    this_year_balance?: number | null;
    /**
     * 昨年ポイント 会員ステータスが通常以外の時、設定しない 
     * @type {number}
     * @memberof ResOpCancelGivePoint
     */
    last_year_balance?: number | null;
    /**
     * 前回までの累計ポイント 会員ステータスが通常以外の時、設定しない 
     * @type {number}
     * @memberof ResOpCancelGivePoint
     */
    previous_balance?: number | null;
    /**
     * ポイント積み立て期限 ポイント積立終了日の年月（yyyymm形式) 会員ステータスが通常以外の時、設定しない 白カードの照会取引の時、\"0\"固定 白カードの更新取引の場合、（初回利用日）処理日（年月）から算出した値を設定 
     * @type {string}
     * @memberof ResOpCancelGivePoint
     */
    point_accum_term?: string | null;
    /**
     * ポイント失効期限 ポイント失効日の年月（yyyymm形式) 会員ステータスが通常以外の時、設定しない 白カードの照会取引の時、\"0\"固定 白カードの更新取引の場合、（初回利用日）処理日（年月）から算出した値を設定 
     * @type {string}
     * @memberof ResOpCancelGivePoint
     */
    point_invalid_date?: string | null;
    /**
     * 入会日 入会日の年月（\"yyyymm\"形式) 会員ステータスが通常以外の時、設定しない 白カードの照会取引の時、\"0\"固定 白カードの更新取引の場合、（初回利用日）処理日（年月） 
     * @type {string}
     * @memberof ResOpCancelGivePoint
     */
    admission_date?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ResOpCancelGivePointReturnStatusEnum {
    _000 = '000',
    _007 = '007',
    _047 = '047',
    _057 = '057',
    _077 = '077',
    _117 = '117',
    _127 = '127',
    _907 = '907',
    _909 = '909'
}
/**
    * @export
    * @enum {string}
    */
export enum ResOpCancelGivePointMemberStatusEnum {
    _00 = '00',
    _07 = '07',
    _47 = '47',
    _57 = '57'
}

/**
 * ポイント還元取消レスポンス
 * @export
 * @interface ResOpCancelUsePoint
 */
export interface ResOpCancelUsePoint {
    /**
     * 端末番号(TermID) 端末番号はクライアント側で任意に設定。 
     * @type {number}
     * @memberof ResOpCancelUsePoint
     */
    device_no?: number;
    /**
     * 取引番号 ECOpに送る際もパディングは不要 
     * @type {number}
     * @memberof ResOpCancelUsePoint
     */
    tx_id?: number;
    /**
     * 取引日(yyyymmdd)
     * @type {string}
     * @memberof ResOpCancelUsePoint
     */
    business_date?: string;
    /**
     * 取引時刻(hhmmssmmm)
     * @type {string}
     * @memberof ResOpCancelUsePoint
     */
    time?: string;
    /**
     * データ処理時刻 yyyy-mm-ddThh:mm:ss.mmm+09:00形式 
     * @type {string}
     * @memberof ResOpCancelUsePoint
     */
    process_datetime?: string;
    /**
     * 返送ステータス - `000` 正常 - `007` ステータスエラー(紛失) - `047` ステータスエラー(退会) - `057` ステータスエラー(有効期限終了) - `077` 存在チェックエラー - `117` ポイント口座番号抽出エラー - `127` 店舗未登録エラー - `907` ECOpシステムエラー - `909` サービス時間外 
     * @type {string}
     * @memberof ResOpCancelUsePoint
     */
    return_status?: ResOpCancelUsePointReturnStatusEnum;
    /**
     * エラーメッセージ 返送ステータスがエラーの場合、エラーメッセージが格納される。 
     * @type {string}
     * @memberof ResOpCancelUsePoint
     */
    err_message?: string | null;
    /**
     * 小田急お客様番号(PointID) 
     * @type {string}
     * @memberof ResOpCancelUsePoint
     */
    odakyu_customer_no?: string;
    /**
     * 会員ステータス - `00` 通常 - `07` 紛失 - `47` 退会 - `57` 有効期限満了 
     * @type {string}
     * @memberof ResOpCancelUsePoint
     */
    member_status?: ResOpCancelUsePointMemberStatusEnum;
    /**
     * 本年ポイント 会員ステータスが通常以外の時、設定しない 本年ポイント数がマイナスの時、 \"-\"を付ける(\"-50000\") 
     * @type {number}
     * @memberof ResOpCancelUsePoint
     */
    this_year_balance?: number | null;
    /**
     * 昨年ポイント 会員ステータスが通常以外の時、設定しない 
     * @type {number}
     * @memberof ResOpCancelUsePoint
     */
    last_year_balance?: number | null;
    /**
     * 前回までの累計ポイント 会員ステータスが通常以外の時、設定しない 
     * @type {number}
     * @memberof ResOpCancelUsePoint
     */
    previous_balance?: number | null;
    /**
     * ポイント積み立て期限 ポイント積立終了日の年月（yyyymm形式) 会員ステータスが通常以外の時、設定しない 白カードの照会取引の時、\"0\"固定 白カードの更新取引の場合、（初回利用日）処理日（年月）から算出した値を設定 
     * @type {string}
     * @memberof ResOpCancelUsePoint
     */
    point_accum_term?: string | null;
    /**
     * ポイント失効期限 ポイント失効日の年月（yyyymm形式) 会員ステータスが通常以外の時、設定しない 白カードの照会取引の時、\"0\"固定 白カードの更新取引の場合、（初回利用日）処理日（年月）から算出した値を設定 
     * @type {string}
     * @memberof ResOpCancelUsePoint
     */
    point_invalid_date?: string | null;
    /**
     * 入会日 入会日の年月（\"yyyymm\"形式) 会員ステータスが通常以外の時、設定しない 白カードの照会取引の時、\"0\"固定 白カードの更新取引の場合、（初回利用日）処理日（年月） 
     * @type {string}
     * @memberof ResOpCancelUsePoint
     */
    admission_date?: string | null;
    /**
     * 今回還元(取消)したポイント数 （符号はトランザクションタイプで判定） 会員ステータスが通常以外の時、設定しない 当要素が存在しない時は0を意味する 
     * @type {number}
     * @memberof ResOpCancelUsePoint
     */
    reward_point?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ResOpCancelUsePointReturnStatusEnum {
    _000 = '000',
    _007 = '007',
    _047 = '047',
    _057 = '057',
    _077 = '077',
    _117 = '117',
    _127 = '127',
    _907 = '907',
    _909 = '909'
}
/**
    * @export
    * @enum {string}
    */
export enum ResOpCancelUsePointMemberStatusEnum {
    _00 = '00',
    _07 = '07',
    _47 = '47',
    _57 = '57'
}

/**
 * OPカード(エアカード)発行結果
 * @export
 * @interface ResOpCardIssue
 */
export interface ResOpCardIssue {
    /**
     * 小田急お客様番号
     * @type {string}
     * @memberof ResOpCardIssue
     */
    odakyu_customer_no: string;
    /**
     * セキュリティコード
     * @type {string}
     * @memberof ResOpCardIssue
     */
    security_cd: string;
}
/**
 * OPカード紐付レスポンス
 * @export
 * @interface ResOpCardPost
 */
export interface ResOpCardPost {
    /**
     * 本APIによるOPシステム属性登録済フラグ
     * @type {boolean}
     * @memberof ResOpCardPost
     */
    is_attr_registered_to_opsys: boolean;
}
/**
 * ポイント付与レスポンス
 * @export
 * @interface ResOpGivePoint
 */
export interface ResOpGivePoint {
    /**
     * 端末番号(TermID) 端末番号はクライアント側で任意に設定。 
     * @type {number}
     * @memberof ResOpGivePoint
     */
    device_no?: number;
    /**
     * 取引番号 ECOpに送る際もパディングは不要 
     * @type {number}
     * @memberof ResOpGivePoint
     */
    tx_id?: number;
    /**
     * 取引日(yyyymmdd)
     * @type {string}
     * @memberof ResOpGivePoint
     */
    business_date?: string;
    /**
     * 取引時刻(hhmmssmmm)
     * @type {string}
     * @memberof ResOpGivePoint
     */
    time?: string;
    /**
     * データ処理時刻 yyyy-mm-ddThh:mm:ss.mmm+09:00形式 
     * @type {string}
     * @memberof ResOpGivePoint
     */
    process_datetime?: string;
    /**
     * ポイント対象金額
     * @type {number}
     * @memberof ResOpGivePoint
     */
    point_target_amount?: number;
    /**
     * ポイント非対称金額
     * @type {number}
     * @memberof ResOpGivePoint
     */
    point_exclude_amount?: number;
    /**
     * 小田急お客様番号(PointID) 
     * @type {string}
     * @memberof ResOpGivePoint
     */
    odakyu_customer_no?: string;
    /**
     * 付与ポイント数
     * @type {number}
     * @memberof ResOpGivePoint
     */
    award_point?: number;
    /**
     * 返送ステータス - `000` 正常 - `007` ステータスエラー(紛失) - `047` ステータスエラー(退会) - `057` ステータスエラー(有効期限終了) - `077` 存在チェックエラー - `117` ポイント口座番号抽出エラー - `127` 店舗未登録エラー - `907` ECOpシステムエラー - `909` サービス時間外 
     * @type {string}
     * @memberof ResOpGivePoint
     */
    return_status?: ResOpGivePointReturnStatusEnum;
    /**
     * エラーメッセージ 返送ステータスがエラーの場合、エラーメッセージが格納される。 
     * @type {string}
     * @memberof ResOpGivePoint
     */
    err_message?: string | null;
    /**
     * 会員ステータス - `00` 通常 - `07` 紛失 - `47` 退会 - `57` 有効期限満了 
     * @type {string}
     * @memberof ResOpGivePoint
     */
    member_status?: ResOpGivePointMemberStatusEnum;
    /**
     * 本年ポイント 会員ステータスが通常以外の時、設定しない 本年ポイント数がマイナスの時、 \"-\"を付ける(\"-50000\") 
     * @type {number}
     * @memberof ResOpGivePoint
     */
    this_year_balance?: number | null;
    /**
     * 昨年ポイント 会員ステータスが通常以外の時、設定しない 
     * @type {number}
     * @memberof ResOpGivePoint
     */
    last_year_balance?: number | null;
    /**
     * 前回までの累計ポイント 会員ステータスが通常以外の時、設定しない 
     * @type {number}
     * @memberof ResOpGivePoint
     */
    previous_balance?: number | null;
    /**
     * ポイント積み立て期限 ポイント積立終了日の年月（yyyymm形式) 会員ステータスが通常以外の時、設定しない 白カードの照会取引の時、\"0\"固定 白カードの更新取引の場合、（初回利用日）処理日（年月）から算出した値を設定 
     * @type {string}
     * @memberof ResOpGivePoint
     */
    point_accum_term?: string | null;
    /**
     * ポイント失効期限 ポイント失効日の年月（yyyymm形式) 会員ステータスが通常以外の時、設定しない 白カードの照会取引の時、\"0\"固定 白カードの更新取引の場合、（初回利用日）処理日（年月）から算出した値を設定 
     * @type {string}
     * @memberof ResOpGivePoint
     */
    point_invalid_date?: string | null;
    /**
     * 入会日 入会日の年月（\"yyyymm\"形式) 会員ステータスが通常以外の時、設定しない 白カードの照会取引の時、\"0\"固定 白カードの更新取引の場合、（初回利用日）処理日（年月） 
     * @type {string}
     * @memberof ResOpGivePoint
     */
    admission_date?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ResOpGivePointReturnStatusEnum {
    _000 = '000',
    _007 = '007',
    _047 = '047',
    _057 = '057',
    _077 = '077',
    _117 = '117',
    _127 = '127',
    _907 = '907',
    _909 = '909'
}
/**
    * @export
    * @enum {string}
    */
export enum ResOpGivePointMemberStatusEnum {
    _00 = '00',
    _07 = '07',
    _47 = '47',
    _57 = '57'
}

/**
 * Op履歴レスポンス
 * @export
 * @interface ResOpHistory
 */
export interface ResOpHistory {
    /**
     * 処理ステータス （GPSから返却される処理ステータス）    * `00` - 正常    * `01` - カード番号なし    * `02` - 履歴データなし    * `99` - システムエラー 
     * @type {string}
     * @memberof ResOpHistory
     */
    process_status: ResOpHistoryProcessStatusEnum;
    /**
     * 利用日(YYYYMMDD) ※GPSの利用日を示す 
     * @type {string}
     * @memberof ResOpHistory
     */
    transaction_date: string;
    /**
     * カード種別コード
     * @type {string}
     * @memberof ResOpHistory
     */
    card_type_cd: string;
    /**
     * 親子区分    * `0` - 本人    * `1` - 家族 
     * @type {string}
     * @memberof ResOpHistory
     */
    parentage_type: ResOpHistoryParentageTypeEnum;
    /**
     * 店舗名称
     * @type {string}
     * @memberof ResOpHistory
     */
    store_name: string;
    /**
     * 付与ポイント（符号）
     * @type {string}
     * @memberof ResOpHistory
     */
    granted_points_sign: ResOpHistoryGrantedPointsSignEnum;
    /**
     * 付与ポイント 
     * @type {number}
     * @memberof ResOpHistory
     */
    granted_points: number;
    /**
     * 利用ポイント（符号）
     * @type {string}
     * @memberof ResOpHistory
     */
    used_points_sign: ResOpHistoryUsedPointsSignEnum;
    /**
     * 利用ポイント 
     * @type {number}
     * @memberof ResOpHistory
     */
    used_points: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ResOpHistoryProcessStatusEnum {
    _00 = '00',
    _01 = '01',
    _02 = '02',
    _99 = '99'
}
/**
    * @export
    * @enum {string}
    */
export enum ResOpHistoryParentageTypeEnum {
    _0 = '0',
    _1 = '1'
}
/**
    * @export
    * @enum {string}
    */
export enum ResOpHistoryGrantedPointsSignEnum {
    Plus = '+',
    Minus = '-'
}
/**
    * @export
    * @enum {string}
    */
export enum ResOpHistoryUsedPointsSignEnum {
    Plus = '+',
    Minus = '-'
}

/**
 * ポイント還元レスポンス
 * @export
 * @interface ResOpUsePoint
 */
export interface ResOpUsePoint {
    /**
     * 端末番号(TermID) 端末番号はクライアント側で任意に設定。 
     * @type {number}
     * @memberof ResOpUsePoint
     */
    device_no?: number;
    /**
     * 取引番号 ECOpに送る際もパディングは不要 
     * @type {number}
     * @memberof ResOpUsePoint
     */
    tx_id?: number;
    /**
     * 取引日(yyyymmdd)
     * @type {string}
     * @memberof ResOpUsePoint
     */
    business_date?: string;
    /**
     * 取引時刻(hhmmssmmm)
     * @type {string}
     * @memberof ResOpUsePoint
     */
    time?: string;
    /**
     * データ処理時刻 yyyy-mm-ddThh:mm:ss.mmm+09:00形式 
     * @type {string}
     * @memberof ResOpUsePoint
     */
    process_datetime?: string;
    /**
     * 返送ステータス - `000` 正常 - `007` ステータスエラー(紛失) - `047` ステータスエラー(退会) - `057` ステータスエラー(有効期限終了) - `077` 存在チェックエラー - `117` ポイント口座番号抽出エラー - `127` 店舗未登録エラー - `907` ECOpシステムエラー - `909` サービス時間外 
     * @type {string}
     * @memberof ResOpUsePoint
     */
    return_status?: ResOpUsePointReturnStatusEnum;
    /**
     * エラーメッセージ 返送ステータスがエラーの場合、エラーメッセージが格納される。 
     * @type {string}
     * @memberof ResOpUsePoint
     */
    err_message?: string | null;
    /**
     * 小田急お客様番号(PointID) 
     * @type {string}
     * @memberof ResOpUsePoint
     */
    odakyu_customer_no?: string;
    /**
     * 会員ステータス - `00` 通常 - `07` 紛失 - `47` 退会 - `57` 有効期限満了 
     * @type {string}
     * @memberof ResOpUsePoint
     */
    member_status?: ResOpUsePointMemberStatusEnum;
    /**
     * 本年ポイント 会員ステータスが通常以外の時、設定しない 本年ポイント数がマイナスの時、 \"-\"を付ける(\"-50000\") 
     * @type {number}
     * @memberof ResOpUsePoint
     */
    this_year_balance?: number | null;
    /**
     * 昨年ポイント 会員ステータスが通常以外の時、設定しない 
     * @type {number}
     * @memberof ResOpUsePoint
     */
    last_year_balance?: number | null;
    /**
     * 前回までの累計ポイント 会員ステータスが通常以外の時、設定しない 
     * @type {number}
     * @memberof ResOpUsePoint
     */
    previous_balance?: number | null;
    /**
     * 還元ポイント数 （符号はトランザクションタイプで判定） 会員ステータスが通常以外の時、設定しない 当要素が存在しない時は0を意味する 
     * @type {number}
     * @memberof ResOpUsePoint
     */
    reward_point?: number;
    /**
     * ポイント積み立て期限 ポイント積立終了日の年月（yyyymm形式) 会員ステータスが通常以外の時、設定しない 白カードの照会取引の時、\"0\"固定 白カードの更新取引の場合、（初回利用日）処理日（年月）から算出した値を設定 
     * @type {string}
     * @memberof ResOpUsePoint
     */
    point_accum_term?: string | null;
    /**
     * ポイント失効期限 ポイント失効日の年月（yyyymm形式) 会員ステータスが通常以外の時、設定しない 白カードの照会取引の時、\"0\"固定 白カードの更新取引の場合、（初回利用日）処理日（年月）から算出した値を設定 
     * @type {string}
     * @memberof ResOpUsePoint
     */
    point_invalid_date?: string | null;
    /**
     * 入会日 入会日の年月（\"yyyymm\"形式) 会員ステータスが通常以外の時、設定しない 白カードの照会取引の時、\"0\"固定 白カードの更新取引の場合、（初回利用日）処理日（年月） 
     * @type {string}
     * @memberof ResOpUsePoint
     */
    admission_date?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ResOpUsePointReturnStatusEnum {
    _000 = '000',
    _007 = '007',
    _047 = '047',
    _057 = '057',
    _077 = '077',
    _117 = '117',
    _127 = '127',
    _907 = '907',
    _909 = '909'
}
/**
    * @export
    * @enum {string}
    */
export enum ResOpUsePointMemberStatusEnum {
    _00 = '00',
    _07 = '07',
    _47 = '47',
    _57 = '57'
}

/**
 * チャージ履歴レスポンスボディ
 * @export
 * @interface ResPasmoChargeHistory
 */
export interface ResPasmoChargeHistory {
    /**
     * 提携先コード 8013固定 出金に必要な番号
     * @type {string}
     * @memberof ResPasmoChargeHistory
     */
    partner_code: ResPasmoChargeHistoryPartnerCodeEnum;
    /**
     * ユーザの過去1年分のチャージ履歴の件数
     * @type {number}
     * @memberof ResPasmoChargeHistory
     */
    total_count: number;
    /**
     * ページサイズ 1ページあたりのチャージ履歴件数 50固定
     * @type {number}
     * @memberof ResPasmoChargeHistory
     */
    page_size: number;
    /**
     * ページ番号 リクエストのページ番号をそのまま返却する
     * @type {number}
     * @memberof ResPasmoChargeHistory
     */
    page_no: number;
    /**
     * ユーザのチャージ履歴の配列 1ページ分の配列であり、長さはビジネスルールにより決定される
     * @type {Array<ResPasmoChargeHistoryCharges>}
     * @memberof ResPasmoChargeHistory
     */
    charges: Array<ResPasmoChargeHistoryCharges>;
}

/**
    * @export
    * @enum {string}
    */
export enum ResPasmoChargeHistoryPartnerCodeEnum {
    _8013 = '8013'
}

/**
 * 
 * @export
 * @interface ResPasmoChargeHistoryCharges
 */
export interface ResPasmoChargeHistoryCharges {
    /**
     * チャージ金額（手数料を含まない）
     * @type {number}
     * @memberof ResPasmoChargeHistoryCharges
     */
    amount: number;
    /**
     * 種別（1:セブン銀行、2:モバイルPASMO）
     * @type {string}
     * @memberof ResPasmoChargeHistoryCharges
     */
    charge_typ: string;
    /**
     * ポイント交換日時
     * @type {string}
     * @memberof ResPasmoChargeHistoryCharges
     */
    trade_dt: string;
    /**
     * [セブン銀行] 確認番号
     * @type {string}
     * @memberof ResPasmoChargeHistoryCharges
     */
    confirmation_no: string | null;
    /**
     * [セブン銀行] ATM 利用日時
     * @type {string}
     * @memberof ResPasmoChargeHistoryCharges
     */
    atm_used_dt: string | null;
    /**
     * [セブン銀行] 受取有効期限日
     * @type {string}
     * @memberof ResPasmoChargeHistoryCharges
     */
    receiving_expiration_ymd: string | null;
    /**
     * [セブン銀行] 送金ステータス （10:本登録、20:支払中、30:送金完了、31:補正完了、40:期限切れ、50:取消）
     * @type {string}
     * @memberof ResPasmoChargeHistoryCharges
     */
    remittance_status: string | null;
    /**
     * [モバイルPASMO] チャージステータス （00:成功、10:失敗、20:成否不明、30:リカバリ済）
     * @type {string}
     * @memberof ResPasmoChargeHistoryCharges
     */
    charge_status: string | null;
    /**
     * [モバイルPASMO] リカバリ日時
     * @type {string}
     * @memberof ResPasmoChargeHistoryCharges
     */
    recovery_dt: string | null;
}
/**
 * チャージ制限情報取得レスポンスボディ
 * @export
 * @interface ResPasmoChargeLimit
 */
export interface ResPasmoChargeLimit {
    /**
     * 月間チャージ上限額 グローバル変数から値を返却する
     * @type {number}
     * @memberof ResPasmoChargeLimit
     */
    monthly_charge_limit: number;
    /**
     * チャージ申請済金額 セブン銀行チャージ、モバイルPASMOチャージの合算した金額で算出する
     * @type {number}
     * @memberof ResPasmoChargeLimit
     */
    monthly_charge_amount: number;
    /**
     * セブン銀行最低チャージ金額 グローバル変数から値を返却する
     * @type {number}
     * @memberof ResPasmoChargeLimit
     */
    seven_minimum_amount: number;
    /**
     * セブン銀行チャージ単位金額 グローバル変数から値を返却する
     * @type {number}
     * @memberof ResPasmoChargeLimit
     */
    seven_unit_amount: number;
    /**
     * セブン銀行チャージ手数料 グローバル変数から値を返却する
     * @type {number}
     * @memberof ResPasmoChargeLimit
     */
    seven_charge_fee: number;
    /**
     * モバイルPASMO最低チャージ金額 グローバル変数から値を返却する
     * @type {number}
     * @memberof ResPasmoChargeLimit
     */
    mobile_pasmo_minimum_amount: number;
    /**
     * モバイルPASMOチャージ単位金額 グローバル変数から値を返却する
     * @type {number}
     * @memberof ResPasmoChargeLimit
     */
    mobile_pasmo_unit_amount: number;
    /**
     * モバイルPASMOチャージ手数料 グローバル変数から値を返却する
     * @type {number}
     * @memberof ResPasmoChargeLimit
     */
    mobile_pasmo_charge_fee: number;
}
/**
 * OPギフト登録結果
 * @export
 * @interface ResPostOpGift
 */
export interface ResPostOpGift {
    /**
     * 合計ポイント数
     * @type {number}
     * @memberof ResPostOpGift
     */
    total_point_cnt: number;
    /**
     * 通常ポイント数
     * @type {number}
     * @memberof ResPostOpGift
     */
    normal_point_cnt: number;
    /**
     * 合計ボーナスポイント数
     * @type {number}
     * @memberof ResPostOpGift
     */
    total_bonus_point_cnt: number;
    /**
     * ボーナスポイント
     * @type {Array<ResPostOpGiftBonusList>}
     * @memberof ResPostOpGift
     */
    bonus_list: Array<ResPostOpGiftBonusList>;
    /**
     * イベント名
     * @type {string}
     * @memberof ResPostOpGift
     */
    event_name: string;
    /**
     * グループ名
     * @type {string}
     * @memberof ResPostOpGift
     */
    group_name: string | null;
}
/**
 * 
 * @export
 * @interface ResPostOpGiftBonusList
 */
export interface ResPostOpGiftBonusList {
    /**
     * ボーナスポイント名
     * @type {string}
     * @memberof ResPostOpGiftBonusList
     */
    name: string;
    /**
     * ボーナスポイント数
     * @type {number}
     * @memberof ResPostOpGiftBonusList
     */
    point_cnt: number;
}
/**
 * アクセストークン取得依頼要求に対する正常応答ボディ
 * @export
 * @interface ResSevenbankAccessToken
 */
export interface ResSevenbankAccessToken {
    /**
     * アクセストークン 送金企業が発行するアクセストークン
     * @type {string}
     * @memberof ResSevenbankAccessToken
     */
    access_token: string;
    /**
     * トークンタイプ `Bearer` 固定
     * @type {string}
     * @memberof ResSevenbankAccessToken
     */
    token_type: ResSevenbankAccessTokenTokenTypeEnum;
    /**
     * 有効期限 アクセストークンの有効期限を秒数で設定する
     * @type {string}
     * @memberof ResSevenbankAccessToken
     */
    expires_in: string;
    /**
     * スコープ アクセス権限のスコープを空白区切りで設定する
     * @type {string}
     * @memberof ResSevenbankAccessToken
     */
    scope: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ResSevenbankAccessTokenTokenTypeEnum {
    Bearer = 'Bearer'
}

/**
 * セブン銀行送金結果通知APIの正常応答ボディ
 * @export
 * @interface ResSevenbankNotification
 */
export interface ResSevenbankNotification {
    /**
     * 
     * @type {ResSevenbankNotificationCOMMONHEADER}
     * @memberof ResSevenbankNotification
     */
    common_header: ResSevenbankNotificationCOMMONHEADER;
    /**
     * 
     * @type {ResSevenbankNotificationBUSINESSDATA}
     * @memberof ResSevenbankNotification
     */
    business_data: ResSevenbankNotificationBUSINESSDATA;
}
/**
 * 業務データ
 * @export
 * @interface ResSevenbankNotificationBUSINESSDATA
 */
export interface ResSevenbankNotificationBUSINESSDATA {
    /**
     * 送金処理通番 資金移動子会社にて採番した通番 結果通知(要求)と同じ値を採用
     * @type {string}
     * @memberof ResSevenbankNotificationBUSINESSDATA
     */
    rem_proc_seqno: string;
}
/**
 * 共通ヘッダー
 * @export
 * @interface ResSevenbankNotificationCOMMONHEADER
 */
export interface ResSevenbankNotificationCOMMONHEADER {
    /**
     * 応答ステータス
     * @type {string}
     * @memberof ResSevenbankNotificationCOMMONHEADER
     */
    res_status: ResSevenbankNotificationCOMMONHEADERResStatusEnum;
    /**
     * 応答処理日時 形式: YYYYMMDDhhmmss 送金企業にて処理を行なった日時
     * @type {string}
     * @memberof ResSevenbankNotificationCOMMONHEADER
     */
    res_proc_date: string;
    /**
     * 提携先コード 7PSが管理する送金提携先を識別するためのコード 送金結果通知(要求)と同じ値を設定
     * @type {string}
     * @memberof ResSevenbankNotificationCOMMONHEADER
     */
    rmtr_cd: string;
    /**
     * エラー情報（配列）
     * @type {Array<ResSevenbankNotificationCOMMONHEADERERRORS>}
     * @memberof ResSevenbankNotificationCOMMONHEADER
     */
    errors: Array<ResSevenbankNotificationCOMMONHEADERERRORS>;
}

/**
    * @export
    * @enum {string}
    */
export enum ResSevenbankNotificationCOMMONHEADERResStatusEnum {
    _200 = '200',
    _400 = '400',
    _401 = '401',
    _408 = '408',
    _500 = '500',
    _503 = '503'
}

/**
 * 
 * @export
 * @interface ResSevenbankNotificationCOMMONHEADERERRORS
 */
export interface ResSevenbankNotificationCOMMONHEADERERRORS {
    /**
     * エラーコード エラー内容を表すコード 正常応答時は設定しない - 10: 受取人認証不正 - 20: 対象明細が存在しない - 21: 対象明細がロック中 - 22: 対象明細が既に支払済 - 29: その他の業務エラー - 30: JWS署名検証エラー - 31: JTI重複チェックエラー - 32: JTI有効期限チェックエラー - 33: クライアントチェックエラー(クライアント存在チェック、クライアント有効性チェック) - 34: アクセストークン有効期限チェックエラー - 35: アクセストークン有効性チェックエラー - 36: アクセストークンスコープチェックエラー - 37: アクセストークン存在チェックエラー - 90: 送金企業システムのURIへのアクセスが認証されなかった - 91: 送金企業システムのウェブサーバ側で決められた要求待ち時間を超えている - 92: 送金企業システムのサーバが高負荷の状態、またはメンテナンス中などでアクセスできない - 99: その他のシステムエラー
     * @type {string}
     * @memberof ResSevenbankNotificationCOMMONHEADERERRORS
     */
    error_code?: ResSevenbankNotificationCOMMONHEADERERRORSErrorCodeEnum;
    /**
     * エラーメッセージ エラー内容を表すメッセージ 正常応答時は設定しない
     * @type {string}
     * @memberof ResSevenbankNotificationCOMMONHEADERERRORS
     */
    error_message?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ResSevenbankNotificationCOMMONHEADERERRORSErrorCodeEnum {
    _10 = '10',
    _20 = '20',
    _21 = '21',
    _22 = '22',
    _29 = '29',
    _30 = '30',
    _31 = '31',
    _32 = '32',
    _33 = '33',
    _34 = '34',
    _35 = '35',
    _36 = '36',
    _37 = '37',
    _90 = '90',
    _91 = '91',
    _92 = '92',
    _99 = '99'
}

/**
 * 送金登録レスポンスボディ
 * @export
 * @interface ResSevenbankRegister
 */
export interface ResSevenbankRegister {
    /**
     * 確認番号 セブン銀行送金登録APIから返された 出金に必要な番号
     * @type {string}
     * @memberof ResSevenbankRegister
     */
    conf_no: string;
    /**
     * 提携先コード 8013固定 出金に必要な番号
     * @type {string}
     * @memberof ResSevenbankRegister
     */
    partner_code: ResSevenbankRegisterPartnerCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ResSevenbankRegisterPartnerCodeEnum {
    _8013 = '8013'
}

/**
 * アクセストークン取得依頼要求・失効依頼要求に対する異常応答ボディ
 * @export
 * @interface ResSevenbankTokenError
 */
export interface ResSevenbankTokenError {
    /**
     * エラーコード エラー内容を表すメッセージ 80. その他エラー 81. 取引時間終了 82. フォーマットエラー 83. 送金企業システムビジー 84. 送金企業システム内部処理エラー 30. JWS署名検証エラー 31. JTI重複チェックエラー 32. JWT有効期限チェックエラー 33. クライアントエラー（クライアント存在チェック、クライアント有効性チェック）
     * @type {string}
     * @memberof ResSevenbankTokenError
     */
    error_code: ResSevenbankTokenErrorErrorCodeEnum;
    /**
     * エラー詳細情報 エラーの詳細情報を表すメッセージ
     * @type {string}
     * @memberof ResSevenbankTokenError
     */
    error_detail?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ResSevenbankTokenErrorErrorCodeEnum {
    _80 = '80',
    _81 = '81',
    _82 = '82',
    _83 = '83',
    _84 = '84',
    _30 = '30',
    _31 = '31',
    _32 = '32',
    _33 = '33'
}


/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * [P0208]キャンペーン登録可否取得API リクエストユーザのキャンペーン登録可否を判定し、結果を返却する 登録可否判定はリクエストユーザに紐付くメインカードのBIN帯を用いて実施する
         * @param {string} campaignId キャンペーンID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignPossibility: async (campaignId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            if (campaignId === null || campaignId === undefined) {
                throw new RequiredError('campaignId','Required parameter campaignId was null or undefined when calling getCampaignPossibility.');
            }
            const localVarPath = `/campaign/{campaign_id}/possibility`
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    return {
        /**
         * [P0208]キャンペーン登録可否取得API リクエストユーザのキャンペーン登録可否を判定し、結果を返却する 登録可否判定はリクエストユーザに紐付くメインカードのBIN帯を用いて実施する
         * @param {string} campaignId キャンペーンID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignPossibility(campaignId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResGetCampaignPossibility>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).getCampaignPossibility(campaignId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * [P0208]キャンペーン登録可否取得API リクエストユーザのキャンペーン登録可否を判定し、結果を返却する 登録可否判定はリクエストユーザに紐付くメインカードのBIN帯を用いて実施する
         * @param {string} campaignId キャンペーンID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignPossibility(campaignId: string, options?: any): AxiosPromise<ResGetCampaignPossibility> {
            return CampaignApiFp(configuration).getCampaignPossibility(campaignId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * [P0208]キャンペーン登録可否取得API リクエストユーザのキャンペーン登録可否を判定し、結果を返却する 登録可否判定はリクエストユーザに紐付くメインカードのBIN帯を用いて実施する
     * @param {string} campaignId キャンペーンID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getCampaignPossibility(campaignId: string, options?: any) {
        return CampaignApiFp(this.configuration).getCampaignPossibility(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * MobilepasmoApi - axios parameter creator
 * @export
 */
export const MobilepasmoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * titanがサーバ、モバイルPASMOシステムがクライアントとなるときのポイントチャージ処理結果受領API
         * @summary モバイルPASMOポイントチャージ処理結果受領API
         * @param {string} contentType コンテンツタイプ 必須パラメータ &#x60;application/jose; charset&#x3D;UTF-8&#x60; 固定
         * @param {string} [body] HTTPボディ 必須パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyMobilepasmoCharge: async (contentType: string, body?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentType' is not null or undefined
            if (contentType === null || contentType === undefined) {
                throw new RequiredError('contentType','Required parameter contentType was null or undefined when calling notifyMobilepasmoCharge.');
            }
            const localVarPath = `/pasmo_charge/mobile/result`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/jose';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobilepasmoApi - functional programming interface
 * @export
 */
export const MobilepasmoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * titanがサーバ、モバイルPASMOシステムがクライアントとなるときのポイントチャージ処理結果受領API
         * @summary モバイルPASMOポイントチャージ処理結果受領API
         * @param {string} contentType コンテンツタイプ 必須パラメータ &#x60;application/jose; charset&#x3D;UTF-8&#x60; 固定
         * @param {string} [body] HTTPボディ 必須パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifyMobilepasmoCharge(contentType: string, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResMobilepasmoResult>> {
            const localVarAxiosArgs = await MobilepasmoApiAxiosParamCreator(configuration).notifyMobilepasmoCharge(contentType, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MobilepasmoApi - factory interface
 * @export
 */
export const MobilepasmoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * titanがサーバ、モバイルPASMOシステムがクライアントとなるときのポイントチャージ処理結果受領API
         * @summary モバイルPASMOポイントチャージ処理結果受領API
         * @param {string} contentType コンテンツタイプ 必須パラメータ &#x60;application/jose; charset&#x3D;UTF-8&#x60; 固定
         * @param {string} [body] HTTPボディ 必須パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyMobilepasmoCharge(contentType: string, body?: string, options?: any): AxiosPromise<ResMobilepasmoResult> {
            return MobilepasmoApiFp(configuration).notifyMobilepasmoCharge(contentType, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MobilepasmoApi - object-oriented interface
 * @export
 * @class MobilepasmoApi
 * @extends {BaseAPI}
 */
export class MobilepasmoApi extends BaseAPI {
    /**
     * titanがサーバ、モバイルPASMOシステムがクライアントとなるときのポイントチャージ処理結果受領API
     * @summary モバイルPASMOポイントチャージ処理結果受領API
     * @param {string} contentType コンテンツタイプ 必須パラメータ &#x60;application/jose; charset&#x3D;UTF-8&#x60; 固定
     * @param {string} [body] HTTPボディ 必須パラメータ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilepasmoApi
     */
    public notifyMobilepasmoCharge(contentType: string, body?: string, options?: any) {
        return MobilepasmoApiFp(this.configuration).notifyMobilepasmoCharge(contentType, body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * OneApi - axios parameter creator
 * @export
 */
export const OneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 機能: [P0114] ONE会員本登録・OPカード紐付API IFID: 010304 IF名: One本登録/OPカード紐付応答 認証: 個人トークンを利用する（エンドユーザが認証後にAuth0から取得できるアクセストークンを利用する） One会員本登録及びOPカード紐付を実施する。紐付対象がエアカードである場合のみ、カードの属性登録を行い、既に会員管理システムに登録されているカードの場合、属性更新は行わない。 紐付対象がP専である場合、ECOP属性認証を行い、認証NGである場合はエラーとする。 紐付対象のOPカードが他One会員に紐づいている場合はエラーとする。 
         * @param {ReqLinkWithOpCard} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneUserWithLinkOpCard: async (body?: ReqLinkWithOpCard, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/one/users/with_link_opcard`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user", "1st-party-point-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0138] ONE新規登録API 認証: 個人トークンを利用する。 ONE本会員登録画面からの会員登録を実施する。 OPカードが指定された場合、指定されたOPカードの検証、登録を実施する。 また、指定されたカードがOPシステム未登録のP専板カードの場合、セキュリティコードを用いてOPシステムに登録後、ONE紐付けを実施する。 OPカードが指定されない場合、エアカードを発行・属性登録した上で紐付を実施する。
         * @param {ReqOneUserPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oneUserCreate: async (body?: ReqOneUserPost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/one/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0180] OPアプリ向けONE新規登録API 認証: 個人トークンを利用する（エンドユーザが認証後にAuth0から取得できるアクセストークンを利用する） One会員本登録及びOPカード紐付を実施する。紐付対象がエアカードである場合のみ、カードの属性登録を行い、既に会員管理システムに登録されているカードの場合、属性更新は行わない。 紐付対象がP専である場合、まず、OPシステム登録済/未を確認する。 OPシステム登録済の場合、ECOP属性認証を実施し、認証NGである場合はエラーとする。 OPシステム未登録の場合、セキュリティコードを用いてOPシステム登録を実施する。 紐付対象のOPカードが他One会員に紐づいている場合はエラーとする。
         * @param {ReqOneUserCreateFromOpapp} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oneUserCreateFromOpapp: async (body?: ReqOneUserCreateFromOpapp, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/one/users_from_opapp`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0118] エアカード属性登録/紐付API IFID: 010704 IF名: エアカード紐付応答 認証: 個人トークンを利用する エアカード属性登録と紐付を実施する。 
         * @param {ReqAirCardLink} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOneUserLinkAir: async (body?: ReqAirCardLink, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/one/users/link_air`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user", "1st-party-point-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0136] ONE退会 認証: 個人トークンを利用する。 ONE退会を実施する。 同時に、リクエストで受け取ったOP番号について、OPシステム退会を実施する。
         * @param {ReqOneUserDelete} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUser: async (body?: ReqOneUserDelete, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/one/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0120] ONE属性変更API IFID: 030605 IF名: OPカード属性更新要求 認証: 個人トークンを使用する ONE属性とONEユーザに紐付られたOPカードの属性を更新する。
         * @param {ReqOneUserCreate} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate: async (body?: ReqOneUserCreate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/one/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OneApi - functional programming interface
 * @export
 */
export const OneApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 機能: [P0114] ONE会員本登録・OPカード紐付API IFID: 010304 IF名: One本登録/OPカード紐付応答 認証: 個人トークンを利用する（エンドユーザが認証後にAuth0から取得できるアクセストークンを利用する） One会員本登録及びOPカード紐付を実施する。紐付対象がエアカードである場合のみ、カードの属性登録を行い、既に会員管理システムに登録されているカードの場合、属性更新は行わない。 紐付対象がP専である場合、ECOP属性認証を行い、認証NGである場合はエラーとする。 紐付対象のOPカードが他One会員に紐づいている場合はエラーとする。 
         * @param {ReqLinkWithOpCard} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneUserWithLinkOpCard(body?: ReqLinkWithOpCard, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OneApiAxiosParamCreator(configuration).createOneUserWithLinkOpCard(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0138] ONE新規登録API 認証: 個人トークンを利用する。 ONE本会員登録画面からの会員登録を実施する。 OPカードが指定された場合、指定されたOPカードの検証、登録を実施する。 また、指定されたカードがOPシステム未登録のP専板カードの場合、セキュリティコードを用いてOPシステムに登録後、ONE紐付けを実施する。 OPカードが指定されない場合、エアカードを発行・属性登録した上で紐付を実施する。
         * @param {ReqOneUserPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oneUserCreate(body?: ReqOneUserPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResOneUserPost>> {
            const localVarAxiosArgs = await OneApiAxiosParamCreator(configuration).oneUserCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0180] OPアプリ向けONE新規登録API 認証: 個人トークンを利用する（エンドユーザが認証後にAuth0から取得できるアクセストークンを利用する） One会員本登録及びOPカード紐付を実施する。紐付対象がエアカードである場合のみ、カードの属性登録を行い、既に会員管理システムに登録されているカードの場合、属性更新は行わない。 紐付対象がP専である場合、まず、OPシステム登録済/未を確認する。 OPシステム登録済の場合、ECOP属性認証を実施し、認証NGである場合はエラーとする。 OPシステム未登録の場合、セキュリティコードを用いてOPシステム登録を実施する。 紐付対象のOPカードが他One会員に紐づいている場合はエラーとする。
         * @param {ReqOneUserCreateFromOpapp} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oneUserCreateFromOpapp(body?: ReqOneUserCreateFromOpapp, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResOneUserCreateFromOpapp>> {
            const localVarAxiosArgs = await OneApiAxiosParamCreator(configuration).oneUserCreateFromOpapp(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0118] エアカード属性登録/紐付API IFID: 010704 IF名: エアカード紐付応答 認証: 個人トークンを利用する エアカード属性登録と紐付を実施する。 
         * @param {ReqAirCardLink} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOneUserLinkAir(body?: ReqAirCardLink, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OneApiAxiosParamCreator(configuration).postOneUserLinkAir(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0136] ONE退会 認証: 個人トークンを利用する。 ONE退会を実施する。 同時に、リクエストで受け取ったOP番号について、OPシステム退会を実施する。
         * @param {ReqOneUserDelete} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUser(body?: ReqOneUserDelete, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OneApiAxiosParamCreator(configuration).removeUser(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0120] ONE属性変更API IFID: 030605 IF名: OPカード属性更新要求 認証: 個人トークンを使用する ONE属性とONEユーザに紐付られたOPカードの属性を更新する。
         * @param {ReqOneUserCreate} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdate(body?: ReqOneUserCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OneApiAxiosParamCreator(configuration).userUpdate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OneApi - factory interface
 * @export
 */
export const OneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 機能: [P0114] ONE会員本登録・OPカード紐付API IFID: 010304 IF名: One本登録/OPカード紐付応答 認証: 個人トークンを利用する（エンドユーザが認証後にAuth0から取得できるアクセストークンを利用する） One会員本登録及びOPカード紐付を実施する。紐付対象がエアカードである場合のみ、カードの属性登録を行い、既に会員管理システムに登録されているカードの場合、属性更新は行わない。 紐付対象がP専である場合、ECOP属性認証を行い、認証NGである場合はエラーとする。 紐付対象のOPカードが他One会員に紐づいている場合はエラーとする。 
         * @param {ReqLinkWithOpCard} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneUserWithLinkOpCard(body?: ReqLinkWithOpCard, options?: any): AxiosPromise<void> {
            return OneApiFp(configuration).createOneUserWithLinkOpCard(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0138] ONE新規登録API 認証: 個人トークンを利用する。 ONE本会員登録画面からの会員登録を実施する。 OPカードが指定された場合、指定されたOPカードの検証、登録を実施する。 また、指定されたカードがOPシステム未登録のP専板カードの場合、セキュリティコードを用いてOPシステムに登録後、ONE紐付けを実施する。 OPカードが指定されない場合、エアカードを発行・属性登録した上で紐付を実施する。
         * @param {ReqOneUserPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oneUserCreate(body?: ReqOneUserPost, options?: any): AxiosPromise<ResOneUserPost> {
            return OneApiFp(configuration).oneUserCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0180] OPアプリ向けONE新規登録API 認証: 個人トークンを利用する（エンドユーザが認証後にAuth0から取得できるアクセストークンを利用する） One会員本登録及びOPカード紐付を実施する。紐付対象がエアカードである場合のみ、カードの属性登録を行い、既に会員管理システムに登録されているカードの場合、属性更新は行わない。 紐付対象がP専である場合、まず、OPシステム登録済/未を確認する。 OPシステム登録済の場合、ECOP属性認証を実施し、認証NGである場合はエラーとする。 OPシステム未登録の場合、セキュリティコードを用いてOPシステム登録を実施する。 紐付対象のOPカードが他One会員に紐づいている場合はエラーとする。
         * @param {ReqOneUserCreateFromOpapp} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oneUserCreateFromOpapp(body?: ReqOneUserCreateFromOpapp, options?: any): AxiosPromise<ResOneUserCreateFromOpapp> {
            return OneApiFp(configuration).oneUserCreateFromOpapp(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0118] エアカード属性登録/紐付API IFID: 010704 IF名: エアカード紐付応答 認証: 個人トークンを利用する エアカード属性登録と紐付を実施する。 
         * @param {ReqAirCardLink} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOneUserLinkAir(body?: ReqAirCardLink, options?: any): AxiosPromise<void> {
            return OneApiFp(configuration).postOneUserLinkAir(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0136] ONE退会 認証: 個人トークンを利用する。 ONE退会を実施する。 同時に、リクエストで受け取ったOP番号について、OPシステム退会を実施する。
         * @param {ReqOneUserDelete} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUser(body?: ReqOneUserDelete, options?: any): AxiosPromise<void> {
            return OneApiFp(configuration).removeUser(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0120] ONE属性変更API IFID: 030605 IF名: OPカード属性更新要求 認証: 個人トークンを使用する ONE属性とONEユーザに紐付られたOPカードの属性を更新する。
         * @param {ReqOneUserCreate} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate(body?: ReqOneUserCreate, options?: any): AxiosPromise<void> {
            return OneApiFp(configuration).userUpdate(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OneApi - object-oriented interface
 * @export
 * @class OneApi
 * @extends {BaseAPI}
 */
export class OneApi extends BaseAPI {
    /**
     * 機能: [P0114] ONE会員本登録・OPカード紐付API IFID: 010304 IF名: One本登録/OPカード紐付応答 認証: 個人トークンを利用する（エンドユーザが認証後にAuth0から取得できるアクセストークンを利用する） One会員本登録及びOPカード紐付を実施する。紐付対象がエアカードである場合のみ、カードの属性登録を行い、既に会員管理システムに登録されているカードの場合、属性更新は行わない。 紐付対象がP専である場合、ECOP属性認証を行い、認証NGである場合はエラーとする。 紐付対象のOPカードが他One会員に紐づいている場合はエラーとする。 
     * @param {ReqLinkWithOpCard} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneApi
     */
    public createOneUserWithLinkOpCard(body?: ReqLinkWithOpCard, options?: any) {
        return OneApiFp(this.configuration).createOneUserWithLinkOpCard(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0138] ONE新規登録API 認証: 個人トークンを利用する。 ONE本会員登録画面からの会員登録を実施する。 OPカードが指定された場合、指定されたOPカードの検証、登録を実施する。 また、指定されたカードがOPシステム未登録のP専板カードの場合、セキュリティコードを用いてOPシステムに登録後、ONE紐付けを実施する。 OPカードが指定されない場合、エアカードを発行・属性登録した上で紐付を実施する。
     * @param {ReqOneUserPost} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneApi
     */
    public oneUserCreate(body?: ReqOneUserPost, options?: any) {
        return OneApiFp(this.configuration).oneUserCreate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0180] OPアプリ向けONE新規登録API 認証: 個人トークンを利用する（エンドユーザが認証後にAuth0から取得できるアクセストークンを利用する） One会員本登録及びOPカード紐付を実施する。紐付対象がエアカードである場合のみ、カードの属性登録を行い、既に会員管理システムに登録されているカードの場合、属性更新は行わない。 紐付対象がP専である場合、まず、OPシステム登録済/未を確認する。 OPシステム登録済の場合、ECOP属性認証を実施し、認証NGである場合はエラーとする。 OPシステム未登録の場合、セキュリティコードを用いてOPシステム登録を実施する。 紐付対象のOPカードが他One会員に紐づいている場合はエラーとする。
     * @param {ReqOneUserCreateFromOpapp} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneApi
     */
    public oneUserCreateFromOpapp(body?: ReqOneUserCreateFromOpapp, options?: any) {
        return OneApiFp(this.configuration).oneUserCreateFromOpapp(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0118] エアカード属性登録/紐付API IFID: 010704 IF名: エアカード紐付応答 認証: 個人トークンを利用する エアカード属性登録と紐付を実施する。 
     * @param {ReqAirCardLink} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneApi
     */
    public postOneUserLinkAir(body?: ReqAirCardLink, options?: any) {
        return OneApiFp(this.configuration).postOneUserLinkAir(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0136] ONE退会 認証: 個人トークンを利用する。 ONE退会を実施する。 同時に、リクエストで受け取ったOP番号について、OPシステム退会を実施する。
     * @param {ReqOneUserDelete} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneApi
     */
    public removeUser(body?: ReqOneUserDelete, options?: any) {
        return OneApiFp(this.configuration).removeUser(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0120] ONE属性変更API IFID: 030605 IF名: OPカード属性更新要求 認証: 個人トークンを使用する ONE属性とONEユーザに紐付られたOPカードの属性を更新する。
     * @param {ReqOneUserCreate} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneApi
     */
    public userUpdate(body?: ReqOneUserCreate, options?: any) {
        return OneApiFp(this.configuration).userUpdate(body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * OpApi - axios parameter creator
 * @export
 */
export const OpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 機能: [P0145] OP紐付解除API 認証: 個人トークンを利用する OPカード紐付解除を実施する。 P専板カードについては、OPシステム退会フラグがtrueの場合、同時にOPカードのOPシステム退会も実施する。
         * @param {ReqOpCardDelete} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOpCards: async (body?: ReqOpCardDelete, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/op/cards`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0214] 百貨店ポイント取得API リクエストの小田急お客様番号に紐付く百貨店ポイント情報を返却する
         * @param {string} odakyuCustomerNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentStorePoint: async (odakyuCustomerNo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'odakyuCustomerNo' is not null or undefined
            if (odakyuCustomerNo === null || odakyuCustomerNo === undefined) {
                throw new RequiredError('odakyuCustomerNo','Required parameter odakyuCustomerNo was null or undefined when calling getDepartmentStorePoint.');
            }
            const localVarPath = `/op/cards/department_store_point/{odakyu_customer_no}`
                .replace(`{${"odakyu_customer_no"}}`, encodeURIComponent(String(odakyuCustomerNo)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0103] ポイント残高照会API IFID: 030901(ONEサイト)       030902(パートナー)       030904(OPアプリ) IF名: ポイント残高応答 認証: 個人トークンを利用する 小田急お客様番号が引き渡された場合、指定されたOpカードの残高情報を返却する。 小田急お客様番号が引き渡されなかった場合、代表Opカードの残高情報を返却する。 
         * @param {number} deviceNo 端末番号 端末番号はクライアント側で任意に設定。 
         * @param {string} [odakyuCustomerNo] 認証: 個人トークンを利用する 小田急お客様番号 クライアント側でセットしなかった場合は代表カードの残高が返却される 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpBalance: async (deviceNo: number, odakyuCustomerNo?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceNo' is not null or undefined
            if (deviceNo === null || deviceNo === undefined) {
                throw new RequiredError('deviceNo','Required parameter deviceNo was null or undefined when calling getOpBalance.');
            }
            const localVarPath = `/op/cards/balance`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user", "1st-party-point-user", "3rd-party-point-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (deviceNo !== undefined) {
                localVarQueryParameter['device_no'] = deviceNo;
            }

            if (odakyuCustomerNo !== undefined) {
                localVarQueryParameter['odakyu_customer_no'] = odakyuCustomerNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0135] OP履歴照会API IFID: 031101 (ONEサイト)       031104 (OPアプリ) IF名: ポイント履歴応答 認証: 個人トークンを利用する 小田急お客様番号が引き渡された場合、指定されたOpカードの履歴情報を返却する。 小田急お客様番号が引き渡されなかった場合、代表Opカードの履歴情報を返却する。 
         * @param {string} from 検索開始日(YYYYMMDD)
         * @param {string} to 検索終了日(YYYYMMDD)
         * @param {number} pageNo ページ番号。1ページ辺り50件
         * @param {string} [odakyuCustomerNo] 小田急お客様番号 指定しない場合、代表Opカードの履歴を返却する 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpHistories: async (from: string, to: string, pageNo: number, odakyuCustomerNo?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling getOpHistories.');
            }
            // verify required parameter 'to' is not null or undefined
            if (to === null || to === undefined) {
                throw new RequiredError('to','Required parameter to was null or undefined when calling getOpHistories.');
            }
            // verify required parameter 'pageNo' is not null or undefined
            if (pageNo === null || pageNo === undefined) {
                throw new RequiredError('pageNo','Required parameter pageNo was null or undefined when calling getOpHistories.');
            }
            const localVarPath = `/op/cards/point_histories`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user", "1st-party-point-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (odakyuCustomerNo !== undefined) {
                localVarQueryParameter['odakyu_customer_no'] = odakyuCustomerNo;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (pageNo !== undefined) {
                localVarQueryParameter['page_no'] = pageNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [P0212]自動入力用属性取得API - ECOP認証項目に紐づくOPカードの属性情報をOPシステムから取得するAPI - OPシステムから属性取得する前に電話番号を不要とするECOP認証を実施する - 特別な固定値を電話番号としてOPシステムに連携することで、電話番号のみ認証をスキップする - 電話番号以外のECOP認証項目はユーザによるパラメータ入力
         * @param {string} odakyuCustomerNo 小田急お客さま番号
         * @param {string} hiraLastName 氏（ひらがな）(全角のみ)
         * @param {string} hiraFirstName 名（ひらがな）(全角のみ)
         * @param {string} birthYear 生年月日（年）（パラメータ整合性チェックをアプリで行う）
         * @param {string} birthMonth 生年月日（月）（パラメータ整合性チェックをアプリで行う）
         * @param {string} birthDay 生年月日（日）（パラメータ整合性チェックをアプリで行う）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfoForAutoInput: async (odakyuCustomerNo: string, hiraLastName: string, hiraFirstName: string, birthYear: string, birthMonth: string, birthDay: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'odakyuCustomerNo' is not null or undefined
            if (odakyuCustomerNo === null || odakyuCustomerNo === undefined) {
                throw new RequiredError('odakyuCustomerNo','Required parameter odakyuCustomerNo was null or undefined when calling getUserInfoForAutoInput.');
            }
            // verify required parameter 'hiraLastName' is not null or undefined
            if (hiraLastName === null || hiraLastName === undefined) {
                throw new RequiredError('hiraLastName','Required parameter hiraLastName was null or undefined when calling getUserInfoForAutoInput.');
            }
            // verify required parameter 'hiraFirstName' is not null or undefined
            if (hiraFirstName === null || hiraFirstName === undefined) {
                throw new RequiredError('hiraFirstName','Required parameter hiraFirstName was null or undefined when calling getUserInfoForAutoInput.');
            }
            // verify required parameter 'birthYear' is not null or undefined
            if (birthYear === null || birthYear === undefined) {
                throw new RequiredError('birthYear','Required parameter birthYear was null or undefined when calling getUserInfoForAutoInput.');
            }
            // verify required parameter 'birthMonth' is not null or undefined
            if (birthMonth === null || birthMonth === undefined) {
                throw new RequiredError('birthMonth','Required parameter birthMonth was null or undefined when calling getUserInfoForAutoInput.');
            }
            // verify required parameter 'birthDay' is not null or undefined
            if (birthDay === null || birthDay === undefined) {
                throw new RequiredError('birthDay','Required parameter birthDay was null or undefined when calling getUserInfoForAutoInput.');
            }
            const localVarPath = `/op/cards/info/{odakyu_customer_no}`
                .replace(`{${"odakyu_customer_no"}}`, encodeURIComponent(String(odakyuCustomerNo)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (hiraLastName !== undefined) {
                localVarQueryParameter['hira_last_name'] = hiraLastName;
            }

            if (hiraFirstName !== undefined) {
                localVarQueryParameter['hira_first_name'] = hiraFirstName;
            }

            if (birthYear !== undefined) {
                localVarQueryParameter['birth_year'] = birthYear;
            }

            if (birthMonth !== undefined) {
                localVarQueryParameter['birth_month'] = birthMonth;
            }

            if (birthDay !== undefined) {
                localVarQueryParameter['birth_day'] = birthDay;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0112] エアカード発行 IFID: 030204 IF名: エアカード発番応答 認証: M2Mトークンを利用する 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueOpCard: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/op/cards/issue`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["opapp-m2m"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0101] メインカード変更API 認証: 個人トークンを利用する（エンドユーザが認証後にAuth0から取得できるアクセストークンを利用する） 
         * @param {string} odakyuCustomerNo 小田急お客様番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opCardsIsMainPatch: async (odakyuCustomerNo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'odakyuCustomerNo' is not null or undefined
            if (odakyuCustomerNo === null || odakyuCustomerNo === undefined) {
                throw new RequiredError('odakyuCustomerNo','Required parameter odakyuCustomerNo was null or undefined when calling opCardsIsMainPatch.');
            }
            const localVarPath = `/op/cards/is_main`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (odakyuCustomerNo !== undefined) {
                localVarQueryParameter['odakyu_customer_no'] = odakyuCustomerNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0148] OPカード属性参照API IFID: 030701 (ONEサイト)       030704 (OPアプリ) IF名: 小田急お客様番号応答 説明: 指定したuidに紐づく小田急お客様番号を全て取得する。       ①アプリケーションがAuth0経由で認証をした時に、Auth0 Rulesが本APIを実行する。       Auth0 Rulesは認証したユーザのOPカード情報を本APIから取得し、IDトークンにその情報を付与する。       アプリケーションはそのIDトークン経由でOPカード情報を取得する。       ②ONE管理画面から、ユーザに紐づくOPカード情報を参照するために、本APIが実行される。 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opCardsUidGet: async (uid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling opCardsUidGet.');
            }
            const localVarPath = `/op/cards/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-support-center", "auth0-system-access"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 認証: 個人トークンを利用する OP系各外部サービスがサービス時間内か否かを返却する。 
         * @param {'ecop'} [service] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opInserviceGet: async (service?: 'ecop', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/op/inservice`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user", "1st-party-point-user", "3rd-party-point-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (service !== undefined) {
                localVarQueryParameter['service'] = service;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0193] JALマイル交換API 認証: 個人トークンを利用する メインカードがマイル交換可能券種である場合に、指定された小田急ポイントをJALマイルと交換する なお、ポイント減算とマイル加算はOPシステム側で実施する
         * @param {ReqPostJalMileExchange} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJalMiles: async (body?: ReqPostJalMileExchange, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/op/cards/jal_mile_exchange`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0102] OP属性認証API IFID: 032501 IF名: OPカード属性認証応答 認証: 個人トークンを利用する /ECOPW011 ECOP認証I/Fのレスポンスを返却する 
         * @param {ReqAuth} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpAuth: async (body?: ReqAuth, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/op/cards/auth`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0100] ポイント利用自動取消API IFID: 034002 IF名: ポイント利用自動取消応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント利用自動取消結果を返却する 
         * @param {ReqOpAutoCancelUsePoint} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpAutoCancelUsePoint: async (body?: ReqOpAutoCancelUsePoint, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/op/cards/auto_cancel/use_point`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0106] ポイント付与取消API IFID: 031502 IF名: ポイント付与取消応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント付与取消結果を返却する 
         * @param {ReqOpCancelGivePoint} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpCancelGivePoint: async (body?: ReqOpCancelGivePoint, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/op/cards/cancel/give_point`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0104] ポイント利用取消API IFID: 031902 IF名: ポイント利用取消応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント利用取消結果を返却する 
         * @param {ReqOpCancelUsePoint} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpCancelUsePoint: async (body?: ReqOpCancelUsePoint, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/op/cards/cancel/use_point`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0142] OP紐付API 認証: 個人トークンを利用する（エンドユーザが認証後にAuth0から取得できるアクセストークンを利用する） OPカード登録
         * @param {ReqOpCardPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpCards: async (body?: ReqOpCardPost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/op/cards`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0248] ポイント交換API 認証: 個人トークンを利用する リクエストのポイント交換商品IDに紐づく商品と小田急ポイントを交換するため、 ポイント還元をOPシステムにリクエストする ポイント還元後にユーザとポイント交換加盟店にポイント交換内容をメールで送信する
         * @param {ReqPostOpExchange} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpExchange: async (body?: ReqPostOpExchange, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/op/exchanges`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0155] OPギフト登録API 認証: 個人トークンを利用する 指定されたOPギフトコードに紐づくポイントを付与し、ギフトコードに紐付くOPギフトイベント、グループ、ポイントの情報を返却する 
         * @param {ReqPostOpGift} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpGift: async (body?: ReqPostOpGift, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/op/gift`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0107] ポイント付与API IFID: 031302 IF名: ポイント付与応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント付与結果を返却する 
         * @param {ReqOpGivePoint} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpGivePoint: async (body?: ReqOpGivePoint, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/op/cards/give_point`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0105] ポイント利用API IFID: 031702 IF名: ポイント利用応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント利用結果を返却する 
         * @param {ReqOpUsePoint} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpUsePoint: async (body?: ReqOpUsePoint, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/op/cards/use_point`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user", "1st-party-point-user", "3rd-party-point-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpApi - functional programming interface
 * @export
 */
export const OpApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 機能: [P0145] OP紐付解除API 認証: 個人トークンを利用する OPカード紐付解除を実施する。 P専板カードについては、OPシステム退会フラグがtrueの場合、同時にOPカードのOPシステム退会も実施する。
         * @param {ReqOpCardDelete} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOpCards(body?: ReqOpCardDelete, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).deleteOpCards(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0214] 百貨店ポイント取得API リクエストの小田急お客様番号に紐付く百貨店ポイント情報を返却する
         * @param {string} odakyuCustomerNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartmentStorePoint(odakyuCustomerNo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResGetDepartmentStorePoint>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).getDepartmentStorePoint(odakyuCustomerNo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0103] ポイント残高照会API IFID: 030901(ONEサイト)       030902(パートナー)       030904(OPアプリ) IF名: ポイント残高応答 認証: 個人トークンを利用する 小田急お客様番号が引き渡された場合、指定されたOpカードの残高情報を返却する。 小田急お客様番号が引き渡されなかった場合、代表Opカードの残高情報を返却する。 
         * @param {number} deviceNo 端末番号 端末番号はクライアント側で任意に設定。 
         * @param {string} [odakyuCustomerNo] 認証: 個人トークンを利用する 小田急お客様番号 クライアント側でセットしなかった場合は代表カードの残高が返却される 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpBalance(deviceNo: number, odakyuCustomerNo?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResOpBalance>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).getOpBalance(deviceNo, odakyuCustomerNo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0135] OP履歴照会API IFID: 031101 (ONEサイト)       031104 (OPアプリ) IF名: ポイント履歴応答 認証: 個人トークンを利用する 小田急お客様番号が引き渡された場合、指定されたOpカードの履歴情報を返却する。 小田急お客様番号が引き渡されなかった場合、代表Opカードの履歴情報を返却する。 
         * @param {string} from 検索開始日(YYYYMMDD)
         * @param {string} to 検索終了日(YYYYMMDD)
         * @param {number} pageNo ページ番号。1ページ辺り50件
         * @param {string} [odakyuCustomerNo] 小田急お客様番号 指定しない場合、代表Opカードの履歴を返却する 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpHistories(from: string, to: string, pageNo: number, odakyuCustomerNo?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResOpHistory>>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).getOpHistories(from, to, pageNo, odakyuCustomerNo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * [P0212]自動入力用属性取得API - ECOP認証項目に紐づくOPカードの属性情報をOPシステムから取得するAPI - OPシステムから属性取得する前に電話番号を不要とするECOP認証を実施する - 特別な固定値を電話番号としてOPシステムに連携することで、電話番号のみ認証をスキップする - 電話番号以外のECOP認証項目はユーザによるパラメータ入力
         * @param {string} odakyuCustomerNo 小田急お客さま番号
         * @param {string} hiraLastName 氏（ひらがな）(全角のみ)
         * @param {string} hiraFirstName 名（ひらがな）(全角のみ)
         * @param {string} birthYear 生年月日（年）（パラメータ整合性チェックをアプリで行う）
         * @param {string} birthMonth 生年月日（月）（パラメータ整合性チェックをアプリで行う）
         * @param {string} birthDay 生年月日（日）（パラメータ整合性チェックをアプリで行う）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfoForAutoInput(odakyuCustomerNo: string, hiraLastName: string, hiraFirstName: string, birthYear: string, birthMonth: string, birthDay: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResGetUserInfoForAutoInput>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).getUserInfoForAutoInput(odakyuCustomerNo, hiraLastName, hiraFirstName, birthYear, birthMonth, birthDay, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0112] エアカード発行 IFID: 030204 IF名: エアカード発番応答 認証: M2Mトークンを利用する 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueOpCard(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResOpCardIssue>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).issueOpCard(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0101] メインカード変更API 認証: 個人トークンを利用する（エンドユーザが認証後にAuth0から取得できるアクセストークンを利用する） 
         * @param {string} odakyuCustomerNo 小田急お客様番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async opCardsIsMainPatch(odakyuCustomerNo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).opCardsIsMainPatch(odakyuCustomerNo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0148] OPカード属性参照API IFID: 030701 (ONEサイト)       030704 (OPアプリ) IF名: 小田急お客様番号応答 説明: 指定したuidに紐づく小田急お客様番号を全て取得する。       ①アプリケーションがAuth0経由で認証をした時に、Auth0 Rulesが本APIを実行する。       Auth0 Rulesは認証したユーザのOPカード情報を本APIから取得し、IDトークンにその情報を付与する。       アプリケーションはそのIDトークン経由でOPカード情報を取得する。       ②ONE管理画面から、ユーザに紐づくOPカード情報を参照するために、本APIが実行される。 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async opCardsUidGet(uid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResGetOpCard>>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).opCardsUidGet(uid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 認証: 個人トークンを利用する OP系各外部サービスがサービス時間内か否かを返却する。 
         * @param {'ecop'} [service] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async opInserviceGet(service?: 'ecop', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).opInserviceGet(service, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0193] JALマイル交換API 認証: 個人トークンを利用する メインカードがマイル交換可能券種である場合に、指定された小田急ポイントをJALマイルと交換する なお、ポイント減算とマイル加算はOPシステム側で実施する
         * @param {ReqPostJalMileExchange} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJalMiles(body?: ReqPostJalMileExchange, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).postJalMiles(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0102] OP属性認証API IFID: 032501 IF名: OPカード属性認証応答 認証: 個人トークンを利用する /ECOPW011 ECOP認証I/Fのレスポンスを返却する 
         * @param {ReqAuth} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOpAuth(body?: ReqAuth, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResAuth>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).postOpAuth(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0100] ポイント利用自動取消API IFID: 034002 IF名: ポイント利用自動取消応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント利用自動取消結果を返却する 
         * @param {ReqOpAutoCancelUsePoint} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOpAutoCancelUsePoint(body?: ReqOpAutoCancelUsePoint, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResOpAutoCancelUsePoint>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).postOpAutoCancelUsePoint(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0106] ポイント付与取消API IFID: 031502 IF名: ポイント付与取消応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント付与取消結果を返却する 
         * @param {ReqOpCancelGivePoint} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOpCancelGivePoint(body?: ReqOpCancelGivePoint, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResOpCancelGivePoint>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).postOpCancelGivePoint(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0104] ポイント利用取消API IFID: 031902 IF名: ポイント利用取消応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント利用取消結果を返却する 
         * @param {ReqOpCancelUsePoint} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOpCancelUsePoint(body?: ReqOpCancelUsePoint, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResOpCancelUsePoint>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).postOpCancelUsePoint(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0142] OP紐付API 認証: 個人トークンを利用する（エンドユーザが認証後にAuth0から取得できるアクセストークンを利用する） OPカード登録
         * @param {ReqOpCardPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOpCards(body?: ReqOpCardPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResOpCardPost>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).postOpCards(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0248] ポイント交換API 認証: 個人トークンを利用する リクエストのポイント交換商品IDに紐づく商品と小田急ポイントを交換するため、 ポイント還元をOPシステムにリクエストする ポイント還元後にユーザとポイント交換加盟店にポイント交換内容をメールで送信する
         * @param {ReqPostOpExchange} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOpExchange(body?: ReqPostOpExchange, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).postOpExchange(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0155] OPギフト登録API 認証: 個人トークンを利用する 指定されたOPギフトコードに紐づくポイントを付与し、ギフトコードに紐付くOPギフトイベント、グループ、ポイントの情報を返却する 
         * @param {ReqPostOpGift} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOpGift(body?: ReqPostOpGift, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResPostOpGift>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).postOpGift(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0107] ポイント付与API IFID: 031302 IF名: ポイント付与応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント付与結果を返却する 
         * @param {ReqOpGivePoint} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOpGivePoint(body?: ReqOpGivePoint, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResOpGivePoint>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).postOpGivePoint(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0105] ポイント利用API IFID: 031702 IF名: ポイント利用応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント利用結果を返却する 
         * @param {ReqOpUsePoint} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOpUsePoint(body?: ReqOpUsePoint, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResOpUsePoint>> {
            const localVarAxiosArgs = await OpApiAxiosParamCreator(configuration).postOpUsePoint(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OpApi - factory interface
 * @export
 */
export const OpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 機能: [P0145] OP紐付解除API 認証: 個人トークンを利用する OPカード紐付解除を実施する。 P専板カードについては、OPシステム退会フラグがtrueの場合、同時にOPカードのOPシステム退会も実施する。
         * @param {ReqOpCardDelete} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOpCards(body?: ReqOpCardDelete, options?: any): AxiosPromise<void> {
            return OpApiFp(configuration).deleteOpCards(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0214] 百貨店ポイント取得API リクエストの小田急お客様番号に紐付く百貨店ポイント情報を返却する
         * @param {string} odakyuCustomerNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentStorePoint(odakyuCustomerNo: string, options?: any): AxiosPromise<ResGetDepartmentStorePoint> {
            return OpApiFp(configuration).getDepartmentStorePoint(odakyuCustomerNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0103] ポイント残高照会API IFID: 030901(ONEサイト)       030902(パートナー)       030904(OPアプリ) IF名: ポイント残高応答 認証: 個人トークンを利用する 小田急お客様番号が引き渡された場合、指定されたOpカードの残高情報を返却する。 小田急お客様番号が引き渡されなかった場合、代表Opカードの残高情報を返却する。 
         * @param {number} deviceNo 端末番号 端末番号はクライアント側で任意に設定。 
         * @param {string} [odakyuCustomerNo] 認証: 個人トークンを利用する 小田急お客様番号 クライアント側でセットしなかった場合は代表カードの残高が返却される 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpBalance(deviceNo: number, odakyuCustomerNo?: string, options?: any): AxiosPromise<ResOpBalance> {
            return OpApiFp(configuration).getOpBalance(deviceNo, odakyuCustomerNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0135] OP履歴照会API IFID: 031101 (ONEサイト)       031104 (OPアプリ) IF名: ポイント履歴応答 認証: 個人トークンを利用する 小田急お客様番号が引き渡された場合、指定されたOpカードの履歴情報を返却する。 小田急お客様番号が引き渡されなかった場合、代表Opカードの履歴情報を返却する。 
         * @param {string} from 検索開始日(YYYYMMDD)
         * @param {string} to 検索終了日(YYYYMMDD)
         * @param {number} pageNo ページ番号。1ページ辺り50件
         * @param {string} [odakyuCustomerNo] 小田急お客様番号 指定しない場合、代表Opカードの履歴を返却する 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpHistories(from: string, to: string, pageNo: number, odakyuCustomerNo?: string, options?: any): AxiosPromise<Array<ResOpHistory>> {
            return OpApiFp(configuration).getOpHistories(from, to, pageNo, odakyuCustomerNo, options).then((request) => request(axios, basePath));
        },
        /**
         * [P0212]自動入力用属性取得API - ECOP認証項目に紐づくOPカードの属性情報をOPシステムから取得するAPI - OPシステムから属性取得する前に電話番号を不要とするECOP認証を実施する - 特別な固定値を電話番号としてOPシステムに連携することで、電話番号のみ認証をスキップする - 電話番号以外のECOP認証項目はユーザによるパラメータ入力
         * @param {string} odakyuCustomerNo 小田急お客さま番号
         * @param {string} hiraLastName 氏（ひらがな）(全角のみ)
         * @param {string} hiraFirstName 名（ひらがな）(全角のみ)
         * @param {string} birthYear 生年月日（年）（パラメータ整合性チェックをアプリで行う）
         * @param {string} birthMonth 生年月日（月）（パラメータ整合性チェックをアプリで行う）
         * @param {string} birthDay 生年月日（日）（パラメータ整合性チェックをアプリで行う）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfoForAutoInput(odakyuCustomerNo: string, hiraLastName: string, hiraFirstName: string, birthYear: string, birthMonth: string, birthDay: string, options?: any): AxiosPromise<ResGetUserInfoForAutoInput> {
            return OpApiFp(configuration).getUserInfoForAutoInput(odakyuCustomerNo, hiraLastName, hiraFirstName, birthYear, birthMonth, birthDay, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0112] エアカード発行 IFID: 030204 IF名: エアカード発番応答 認証: M2Mトークンを利用する 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueOpCard(options?: any): AxiosPromise<ResOpCardIssue> {
            return OpApiFp(configuration).issueOpCard(options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0101] メインカード変更API 認証: 個人トークンを利用する（エンドユーザが認証後にAuth0から取得できるアクセストークンを利用する） 
         * @param {string} odakyuCustomerNo 小田急お客様番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opCardsIsMainPatch(odakyuCustomerNo: string, options?: any): AxiosPromise<void> {
            return OpApiFp(configuration).opCardsIsMainPatch(odakyuCustomerNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0148] OPカード属性参照API IFID: 030701 (ONEサイト)       030704 (OPアプリ) IF名: 小田急お客様番号応答 説明: 指定したuidに紐づく小田急お客様番号を全て取得する。       ①アプリケーションがAuth0経由で認証をした時に、Auth0 Rulesが本APIを実行する。       Auth0 Rulesは認証したユーザのOPカード情報を本APIから取得し、IDトークンにその情報を付与する。       アプリケーションはそのIDトークン経由でOPカード情報を取得する。       ②ONE管理画面から、ユーザに紐づくOPカード情報を参照するために、本APIが実行される。 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opCardsUidGet(uid: string, options?: any): AxiosPromise<Array<ResGetOpCard>> {
            return OpApiFp(configuration).opCardsUidGet(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 認証: 個人トークンを利用する OP系各外部サービスがサービス時間内か否かを返却する。 
         * @param {'ecop'} [service] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opInserviceGet(service?: 'ecop', options?: any): AxiosPromise<InlineResponse2003> {
            return OpApiFp(configuration).opInserviceGet(service, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0193] JALマイル交換API 認証: 個人トークンを利用する メインカードがマイル交換可能券種である場合に、指定された小田急ポイントをJALマイルと交換する なお、ポイント減算とマイル加算はOPシステム側で実施する
         * @param {ReqPostJalMileExchange} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJalMiles(body?: ReqPostJalMileExchange, options?: any): AxiosPromise<void> {
            return OpApiFp(configuration).postJalMiles(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0102] OP属性認証API IFID: 032501 IF名: OPカード属性認証応答 認証: 個人トークンを利用する /ECOPW011 ECOP認証I/Fのレスポンスを返却する 
         * @param {ReqAuth} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpAuth(body?: ReqAuth, options?: any): AxiosPromise<ResAuth> {
            return OpApiFp(configuration).postOpAuth(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0100] ポイント利用自動取消API IFID: 034002 IF名: ポイント利用自動取消応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント利用自動取消結果を返却する 
         * @param {ReqOpAutoCancelUsePoint} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpAutoCancelUsePoint(body?: ReqOpAutoCancelUsePoint, options?: any): AxiosPromise<ResOpAutoCancelUsePoint> {
            return OpApiFp(configuration).postOpAutoCancelUsePoint(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0106] ポイント付与取消API IFID: 031502 IF名: ポイント付与取消応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント付与取消結果を返却する 
         * @param {ReqOpCancelGivePoint} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpCancelGivePoint(body?: ReqOpCancelGivePoint, options?: any): AxiosPromise<ResOpCancelGivePoint> {
            return OpApiFp(configuration).postOpCancelGivePoint(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0104] ポイント利用取消API IFID: 031902 IF名: ポイント利用取消応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント利用取消結果を返却する 
         * @param {ReqOpCancelUsePoint} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpCancelUsePoint(body?: ReqOpCancelUsePoint, options?: any): AxiosPromise<ResOpCancelUsePoint> {
            return OpApiFp(configuration).postOpCancelUsePoint(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0142] OP紐付API 認証: 個人トークンを利用する（エンドユーザが認証後にAuth0から取得できるアクセストークンを利用する） OPカード登録
         * @param {ReqOpCardPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpCards(body?: ReqOpCardPost, options?: any): AxiosPromise<ResOpCardPost> {
            return OpApiFp(configuration).postOpCards(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0248] ポイント交換API 認証: 個人トークンを利用する リクエストのポイント交換商品IDに紐づく商品と小田急ポイントを交換するため、 ポイント還元をOPシステムにリクエストする ポイント還元後にユーザとポイント交換加盟店にポイント交換内容をメールで送信する
         * @param {ReqPostOpExchange} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpExchange(body?: ReqPostOpExchange, options?: any): AxiosPromise<void> {
            return OpApiFp(configuration).postOpExchange(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0155] OPギフト登録API 認証: 個人トークンを利用する 指定されたOPギフトコードに紐づくポイントを付与し、ギフトコードに紐付くOPギフトイベント、グループ、ポイントの情報を返却する 
         * @param {ReqPostOpGift} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpGift(body?: ReqPostOpGift, options?: any): AxiosPromise<ResPostOpGift> {
            return OpApiFp(configuration).postOpGift(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0107] ポイント付与API IFID: 031302 IF名: ポイント付与応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント付与結果を返却する 
         * @param {ReqOpGivePoint} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpGivePoint(body?: ReqOpGivePoint, options?: any): AxiosPromise<ResOpGivePoint> {
            return OpApiFp(configuration).postOpGivePoint(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0105] ポイント利用API IFID: 031702 IF名: ポイント利用応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント利用結果を返却する 
         * @param {ReqOpUsePoint} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpUsePoint(body?: ReqOpUsePoint, options?: any): AxiosPromise<ResOpUsePoint> {
            return OpApiFp(configuration).postOpUsePoint(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OpApi - object-oriented interface
 * @export
 * @class OpApi
 * @extends {BaseAPI}
 */
export class OpApi extends BaseAPI {
    /**
     * 機能: [P0145] OP紐付解除API 認証: 個人トークンを利用する OPカード紐付解除を実施する。 P専板カードについては、OPシステム退会フラグがtrueの場合、同時にOPカードのOPシステム退会も実施する。
     * @param {ReqOpCardDelete} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public deleteOpCards(body?: ReqOpCardDelete, options?: any) {
        return OpApiFp(this.configuration).deleteOpCards(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0214] 百貨店ポイント取得API リクエストの小田急お客様番号に紐付く百貨店ポイント情報を返却する
     * @param {string} odakyuCustomerNo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public getDepartmentStorePoint(odakyuCustomerNo: string, options?: any) {
        return OpApiFp(this.configuration).getDepartmentStorePoint(odakyuCustomerNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0103] ポイント残高照会API IFID: 030901(ONEサイト)       030902(パートナー)       030904(OPアプリ) IF名: ポイント残高応答 認証: 個人トークンを利用する 小田急お客様番号が引き渡された場合、指定されたOpカードの残高情報を返却する。 小田急お客様番号が引き渡されなかった場合、代表Opカードの残高情報を返却する。 
     * @param {number} deviceNo 端末番号 端末番号はクライアント側で任意に設定。 
     * @param {string} [odakyuCustomerNo] 認証: 個人トークンを利用する 小田急お客様番号 クライアント側でセットしなかった場合は代表カードの残高が返却される 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public getOpBalance(deviceNo: number, odakyuCustomerNo?: string, options?: any) {
        return OpApiFp(this.configuration).getOpBalance(deviceNo, odakyuCustomerNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0135] OP履歴照会API IFID: 031101 (ONEサイト)       031104 (OPアプリ) IF名: ポイント履歴応答 認証: 個人トークンを利用する 小田急お客様番号が引き渡された場合、指定されたOpカードの履歴情報を返却する。 小田急お客様番号が引き渡されなかった場合、代表Opカードの履歴情報を返却する。 
     * @param {string} from 検索開始日(YYYYMMDD)
     * @param {string} to 検索終了日(YYYYMMDD)
     * @param {number} pageNo ページ番号。1ページ辺り50件
     * @param {string} [odakyuCustomerNo] 小田急お客様番号 指定しない場合、代表Opカードの履歴を返却する 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public getOpHistories(from: string, to: string, pageNo: number, odakyuCustomerNo?: string, options?: any) {
        return OpApiFp(this.configuration).getOpHistories(from, to, pageNo, odakyuCustomerNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [P0212]自動入力用属性取得API - ECOP認証項目に紐づくOPカードの属性情報をOPシステムから取得するAPI - OPシステムから属性取得する前に電話番号を不要とするECOP認証を実施する - 特別な固定値を電話番号としてOPシステムに連携することで、電話番号のみ認証をスキップする - 電話番号以外のECOP認証項目はユーザによるパラメータ入力
     * @param {string} odakyuCustomerNo 小田急お客さま番号
     * @param {string} hiraLastName 氏（ひらがな）(全角のみ)
     * @param {string} hiraFirstName 名（ひらがな）(全角のみ)
     * @param {string} birthYear 生年月日（年）（パラメータ整合性チェックをアプリで行う）
     * @param {string} birthMonth 生年月日（月）（パラメータ整合性チェックをアプリで行う）
     * @param {string} birthDay 生年月日（日）（パラメータ整合性チェックをアプリで行う）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public getUserInfoForAutoInput(odakyuCustomerNo: string, hiraLastName: string, hiraFirstName: string, birthYear: string, birthMonth: string, birthDay: string, options?: any) {
        return OpApiFp(this.configuration).getUserInfoForAutoInput(odakyuCustomerNo, hiraLastName, hiraFirstName, birthYear, birthMonth, birthDay, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0112] エアカード発行 IFID: 030204 IF名: エアカード発番応答 認証: M2Mトークンを利用する 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public issueOpCard(options?: any) {
        return OpApiFp(this.configuration).issueOpCard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0101] メインカード変更API 認証: 個人トークンを利用する（エンドユーザが認証後にAuth0から取得できるアクセストークンを利用する） 
     * @param {string} odakyuCustomerNo 小田急お客様番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public opCardsIsMainPatch(odakyuCustomerNo: string, options?: any) {
        return OpApiFp(this.configuration).opCardsIsMainPatch(odakyuCustomerNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0148] OPカード属性参照API IFID: 030701 (ONEサイト)       030704 (OPアプリ) IF名: 小田急お客様番号応答 説明: 指定したuidに紐づく小田急お客様番号を全て取得する。       ①アプリケーションがAuth0経由で認証をした時に、Auth0 Rulesが本APIを実行する。       Auth0 Rulesは認証したユーザのOPカード情報を本APIから取得し、IDトークンにその情報を付与する。       アプリケーションはそのIDトークン経由でOPカード情報を取得する。       ②ONE管理画面から、ユーザに紐づくOPカード情報を参照するために、本APIが実行される。 
     * @param {string} uid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public opCardsUidGet(uid: string, options?: any) {
        return OpApiFp(this.configuration).opCardsUidGet(uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 認証: 個人トークンを利用する OP系各外部サービスがサービス時間内か否かを返却する。 
     * @param {'ecop'} [service] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public opInserviceGet(service?: 'ecop', options?: any) {
        return OpApiFp(this.configuration).opInserviceGet(service, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0193] JALマイル交換API 認証: 個人トークンを利用する メインカードがマイル交換可能券種である場合に、指定された小田急ポイントをJALマイルと交換する なお、ポイント減算とマイル加算はOPシステム側で実施する
     * @param {ReqPostJalMileExchange} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public postJalMiles(body?: ReqPostJalMileExchange, options?: any) {
        return OpApiFp(this.configuration).postJalMiles(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0102] OP属性認証API IFID: 032501 IF名: OPカード属性認証応答 認証: 個人トークンを利用する /ECOPW011 ECOP認証I/Fのレスポンスを返却する 
     * @param {ReqAuth} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public postOpAuth(body?: ReqAuth, options?: any) {
        return OpApiFp(this.configuration).postOpAuth(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0100] ポイント利用自動取消API IFID: 034002 IF名: ポイント利用自動取消応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント利用自動取消結果を返却する 
     * @param {ReqOpAutoCancelUsePoint} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public postOpAutoCancelUsePoint(body?: ReqOpAutoCancelUsePoint, options?: any) {
        return OpApiFp(this.configuration).postOpAutoCancelUsePoint(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0106] ポイント付与取消API IFID: 031502 IF名: ポイント付与取消応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント付与取消結果を返却する 
     * @param {ReqOpCancelGivePoint} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public postOpCancelGivePoint(body?: ReqOpCancelGivePoint, options?: any) {
        return OpApiFp(this.configuration).postOpCancelGivePoint(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0104] ポイント利用取消API IFID: 031902 IF名: ポイント利用取消応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント利用取消結果を返却する 
     * @param {ReqOpCancelUsePoint} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public postOpCancelUsePoint(body?: ReqOpCancelUsePoint, options?: any) {
        return OpApiFp(this.configuration).postOpCancelUsePoint(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0142] OP紐付API 認証: 個人トークンを利用する（エンドユーザが認証後にAuth0から取得できるアクセストークンを利用する） OPカード登録
     * @param {ReqOpCardPost} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public postOpCards(body?: ReqOpCardPost, options?: any) {
        return OpApiFp(this.configuration).postOpCards(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0248] ポイント交換API 認証: 個人トークンを利用する リクエストのポイント交換商品IDに紐づく商品と小田急ポイントを交換するため、 ポイント還元をOPシステムにリクエストする ポイント還元後にユーザとポイント交換加盟店にポイント交換内容をメールで送信する
     * @param {ReqPostOpExchange} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public postOpExchange(body?: ReqPostOpExchange, options?: any) {
        return OpApiFp(this.configuration).postOpExchange(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0155] OPギフト登録API 認証: 個人トークンを利用する 指定されたOPギフトコードに紐づくポイントを付与し、ギフトコードに紐付くOPギフトイベント、グループ、ポイントの情報を返却する 
     * @param {ReqPostOpGift} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public postOpGift(body?: ReqPostOpGift, options?: any) {
        return OpApiFp(this.configuration).postOpGift(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0107] ポイント付与API IFID: 031302 IF名: ポイント付与応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント付与結果を返却する 
     * @param {ReqOpGivePoint} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public postOpGivePoint(body?: ReqOpGivePoint, options?: any) {
        return OpApiFp(this.configuration).postOpGivePoint(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0105] ポイント利用API IFID: 031702 IF名: ポイント利用応答 認証: 個人トークンを利用する /ECOPW021 指定されたOPカードに対するポイント利用結果を返却する 
     * @param {ReqOpUsePoint} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpApi
     */
    public postOpUsePoint(body?: ReqOpUsePoint, options?: any) {
        return OpApiFp(this.configuration).postOpUsePoint(body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * OpsysApi - axios parameter creator
 * @export
 */
export const OpsysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * [P0153] OPカード切替API IFID: 034111 IF名: OPカード切替応答 認証: M2Mトークンを利用する リクエストの旧カード番号が紐付いているONEユーザに新カード番号を紐付ける 
         * @param {ReqPostCardSwitch} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCardSwitch: async (body?: ReqPostCardSwitch, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/opsys/card/switch`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["opsys-m2m"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [P0151] ONE会員情報参照API IFID: 033411 IF名: ONE会員情報参照応答 認証: M2Mトークンを利用する リクエストの小田急お客様番号がONEユーザに紐付いているかどうかを返す 
         * @param {InlineObject1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postGetLinkedFlg: async (body?: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/opsys/card/get_linked_flg`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["opsys-m2m"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [P0152] OPカードステータス変更API IFID: 033911 IF名: OPカードステータス変更応答 認証: M2Mトークンを利用する 会員管理システムからのOPカードステータス変更情報に基づいてOPカードのステータスを変更する 
         * @param {ReqPostUpdateStatus} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdateStatus: async (body?: ReqPostUpdateStatus, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/opsys/card/update_status`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["opsys-m2m"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpsysApi - functional programming interface
 * @export
 */
export const OpsysApiFp = function(configuration?: Configuration) {
    return {
        /**
         * [P0153] OPカード切替API IFID: 034111 IF名: OPカード切替応答 認証: M2Mトークンを利用する リクエストの旧カード番号が紐付いているONEユーザに新カード番号を紐付ける 
         * @param {ReqPostCardSwitch} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCardSwitch(body?: ReqPostCardSwitch, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
            const localVarAxiosArgs = await OpsysApiAxiosParamCreator(configuration).postCardSwitch(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * [P0151] ONE会員情報参照API IFID: 033411 IF名: ONE会員情報参照応答 認証: M2Mトークンを利用する リクエストの小田急お客様番号がONEユーザに紐付いているかどうかを返す 
         * @param {InlineObject1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postGetLinkedFlg(body?: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await OpsysApiAxiosParamCreator(configuration).postGetLinkedFlg(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * [P0152] OPカードステータス変更API IFID: 033911 IF名: OPカードステータス変更応答 認証: M2Mトークンを利用する 会員管理システムからのOPカードステータス変更情報に基づいてOPカードのステータスを変更する 
         * @param {ReqPostUpdateStatus} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUpdateStatus(body?: ReqPostUpdateStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await OpsysApiAxiosParamCreator(configuration).postUpdateStatus(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OpsysApi - factory interface
 * @export
 */
export const OpsysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * [P0153] OPカード切替API IFID: 034111 IF名: OPカード切替応答 認証: M2Mトークンを利用する リクエストの旧カード番号が紐付いているONEユーザに新カード番号を紐付ける 
         * @param {ReqPostCardSwitch} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCardSwitch(body?: ReqPostCardSwitch, options?: any): AxiosPromise<InlineResponse2006> {
            return OpsysApiFp(configuration).postCardSwitch(body, options).then((request) => request(axios, basePath));
        },
        /**
         * [P0151] ONE会員情報参照API IFID: 033411 IF名: ONE会員情報参照応答 認証: M2Mトークンを利用する リクエストの小田急お客様番号がONEユーザに紐付いているかどうかを返す 
         * @param {InlineObject1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postGetLinkedFlg(body?: InlineObject1, options?: any): AxiosPromise<InlineResponse2004> {
            return OpsysApiFp(configuration).postGetLinkedFlg(body, options).then((request) => request(axios, basePath));
        },
        /**
         * [P0152] OPカードステータス変更API IFID: 033911 IF名: OPカードステータス変更応答 認証: M2Mトークンを利用する 会員管理システムからのOPカードステータス変更情報に基づいてOPカードのステータスを変更する 
         * @param {ReqPostUpdateStatus} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdateStatus(body?: ReqPostUpdateStatus, options?: any): AxiosPromise<InlineResponse2005> {
            return OpsysApiFp(configuration).postUpdateStatus(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OpsysApi - object-oriented interface
 * @export
 * @class OpsysApi
 * @extends {BaseAPI}
 */
export class OpsysApi extends BaseAPI {
    /**
     * [P0153] OPカード切替API IFID: 034111 IF名: OPカード切替応答 認証: M2Mトークンを利用する リクエストの旧カード番号が紐付いているONEユーザに新カード番号を紐付ける 
     * @param {ReqPostCardSwitch} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpsysApi
     */
    public postCardSwitch(body?: ReqPostCardSwitch, options?: any) {
        return OpsysApiFp(this.configuration).postCardSwitch(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [P0151] ONE会員情報参照API IFID: 033411 IF名: ONE会員情報参照応答 認証: M2Mトークンを利用する リクエストの小田急お客様番号がONEユーザに紐付いているかどうかを返す 
     * @param {InlineObject1} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpsysApi
     */
    public postGetLinkedFlg(body?: InlineObject1, options?: any) {
        return OpsysApiFp(this.configuration).postGetLinkedFlg(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [P0152] OPカードステータス変更API IFID: 033911 IF名: OPカードステータス変更応答 認証: M2Mトークンを利用する 会員管理システムからのOPカードステータス変更情報に基づいてOPカードのステータスを変更する 
     * @param {ReqPostUpdateStatus} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpsysApi
     */
    public postUpdateStatus(body?: ReqPostUpdateStatus, options?: any) {
        return OpsysApiFp(this.configuration).postUpdateStatus(body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * PasmoChargeApi - axios parameter creator
 * @export
 */
export const PasmoChargeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * [P0183] PASMOチャージ履歴参照API PASMOへのチャージ履歴を取得する 認証: 個人トークンを利用する
         * @param {number} pageNo ページ番号 正の整数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPasmoChargeHistory: async (pageNo: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNo' is not null or undefined
            if (pageNo === null || pageNo === undefined) {
                throw new RequiredError('pageNo','Required parameter pageNo was null or undefined when calling getPasmoChargeHistory.');
            }
            const localVarPath = `/pasmo_charge/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (pageNo !== undefined) {
                localVarQueryParameter['page_no'] = pageNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [P0188] チャージ制限情報取得API 処理時点のチャージ可能残高を返却する 認証: 個人トークンを利用する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPasmoChargeLimit: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/pasmo_charge/limit`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [P0216] モバイルPASMOポイントポイントチャージAPI モバイルPASMOにポイントチャージを実施する 認証: 個人トークンを利用する
         * @param {ReqMobilepasmoCharge} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMobilepasmoCharge: async (body?: ReqMobilepasmoCharge, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/pasmo_charge/mobile/charge`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [P0181] セブン銀行送金登録API PASMOにチャージ機能（セブン銀行ATM受け取り）の送金登録を行う 認証: 個人トークンを利用する
         * @param {ReqSevenbankRegister} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSevenbankRegister: async (body?: ReqSevenbankRegister, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/pasmo_charge/sevenbank/register`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasmoChargeApi - functional programming interface
 * @export
 */
export const PasmoChargeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * [P0183] PASMOチャージ履歴参照API PASMOへのチャージ履歴を取得する 認証: 個人トークンを利用する
         * @param {number} pageNo ページ番号 正の整数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPasmoChargeHistory(pageNo: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResPasmoChargeHistory>> {
            const localVarAxiosArgs = await PasmoChargeApiAxiosParamCreator(configuration).getPasmoChargeHistory(pageNo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * [P0188] チャージ制限情報取得API 処理時点のチャージ可能残高を返却する 認証: 個人トークンを利用する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPasmoChargeLimit(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResPasmoChargeLimit>> {
            const localVarAxiosArgs = await PasmoChargeApiAxiosParamCreator(configuration).getPasmoChargeLimit(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * [P0216] モバイルPASMOポイントポイントチャージAPI モバイルPASMOにポイントチャージを実施する 認証: 個人トークンを利用する
         * @param {ReqMobilepasmoCharge} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMobilepasmoCharge(body?: ReqMobilepasmoCharge, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PasmoChargeApiAxiosParamCreator(configuration).postMobilepasmoCharge(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * [P0181] セブン銀行送金登録API PASMOにチャージ機能（セブン銀行ATM受け取り）の送金登録を行う 認証: 個人トークンを利用する
         * @param {ReqSevenbankRegister} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSevenbankRegister(body?: ReqSevenbankRegister, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResSevenbankRegister>> {
            const localVarAxiosArgs = await PasmoChargeApiAxiosParamCreator(configuration).postSevenbankRegister(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PasmoChargeApi - factory interface
 * @export
 */
export const PasmoChargeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * [P0183] PASMOチャージ履歴参照API PASMOへのチャージ履歴を取得する 認証: 個人トークンを利用する
         * @param {number} pageNo ページ番号 正の整数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPasmoChargeHistory(pageNo: number, options?: any): AxiosPromise<ResPasmoChargeHistory> {
            return PasmoChargeApiFp(configuration).getPasmoChargeHistory(pageNo, options).then((request) => request(axios, basePath));
        },
        /**
         * [P0188] チャージ制限情報取得API 処理時点のチャージ可能残高を返却する 認証: 個人トークンを利用する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPasmoChargeLimit(options?: any): AxiosPromise<ResPasmoChargeLimit> {
            return PasmoChargeApiFp(configuration).getPasmoChargeLimit(options).then((request) => request(axios, basePath));
        },
        /**
         * [P0216] モバイルPASMOポイントポイントチャージAPI モバイルPASMOにポイントチャージを実施する 認証: 個人トークンを利用する
         * @param {ReqMobilepasmoCharge} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMobilepasmoCharge(body?: ReqMobilepasmoCharge, options?: any): AxiosPromise<void> {
            return PasmoChargeApiFp(configuration).postMobilepasmoCharge(body, options).then((request) => request(axios, basePath));
        },
        /**
         * [P0181] セブン銀行送金登録API PASMOにチャージ機能（セブン銀行ATM受け取り）の送金登録を行う 認証: 個人トークンを利用する
         * @param {ReqSevenbankRegister} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSevenbankRegister(body?: ReqSevenbankRegister, options?: any): AxiosPromise<ResSevenbankRegister> {
            return PasmoChargeApiFp(configuration).postSevenbankRegister(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasmoChargeApi - object-oriented interface
 * @export
 * @class PasmoChargeApi
 * @extends {BaseAPI}
 */
export class PasmoChargeApi extends BaseAPI {
    /**
     * [P0183] PASMOチャージ履歴参照API PASMOへのチャージ履歴を取得する 認証: 個人トークンを利用する
     * @param {number} pageNo ページ番号 正の整数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasmoChargeApi
     */
    public getPasmoChargeHistory(pageNo: number, options?: any) {
        return PasmoChargeApiFp(this.configuration).getPasmoChargeHistory(pageNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [P0188] チャージ制限情報取得API 処理時点のチャージ可能残高を返却する 認証: 個人トークンを利用する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasmoChargeApi
     */
    public getPasmoChargeLimit(options?: any) {
        return PasmoChargeApiFp(this.configuration).getPasmoChargeLimit(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [P0216] モバイルPASMOポイントポイントチャージAPI モバイルPASMOにポイントチャージを実施する 認証: 個人トークンを利用する
     * @param {ReqMobilepasmoCharge} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasmoChargeApi
     */
    public postMobilepasmoCharge(body?: ReqMobilepasmoCharge, options?: any) {
        return PasmoChargeApiFp(this.configuration).postMobilepasmoCharge(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [P0181] セブン銀行送金登録API PASMOにチャージ機能（セブン銀行ATM受け取り）の送金登録を行う 認証: 個人トークンを利用する
     * @param {ReqSevenbankRegister} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasmoChargeApi
     */
    public postSevenbankRegister(body?: ReqSevenbankRegister, options?: any) {
        return PasmoChargeApiFp(this.configuration).postSevenbankRegister(body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * SevenbankApi - axios parameter creator
 * @export
 */
export const SevenbankApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * titanがサーバ、セブン銀行システムがクライアントとなるときのアクセストークン取得依頼API セブン銀行システムが想定外のリクエストを送信した場合、仕様書で定義されたステータスコード・レスポンスボディを返却する必要があるが、go-swaggerの共通処理により、Swagger定義では各リクエストに対して任意のレスポンスを返すことができないため、リクエストパラメータのバリデーションは各ハンドラの内部で行う。 具体的には、次の2点に留意されたい。 - 必須のリクエストパラメータに対しても `required: false` を指定する - 固定値のリクエストパラメータに対して `enum` を指定しない
         * @summary アクセストークン取得依頼API
         * @param {string} [connection] コネクション 必須パラメータ &#x60;close&#x60; 固定
         * @param {string} [xFapiInteractionId] シーケンスID 必須パラメータ 電文を一意に識別する値を設定する
         * @param {string} [contentType] コンテンツタイプ 必須パラメータ &#x60;application/x-www-form-urlencoded;charset&#x3D;UTF-8&#x60; 固定
         * @param {string} [clientId] クライアントID 必須パラメータ &#x60;sevenbank&#x60; 固定
         * @param {string} [grantType] グラントタイプ 必須パラメータ &#x60;client_credentials&#x60; 固定
         * @param {string} [scope] スコープ 必須パラメータ &#x60;PaymentResult+revoke_token&#x60; 固定 これは &#x60;PaymentResult revoke_token&#x60; をURLエンコードした値である
         * @param {string} [clientAssertionType] クライアントアサーションタイプ 必須パラメータ &#x60;urn%3Aietf%3Aparams%3Aoauth%3Aclient-assertion-type%3Ajwt-bearer&#x60; 固定 これは &#x60;urn:ietf:params:oauth:client-assertion-type:jwt-bearer&#x60; をURLエンコードした値である
         * @param {string} [clientAssertion] クライアントアサーション 必須パラメータ JWT形式で記載する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccessToken: async (connection?: string, xFapiInteractionId?: string, contentType?: string, clientId?: string, grantType?: string, scope?: string, clientAssertionType?: string, clientAssertion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/seven_bank/access_token`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (connection !== undefined && connection !== null) {
                localVarHeaderParameter['Connection'] = String(connection);
            }

            if (xFapiInteractionId !== undefined && xFapiInteractionId !== null) {
                localVarHeaderParameter['x-fapi-interaction-id'] = String(xFapiInteractionId);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }


            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientAssertionType !== undefined) { 
                localVarFormParams.set('client_assertion_type', clientAssertionType as any);
            }
    
            if (clientAssertion !== undefined) { 
                localVarFormParams.set('client_assertion', clientAssertion as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * titanがサーバ、セブン銀行システムがクライアントとなるときの送金結果通知API セブン銀行システムが想定外のリクエストを送信した場合、仕様書で定義されたステータスコード・レスポンスボディを返却する必要があるが、go-swaggerの共通処理により、Swagger定義では各リクエストに対して任意のレスポンスを返すことができないため、リクエストパラメータのバリデーションは各ハンドラの内部で行う。 具体的には、次の2点に留意されたい。 - 必須のリクエストパラメータに対しても `required: false` を指定する - 固定値のリクエストパラメータに対して `enum` を指定しない
         * @summary 送金結果通知API
         * @param {string} [connection] コネクション 必須パラメータ &#x60;close&#x60; 固定
         * @param {string} [xFapiInteractionId] シーケンスID 電文を一意に識別する値を設定する 必須パラメータ
         * @param {string} [contentType] コンテンツタイプ 必須パラメータ &#x60;application/jose;charset&#x3D;UTF-8&#x60; 固定
         * @param {string} [body] HTTPボディ 必須パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNotification: async (connection?: string, xFapiInteractionId?: string, contentType?: string, body?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/seven_bank/notification`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (connection !== undefined && connection !== null) {
                localVarHeaderParameter['Connection'] = String(connection);
            }

            if (xFapiInteractionId !== undefined && xFapiInteractionId !== null) {
                localVarHeaderParameter['x-fapi-interaction-id'] = String(xFapiInteractionId);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/jose';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * titanがサーバ、セブン銀行システムがクライアントとなるときのアクセストークン失効依頼API セブン銀行システムが想定外のリクエストを送信した場合、仕様書で定義されたステータスコード・レスポンスボディを返却する必要があるが、go-swaggerの共通処理により、Swagger定義では各リクエストに対して任意のレスポンスを返すことができないため、リクエストパラメータのバリデーションは各ハンドラの内部で行う。 具体的には、次の2点に留意されたい。 - 必須のリクエストパラメータに対しても `required: false` を指定する - 固定値のリクエストパラメータに対して `enum` を指定しない
         * @summary アクセストークン失効依頼API
         * @param {string} [connection] コネクション 必須パラメータ &#x60;close&#x60; 固定
         * @param {string} [xFapiInteractionId] シーケンスID 必須パラメータ 電文を一意に識別する値を設定する
         * @param {string} [contentType] コンテンツタイプ 必須パラメータ &#x60;application/x-www-form-urlencoded;charset&#x3D;UTF-8&#x60; 固定
         * @param {string} [clientId] クライアントID 必須パラメータ &#x60;sevenbank&#x60; 固定
         * @param {string} [grantType] グラントタイプ 必須パラメータ &#x60;client_credentials&#x60; 固定
         * @param {string} [clientAssertionType] クライアントアサーションタイプ 必須パラメータ &#x60;urn%3Aietf%3Aparams%3Aoauth%3Aclient-assertion-type%3Ajwt-bearer&#x60; 固定 これは &#x60;urn:ietf:params:oauth:client-assertion-type:jwt-bearer&#x60; をURLエンコードした値である
         * @param {string} [clientAssertion] クライアントアサーション 必須パラメータ JWT形式で記載する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRevokeToken: async (connection?: string, xFapiInteractionId?: string, contentType?: string, clientId?: string, grantType?: string, clientAssertionType?: string, clientAssertion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/seven_bank/revoke_token`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (connection !== undefined && connection !== null) {
                localVarHeaderParameter['Connection'] = String(connection);
            }

            if (xFapiInteractionId !== undefined && xFapiInteractionId !== null) {
                localVarHeaderParameter['x-fapi-interaction-id'] = String(xFapiInteractionId);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }


            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (clientAssertionType !== undefined) { 
                localVarFormParams.set('client_assertion_type', clientAssertionType as any);
            }
    
            if (clientAssertion !== undefined) { 
                localVarFormParams.set('client_assertion', clientAssertion as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SevenbankApi - functional programming interface
 * @export
 */
export const SevenbankApiFp = function(configuration?: Configuration) {
    return {
        /**
         * titanがサーバ、セブン銀行システムがクライアントとなるときのアクセストークン取得依頼API セブン銀行システムが想定外のリクエストを送信した場合、仕様書で定義されたステータスコード・レスポンスボディを返却する必要があるが、go-swaggerの共通処理により、Swagger定義では各リクエストに対して任意のレスポンスを返すことができないため、リクエストパラメータのバリデーションは各ハンドラの内部で行う。 具体的には、次の2点に留意されたい。 - 必須のリクエストパラメータに対しても `required: false` を指定する - 固定値のリクエストパラメータに対して `enum` を指定しない
         * @summary アクセストークン取得依頼API
         * @param {string} [connection] コネクション 必須パラメータ &#x60;close&#x60; 固定
         * @param {string} [xFapiInteractionId] シーケンスID 必須パラメータ 電文を一意に識別する値を設定する
         * @param {string} [contentType] コンテンツタイプ 必須パラメータ &#x60;application/x-www-form-urlencoded;charset&#x3D;UTF-8&#x60; 固定
         * @param {string} [clientId] クライアントID 必須パラメータ &#x60;sevenbank&#x60; 固定
         * @param {string} [grantType] グラントタイプ 必須パラメータ &#x60;client_credentials&#x60; 固定
         * @param {string} [scope] スコープ 必須パラメータ &#x60;PaymentResult+revoke_token&#x60; 固定 これは &#x60;PaymentResult revoke_token&#x60; をURLエンコードした値である
         * @param {string} [clientAssertionType] クライアントアサーションタイプ 必須パラメータ &#x60;urn%3Aietf%3Aparams%3Aoauth%3Aclient-assertion-type%3Ajwt-bearer&#x60; 固定 これは &#x60;urn:ietf:params:oauth:client-assertion-type:jwt-bearer&#x60; をURLエンコードした値である
         * @param {string} [clientAssertion] クライアントアサーション 必須パラメータ JWT形式で記載する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAccessToken(connection?: string, xFapiInteractionId?: string, contentType?: string, clientId?: string, grantType?: string, scope?: string, clientAssertionType?: string, clientAssertion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResSevenbankAccessToken>> {
            const localVarAxiosArgs = await SevenbankApiAxiosParamCreator(configuration).postAccessToken(connection, xFapiInteractionId, contentType, clientId, grantType, scope, clientAssertionType, clientAssertion, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * titanがサーバ、セブン銀行システムがクライアントとなるときの送金結果通知API セブン銀行システムが想定外のリクエストを送信した場合、仕様書で定義されたステータスコード・レスポンスボディを返却する必要があるが、go-swaggerの共通処理により、Swagger定義では各リクエストに対して任意のレスポンスを返すことができないため、リクエストパラメータのバリデーションは各ハンドラの内部で行う。 具体的には、次の2点に留意されたい。 - 必須のリクエストパラメータに対しても `required: false` を指定する - 固定値のリクエストパラメータに対して `enum` を指定しない
         * @summary 送金結果通知API
         * @param {string} [connection] コネクション 必須パラメータ &#x60;close&#x60; 固定
         * @param {string} [xFapiInteractionId] シーケンスID 電文を一意に識別する値を設定する 必須パラメータ
         * @param {string} [contentType] コンテンツタイプ 必須パラメータ &#x60;application/jose;charset&#x3D;UTF-8&#x60; 固定
         * @param {string} [body] HTTPボディ 必須パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNotification(connection?: string, xFapiInteractionId?: string, contentType?: string, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResSevenbankNotification>> {
            const localVarAxiosArgs = await SevenbankApiAxiosParamCreator(configuration).postNotification(connection, xFapiInteractionId, contentType, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * titanがサーバ、セブン銀行システムがクライアントとなるときのアクセストークン失効依頼API セブン銀行システムが想定外のリクエストを送信した場合、仕様書で定義されたステータスコード・レスポンスボディを返却する必要があるが、go-swaggerの共通処理により、Swagger定義では各リクエストに対して任意のレスポンスを返すことができないため、リクエストパラメータのバリデーションは各ハンドラの内部で行う。 具体的には、次の2点に留意されたい。 - 必須のリクエストパラメータに対しても `required: false` を指定する - 固定値のリクエストパラメータに対して `enum` を指定しない
         * @summary アクセストークン失効依頼API
         * @param {string} [connection] コネクション 必須パラメータ &#x60;close&#x60; 固定
         * @param {string} [xFapiInteractionId] シーケンスID 必須パラメータ 電文を一意に識別する値を設定する
         * @param {string} [contentType] コンテンツタイプ 必須パラメータ &#x60;application/x-www-form-urlencoded;charset&#x3D;UTF-8&#x60; 固定
         * @param {string} [clientId] クライアントID 必須パラメータ &#x60;sevenbank&#x60; 固定
         * @param {string} [grantType] グラントタイプ 必須パラメータ &#x60;client_credentials&#x60; 固定
         * @param {string} [clientAssertionType] クライアントアサーションタイプ 必須パラメータ &#x60;urn%3Aietf%3Aparams%3Aoauth%3Aclient-assertion-type%3Ajwt-bearer&#x60; 固定 これは &#x60;urn:ietf:params:oauth:client-assertion-type:jwt-bearer&#x60; をURLエンコードした値である
         * @param {string} [clientAssertion] クライアントアサーション 必須パラメータ JWT形式で記載する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRevokeToken(connection?: string, xFapiInteractionId?: string, contentType?: string, clientId?: string, grantType?: string, clientAssertionType?: string, clientAssertion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SevenbankApiAxiosParamCreator(configuration).postRevokeToken(connection, xFapiInteractionId, contentType, clientId, grantType, clientAssertionType, clientAssertion, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SevenbankApi - factory interface
 * @export
 */
export const SevenbankApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * titanがサーバ、セブン銀行システムがクライアントとなるときのアクセストークン取得依頼API セブン銀行システムが想定外のリクエストを送信した場合、仕様書で定義されたステータスコード・レスポンスボディを返却する必要があるが、go-swaggerの共通処理により、Swagger定義では各リクエストに対して任意のレスポンスを返すことができないため、リクエストパラメータのバリデーションは各ハンドラの内部で行う。 具体的には、次の2点に留意されたい。 - 必須のリクエストパラメータに対しても `required: false` を指定する - 固定値のリクエストパラメータに対して `enum` を指定しない
         * @summary アクセストークン取得依頼API
         * @param {string} [connection] コネクション 必須パラメータ &#x60;close&#x60; 固定
         * @param {string} [xFapiInteractionId] シーケンスID 必須パラメータ 電文を一意に識別する値を設定する
         * @param {string} [contentType] コンテンツタイプ 必須パラメータ &#x60;application/x-www-form-urlencoded;charset&#x3D;UTF-8&#x60; 固定
         * @param {string} [clientId] クライアントID 必須パラメータ &#x60;sevenbank&#x60; 固定
         * @param {string} [grantType] グラントタイプ 必須パラメータ &#x60;client_credentials&#x60; 固定
         * @param {string} [scope] スコープ 必須パラメータ &#x60;PaymentResult+revoke_token&#x60; 固定 これは &#x60;PaymentResult revoke_token&#x60; をURLエンコードした値である
         * @param {string} [clientAssertionType] クライアントアサーションタイプ 必須パラメータ &#x60;urn%3Aietf%3Aparams%3Aoauth%3Aclient-assertion-type%3Ajwt-bearer&#x60; 固定 これは &#x60;urn:ietf:params:oauth:client-assertion-type:jwt-bearer&#x60; をURLエンコードした値である
         * @param {string} [clientAssertion] クライアントアサーション 必須パラメータ JWT形式で記載する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccessToken(connection?: string, xFapiInteractionId?: string, contentType?: string, clientId?: string, grantType?: string, scope?: string, clientAssertionType?: string, clientAssertion?: string, options?: any): AxiosPromise<ResSevenbankAccessToken> {
            return SevenbankApiFp(configuration).postAccessToken(connection, xFapiInteractionId, contentType, clientId, grantType, scope, clientAssertionType, clientAssertion, options).then((request) => request(axios, basePath));
        },
        /**
         * titanがサーバ、セブン銀行システムがクライアントとなるときの送金結果通知API セブン銀行システムが想定外のリクエストを送信した場合、仕様書で定義されたステータスコード・レスポンスボディを返却する必要があるが、go-swaggerの共通処理により、Swagger定義では各リクエストに対して任意のレスポンスを返すことができないため、リクエストパラメータのバリデーションは各ハンドラの内部で行う。 具体的には、次の2点に留意されたい。 - 必須のリクエストパラメータに対しても `required: false` を指定する - 固定値のリクエストパラメータに対して `enum` を指定しない
         * @summary 送金結果通知API
         * @param {string} [connection] コネクション 必須パラメータ &#x60;close&#x60; 固定
         * @param {string} [xFapiInteractionId] シーケンスID 電文を一意に識別する値を設定する 必須パラメータ
         * @param {string} [contentType] コンテンツタイプ 必須パラメータ &#x60;application/jose;charset&#x3D;UTF-8&#x60; 固定
         * @param {string} [body] HTTPボディ 必須パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNotification(connection?: string, xFapiInteractionId?: string, contentType?: string, body?: string, options?: any): AxiosPromise<ResSevenbankNotification> {
            return SevenbankApiFp(configuration).postNotification(connection, xFapiInteractionId, contentType, body, options).then((request) => request(axios, basePath));
        },
        /**
         * titanがサーバ、セブン銀行システムがクライアントとなるときのアクセストークン失効依頼API セブン銀行システムが想定外のリクエストを送信した場合、仕様書で定義されたステータスコード・レスポンスボディを返却する必要があるが、go-swaggerの共通処理により、Swagger定義では各リクエストに対して任意のレスポンスを返すことができないため、リクエストパラメータのバリデーションは各ハンドラの内部で行う。 具体的には、次の2点に留意されたい。 - 必須のリクエストパラメータに対しても `required: false` を指定する - 固定値のリクエストパラメータに対して `enum` を指定しない
         * @summary アクセストークン失効依頼API
         * @param {string} [connection] コネクション 必須パラメータ &#x60;close&#x60; 固定
         * @param {string} [xFapiInteractionId] シーケンスID 必須パラメータ 電文を一意に識別する値を設定する
         * @param {string} [contentType] コンテンツタイプ 必須パラメータ &#x60;application/x-www-form-urlencoded;charset&#x3D;UTF-8&#x60; 固定
         * @param {string} [clientId] クライアントID 必須パラメータ &#x60;sevenbank&#x60; 固定
         * @param {string} [grantType] グラントタイプ 必須パラメータ &#x60;client_credentials&#x60; 固定
         * @param {string} [clientAssertionType] クライアントアサーションタイプ 必須パラメータ &#x60;urn%3Aietf%3Aparams%3Aoauth%3Aclient-assertion-type%3Ajwt-bearer&#x60; 固定 これは &#x60;urn:ietf:params:oauth:client-assertion-type:jwt-bearer&#x60; をURLエンコードした値である
         * @param {string} [clientAssertion] クライアントアサーション 必須パラメータ JWT形式で記載する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRevokeToken(connection?: string, xFapiInteractionId?: string, contentType?: string, clientId?: string, grantType?: string, clientAssertionType?: string, clientAssertion?: string, options?: any): AxiosPromise<void> {
            return SevenbankApiFp(configuration).postRevokeToken(connection, xFapiInteractionId, contentType, clientId, grantType, clientAssertionType, clientAssertion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SevenbankApi - object-oriented interface
 * @export
 * @class SevenbankApi
 * @extends {BaseAPI}
 */
export class SevenbankApi extends BaseAPI {
    /**
     * titanがサーバ、セブン銀行システムがクライアントとなるときのアクセストークン取得依頼API セブン銀行システムが想定外のリクエストを送信した場合、仕様書で定義されたステータスコード・レスポンスボディを返却する必要があるが、go-swaggerの共通処理により、Swagger定義では各リクエストに対して任意のレスポンスを返すことができないため、リクエストパラメータのバリデーションは各ハンドラの内部で行う。 具体的には、次の2点に留意されたい。 - 必須のリクエストパラメータに対しても `required: false` を指定する - 固定値のリクエストパラメータに対して `enum` を指定しない
     * @summary アクセストークン取得依頼API
     * @param {string} [connection] コネクション 必須パラメータ &#x60;close&#x60; 固定
     * @param {string} [xFapiInteractionId] シーケンスID 必須パラメータ 電文を一意に識別する値を設定する
     * @param {string} [contentType] コンテンツタイプ 必須パラメータ &#x60;application/x-www-form-urlencoded;charset&#x3D;UTF-8&#x60; 固定
     * @param {string} [clientId] クライアントID 必須パラメータ &#x60;sevenbank&#x60; 固定
     * @param {string} [grantType] グラントタイプ 必須パラメータ &#x60;client_credentials&#x60; 固定
     * @param {string} [scope] スコープ 必須パラメータ &#x60;PaymentResult+revoke_token&#x60; 固定 これは &#x60;PaymentResult revoke_token&#x60; をURLエンコードした値である
     * @param {string} [clientAssertionType] クライアントアサーションタイプ 必須パラメータ &#x60;urn%3Aietf%3Aparams%3Aoauth%3Aclient-assertion-type%3Ajwt-bearer&#x60; 固定 これは &#x60;urn:ietf:params:oauth:client-assertion-type:jwt-bearer&#x60; をURLエンコードした値である
     * @param {string} [clientAssertion] クライアントアサーション 必須パラメータ JWT形式で記載する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SevenbankApi
     */
    public postAccessToken(connection?: string, xFapiInteractionId?: string, contentType?: string, clientId?: string, grantType?: string, scope?: string, clientAssertionType?: string, clientAssertion?: string, options?: any) {
        return SevenbankApiFp(this.configuration).postAccessToken(connection, xFapiInteractionId, contentType, clientId, grantType, scope, clientAssertionType, clientAssertion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * titanがサーバ、セブン銀行システムがクライアントとなるときの送金結果通知API セブン銀行システムが想定外のリクエストを送信した場合、仕様書で定義されたステータスコード・レスポンスボディを返却する必要があるが、go-swaggerの共通処理により、Swagger定義では各リクエストに対して任意のレスポンスを返すことができないため、リクエストパラメータのバリデーションは各ハンドラの内部で行う。 具体的には、次の2点に留意されたい。 - 必須のリクエストパラメータに対しても `required: false` を指定する - 固定値のリクエストパラメータに対して `enum` を指定しない
     * @summary 送金結果通知API
     * @param {string} [connection] コネクション 必須パラメータ &#x60;close&#x60; 固定
     * @param {string} [xFapiInteractionId] シーケンスID 電文を一意に識別する値を設定する 必須パラメータ
     * @param {string} [contentType] コンテンツタイプ 必須パラメータ &#x60;application/jose;charset&#x3D;UTF-8&#x60; 固定
     * @param {string} [body] HTTPボディ 必須パラメータ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SevenbankApi
     */
    public postNotification(connection?: string, xFapiInteractionId?: string, contentType?: string, body?: string, options?: any) {
        return SevenbankApiFp(this.configuration).postNotification(connection, xFapiInteractionId, contentType, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * titanがサーバ、セブン銀行システムがクライアントとなるときのアクセストークン失効依頼API セブン銀行システムが想定外のリクエストを送信した場合、仕様書で定義されたステータスコード・レスポンスボディを返却する必要があるが、go-swaggerの共通処理により、Swagger定義では各リクエストに対して任意のレスポンスを返すことができないため、リクエストパラメータのバリデーションは各ハンドラの内部で行う。 具体的には、次の2点に留意されたい。 - 必須のリクエストパラメータに対しても `required: false` を指定する - 固定値のリクエストパラメータに対して `enum` を指定しない
     * @summary アクセストークン失効依頼API
     * @param {string} [connection] コネクション 必須パラメータ &#x60;close&#x60; 固定
     * @param {string} [xFapiInteractionId] シーケンスID 必須パラメータ 電文を一意に識別する値を設定する
     * @param {string} [contentType] コンテンツタイプ 必須パラメータ &#x60;application/x-www-form-urlencoded;charset&#x3D;UTF-8&#x60; 固定
     * @param {string} [clientId] クライアントID 必須パラメータ &#x60;sevenbank&#x60; 固定
     * @param {string} [grantType] グラントタイプ 必須パラメータ &#x60;client_credentials&#x60; 固定
     * @param {string} [clientAssertionType] クライアントアサーションタイプ 必須パラメータ &#x60;urn%3Aietf%3Aparams%3Aoauth%3Aclient-assertion-type%3Ajwt-bearer&#x60; 固定 これは &#x60;urn:ietf:params:oauth:client-assertion-type:jwt-bearer&#x60; をURLエンコードした値である
     * @param {string} [clientAssertion] クライアントアサーション 必須パラメータ JWT形式で記載する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SevenbankApi
     */
    public postRevokeToken(connection?: string, xFapiInteractionId?: string, contentType?: string, clientId?: string, grantType?: string, clientAssertionType?: string, clientAssertion?: string, options?: any) {
        return SevenbankApiFp(this.configuration).postRevokeToken(connection, xFapiInteractionId, contentType, clientId, grantType, clientAssertionType, clientAssertion, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReqAuth0Admin} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth0Admin: async (body: ReqAuth0Admin, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling auth0Admin.');
            }
            const localVarPath = `/auth0_admin`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ONE（オーネ）のみ利用できるエンドポイント。他グループ内アプリ、パートナーはアクセスできない。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebugInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/debug_info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * テスト用に設定された時刻を取得するAPI（環境変数でAPIの利用可否を決定する）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeConfig: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/time_config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * テスト用に時刻の設定を行うためのAPI（環境変数でAPIの利用可否を決定する）
         * @param {InlineObject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTimeConfig: async (body: InlineObject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postTimeConfig.');
            }
            const localVarPath = `/time_config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ONE（オーネ）のみ利用できるエンドポイント。他グループ内アプリ、パートナーはアクセスできない。
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        record: async (body?: object, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/record`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReqAuth0Admin} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auth0Admin(body: ReqAuth0Admin, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResAuth0Admin>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).auth0Admin(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ONE（オーネ）のみ利用できるエンドポイント。他グループ内アプリ、パートナーはアクセスできない。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDebugInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResDebugInfo>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getDebugInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * テスト用に設定された時刻を取得するAPI（環境変数でAPIの利用可否を決定する）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeConfig(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getTimeConfig(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).healthGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * テスト用に時刻の設定を行うためのAPI（環境変数でAPIの利用可否を決定する）
         * @param {InlineObject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTimeConfig(body: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).postTimeConfig(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ONE（オーネ）のみ利用できるエンドポイント。他グループ内アプリ、パートナーはアクセスできない。
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async record(body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).record(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {ReqAuth0Admin} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth0Admin(body: ReqAuth0Admin, options?: any): AxiosPromise<ResAuth0Admin> {
            return SystemApiFp(configuration).auth0Admin(body, options).then((request) => request(axios, basePath));
        },
        /**
         * ONE（オーネ）のみ利用できるエンドポイント。他グループ内アプリ、パートナーはアクセスできない。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebugInfo(options?: any): AxiosPromise<ResDebugInfo> {
            return SystemApiFp(configuration).getDebugInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * テスト用に設定された時刻を取得するAPI（環境変数でAPIの利用可否を決定する）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeConfig(options?: any): AxiosPromise<InlineResponse2001> {
            return SystemApiFp(configuration).getTimeConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet(options?: any): AxiosPromise<InlineResponse200> {
            return SystemApiFp(configuration).healthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * テスト用に時刻の設定を行うためのAPI（環境変数でAPIの利用可否を決定する）
         * @param {InlineObject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTimeConfig(body: InlineObject, options?: any): AxiosPromise<InlineResponse2002> {
            return SystemApiFp(configuration).postTimeConfig(body, options).then((request) => request(axios, basePath));
        },
        /**
         * ONE（オーネ）のみ利用できるエンドポイント。他グループ内アプリ、パートナーはアクセスできない。
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        record(body?: object, options?: any): AxiosPromise<void> {
            return SystemApiFp(configuration).record(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 
     * @param {ReqAuth0Admin} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public auth0Admin(body: ReqAuth0Admin, options?: any) {
        return SystemApiFp(this.configuration).auth0Admin(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ONE（オーネ）のみ利用できるエンドポイント。他グループ内アプリ、パートナーはアクセスできない。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getDebugInfo(options?: any) {
        return SystemApiFp(this.configuration).getDebugInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * テスト用に設定された時刻を取得するAPI（環境変数でAPIの利用可否を決定する）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getTimeConfig(options?: any) {
        return SystemApiFp(this.configuration).getTimeConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public healthGet(options?: any) {
        return SystemApiFp(this.configuration).healthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * テスト用に時刻の設定を行うためのAPI（環境変数でAPIの利用可否を決定する）
     * @param {InlineObject} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public postTimeConfig(body: InlineObject, options?: any) {
        return SystemApiFp(this.configuration).postTimeConfig(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ONE（オーネ）のみ利用できるエンドポイント。他グループ内アプリ、パートナーはアクセスできない。
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public record(body?: object, options?: any) {
        return SystemApiFp(this.configuration).record(body, options).then((request) => request(this.axios, this.basePath));
    }

}


