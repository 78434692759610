






import { Component, Prop, Vue } from 'vue-property-decorator';
import throttle from 'lodash/throttle';

@Component
export default class OnceButton extends Vue {
  @Prop() public click!: (e: Event) => void;
  @Prop({ default: 10000 }) public wait?: number;

  public throttledMethod = throttle(
    (e: Event) => {
      this.click(e);
    },
    this.wait,
    { trailing: false }
  );
}
