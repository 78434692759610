import { ICouponsRepository } from '@/repositories/interface/ICouponsRepository';
import { CouponResponse } from '@/models/cms/coupon';
import { fetchListContents } from '@/common/cms';
import { CouponItem } from '@/models/CouponItem';

// クーポンコード以外の項目
const NOT_SECRET_FIELDS = [
  'id',
  'createdAt',
  'updatedAt',
  'coupon_coupon',
  'coupon_name',
  'hidden_from_list',
  'coupon_explanation',
  'coupon_summary',
  'coupon_detail',
  'coupon_imgleft',
  'coupon_thumbnail',
  'coupon_logo',
  'coupon_tag',
  'coupon_url',
  'coupon_stt',
  'alliance_services_id',
  'data_color'
];

/**
 * クーポンの情報を取得する
 */
export class CouponsRepository implements ICouponsRepository {
  private readonly PATH = 'coupons';
  private readonly LIMIT = 100;
  private readonly HIDDEN_FROM_LIST = 'hidden_from_list[not_equals]true';

  public static empty() {
    return new Array<CouponItem>();
  }

  public constructor(
    private readonly isSP: boolean,
    private readonly supportsWebP: boolean
  ) {}

  /**
   * クーポンの一覧を取得する
   * TODO(k.sakiyama.d7@future.co.jp) オプションは引数から設定できるようにして、呼び出し側でコントロールさせる
   */
  public async get() {
    try {
      const { data } = await fetchListContents<CouponResponse>(this.PATH, {
        limit: this.LIMIT,
        filters: this.HIDDEN_FROM_LIST
      });
      const { contents } = data;
      return this.convertResponseToDomainObject(contents);
    } catch (_) {
      return new Array<CouponItem>();
    }
  }

  /**
   * クーポンの一覧を取得する（ただしクーポンコードは無し）
   * TODO(k.sakiyama.d7@future.co.jp) オプションは引数から設定できるようにして、呼び出し側でコントロールさせる
   */
  public async getWithoutCode() {
    try {
      const { data } = await fetchListContents<CouponResponse>(this.PATH, {
        fields: NOT_SECRET_FIELDS.join(','),
        limit: this.LIMIT,
        filters: this.HIDDEN_FROM_LIST
      });
      const { contents } = data;
      return this.convertResponseToDomainObject(contents);
    } catch (_) {
      return new Array<CouponItem>();
    }
  }

  /**
   * クーポンの単体取得(codeあり)
   * @param alliance_service_id
   */
  public async select(alliance_service_id: number) {
    try {
      const { data } = await fetchListContents<CouponResponse>(this.PATH, {
        limit: this.LIMIT,
        filters: `alliance_services_id[equals]${alliance_service_id}`
      });

      const { contents } = data;
      return contents.length
        ? CouponItem.valueOf(contents[0], this.isSP, this.supportsWebP)
        : null; // 先頭を返す
    } catch (_) {
      return null;
    }
  }

  /**
   * 指定したアライアンスサービスIDに該当するクーポンの一覧を取得(codeあり)
   * @param alliance_service_id
   */
  public async getByAllianceServiceId(alliance_service_id: number) {
    try {
      const { data } = await fetchListContents<CouponResponse>(this.PATH, {
        limit: this.LIMIT,
        filters:
          this.HIDDEN_FROM_LIST +
          `[and]alliance_services_id[equals]${alliance_service_id}`
      });

      const { contents } = data;
      return this.convertResponseToDomainObject(contents);
    } catch (_) {
      return new Array<CouponItem>();
    }
  }

  /**
   * クーポンの単体取得(codeなし)
   * @param alliance_service_id
   */
  public async selectWithoutCode(alliance_service_id: number) {
    try {
      const { data } = await fetchListContents<CouponResponse>(this.PATH, {
        fields: NOT_SECRET_FIELDS.join(','),
        limit: this.LIMIT,
        filters: `alliance_services_id[equals]${alliance_service_id}`
      });

      const { contents } = data;
      return contents.length
        ? CouponItem.valueOf(contents[0], this.isSP, this.supportsWebP)
        : null; // 先頭を返す
    } catch (_) {
      return null;
    }
  }

  /**
   * 指定したアライアンスサービスIDに該当するクーポンの一覧を取得(codeなし)
   * @param alliance_service_id
   */
  public async getByAllianceServiceIdWithoutCode(alliance_service_id: number) {
    try {
      const { data } = await fetchListContents<CouponResponse>(this.PATH, {
        fields: NOT_SECRET_FIELDS.join(','),
        limit: this.LIMIT,
        filters:
          this.HIDDEN_FROM_LIST +
          `[and]alliance_services_id[equals]${alliance_service_id}`
      });

      const { contents } = data;
      return this.convertResponseToDomainObject(contents);
    } catch (_) {
      return new Array<CouponItem>();
    }
  }

  private convertResponseToDomainObject(responseItems: CouponResponse[]) {
    return responseItems.map(e =>
      CouponItem.valueOf(e, this.isSP, this.supportsWebP)
    );
  }
}
