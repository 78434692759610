






























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { CampaignItem } from '@/models/CampaignItem';
import { CampaignRepository } from '@/repositories/CampaignRepository';

@Component
export default class CampaignScroll extends Vue {
  readonly INITIAL_PAGE_NO = 1;
  readonly LIMIT_NUM = 4;
  campaignList: Array<CampaignItem> = [] as Array<CampaignItem>;
  totalCount: number = 0;
  pageNo: number = this.INITIAL_PAGE_NO;

  created() {
    this.initialize();
  }
  initialize() {
    this.getCampaignList();
  }

  async getCampaignList() {
    const listItem = await this.campaignRepository.get(
      this.LIMIT_NUM,
      this.LIMIT_NUM * (this.pageNo - 1)
    );
    if (listItem) {
      this.campaignList = listItem.items;
      this.totalCount = listItem.totalCount;
    }
  }

  get campaignRepository() {
    return new CampaignRepository(
      this.$store.state.isSP,
      this.$store.state.supportsWebP
    );
  }

  permitCardTypeText(campaign: CampaignItem) {
    if (campaign.isPermitTargetAll) {
      return '全券種';
    }
    if (campaign.permitCardTypeText) {
      return campaign.permitCardTypeText;
    }
    if (campaign.isPermitTargetGroup) {
      return campaign.permitCardTypeGroups[0].name;
    }
    if (campaign.isPermitTargetSingle) {
      return campaign.permitCardTypes[0].name;
    }
    return '';
  }

  isMultipleCardType(campaign: CampaignItem) {
    if (campaign.isPermitTargetAll) {
      return false;
    }
    if (campaign.permitCardTypeText) {
      return false;
    }
    if (campaign.isPermitTargetGroup) {
      return campaign.permitCardTypeGroups[0].name;
    }
    if (campaign.isPermitTargetSingle) {
      return campaign.permitCardTypes[0].name;
    }
    return false;
  }

  @Prop()
  readonly isMarginShort?: boolean;
}
