import Vue from 'vue';
import * as titanApi from '@/gen/titan/api';
import { Balance } from '@/models/opcards/OpCard';
import { UserInfoForAutoInput } from '@/models/forms/UserForm';
import { AxiosError, AxiosResponse } from 'axios';

export class OpCardRepository extends Vue {
  private readonly DEVICE_NO = 9999;

  private async opApiClient() {
    const titanConfig = await this.$getTitanConfigWithToken(
      Vue.prototype.$auth
    );
    return new titanApi.OpApi(titanConfig);
  }

  async getOpBalance() {
    const titanConfig = await this.$getTitanConfigWithToken(this.$auth);
    const opApi = new titanApi.OpApi(titanConfig);
    try {
      // ONEからポイント残高を参照する際は端末番号を9999に指定する
      const opBalanceRes = await opApi.getOpBalance(this.DEVICE_NO);
      const { data } = opBalanceRes;
      return data;
    } catch (err) {
      return err;
    }
  }

  async getOpBalanceByOp(odakyuCustomerNo: string) {
    try {
      const client = await this.opApiClient();
      // ONEからポイント残高を参照する際は端末番号を9999に指定する
      const res = await client.getOpBalance(this.DEVICE_NO, odakyuCustomerNo);
      return Balance.valueOf(res.data);
    } catch (_) {
      return {} as Balance;
    }
  }

  async patchMainOpCard(odakyuCustomerNo: string) {
    const client = await this.opApiClient();
    try {
      const res = await client.opCardsIsMainPatch(odakyuCustomerNo);
      return Promise.resolve(res);
    } catch (err) {
      const res = err.response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  async removeOpCardNo(
    deleteOpCardDReqBody: titanApi.ReqOpCardDelete | undefined
  ) {
    const client = await this.opApiClient();
    try {
      const res = await client.deleteOpCards(deleteOpCardDReqBody);
      return Promise.resolve(res);
    } catch (err) {
      const res = err.response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  async postOpAuth(applyOpCardsPostReqBody: titanApi.ReqOpCard | undefined) {
    const titanConfig = await this.$getTitanConfigWithToken(this.$auth);
    const opApi = new titanApi.OpApi(titanConfig);
    try {
      const res = await opApi.postOpAuth(applyOpCardsPostReqBody);
      return {
        status: res.status,
        return_status: res.data.return_status
      };
    } catch (err) {
      return err.response;
    }
  }

  async postOpCardNo(postOpCardReqBody: titanApi.ReqOpCardPost | undefined) {
    const client = await this.opApiClient();
    try {
      const res = await client.postOpCards(postOpCardReqBody);
      return Promise.resolve(res);
    } catch (err) {
      const res = err.response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  async getUserInfoForAutoInput(
    odakyuCustomerNo: string,
    hiraLastName: string,
    hiraFirstName: string,
    birthYear: string,
    birthMonth: string,
    birthDay: string
  ) {
    const client = await this.opApiClient();
    return await client
      .getUserInfoForAutoInput(
        odakyuCustomerNo,
        hiraLastName,
        hiraFirstName,
        birthYear,
        birthMonth,
        birthDay
      )
      .then(res => {
        return new UserInfoForAutoInput(res.data);
      })
      .catch((err: AxiosError) => {
        const res = err.response;
        return Promise.reject(res?.data.code ? res.data.code : res?.status);
      });
  }

  async postJalMileExchange(exchange_point: number) {
    // ONEからOPをJALマイルに交換する際は端末番号を9999に指定する
    const postJalMileExchangeReqBody: titanApi.ReqPostJalMileExchange = {
      device_no: this.DEVICE_NO,
      exchange_point: exchange_point
    };
    const client = await this.opApiClient();
    try {
      const res = await client.postJalMiles(postJalMileExchangeReqBody);
      return Promise.resolve(res);
    } catch (err) {
      const res = err.response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  /**
   * ポイント交換 API (/titan/v1/op/exchanges) を呼び出す。
   * ネットワークエラーなどが生じた場合、Axios のエラーをそのまま throw する。
   * @param {string} odakyu_customer_no - 小田急お客さま番号
   * @param {number} point_amount - 交換ポイント数
   * @param {string} item_id - ポイント交換商品 ID
   * @param {string=} sku_item_name - SKU 単位商品名
   */
  async postOpExchange(
    odakyu_customer_no: string,
    point_amount: number,
    item_id: string,
    sku_item_name?: string
  ): Promise<AxiosResponse<void>> {
    const client = await this.opApiClient();
    try {
      return await client.postOpExchange({
        device_no: this.DEVICE_NO,
        odakyu_customer_no,
        point_amount,
        item_id,
        sku_item_name
      });
    } catch (err) {
      const res = (err as AxiosError).response;
      if (res) {
        throw res.data.code || res.status;
      }
      throw undefined;
    }
  }
}
