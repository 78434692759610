











































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Slick from 'vue-slick';
import $ from 'jquery';

import OnceButton from '@/components/OnceButton.vue';
import ServiceCard from '@/components/ServiceCard.vue';
import { AllianceServiceItem } from '@/models/AllianceServiceItem';
import { AllianceServicesRepository } from '@/repositories/AllianceServicesRepository';
import '../../node_modules/slick-carousel/slick/slick.css';
import {
  LargeCarouselRepository,
  SmallCarouselRepository
} from '@/repositories/CarouselRepository';
import { Image } from '@/models/cms';
import { LargeCarouselItem } from '@/models/LargeCarouselItem';
import { SmallCarouselItem } from '@/models/SmallCarouselItem';
import { DialogContent } from '@/models/DialogContent';
import CarouselDialog from '@/components/CarouselDialog.vue';
import MyPageMenuTiles from '@/components/MyPageMenuTiles.vue';

import ContactPanel from '@/components/ContactPanel.vue';
import StartNav from '@/components/StartNav.vue';
import OpAppDownloadPanel from '@/components/OpAppDownloadPanel.vue';
import NewsScroll from '@/components/NewsScroll.vue';
import CampaignScroll from '@/components/CampaignScroll.vue';
import EventScroll from '@/components/EventScroll.vue';

import { CouponsRepository } from '@/repositories/CouponsRepository';
import { CouponItem } from '@/models/CouponItem';
import CouponCard from '@/components/CouponCard.vue';
import CouponDialog from '@/components/CouponDialog.vue';

@Component({
  components: {
    CarouselDialog,
    ServiceCard,
    'once-button': OnceButton,
    Slick,
    MyPageMenuTiles,
    ContactPanel,
    StartNav,
    OpAppDownloadPanel,
    CouponCard,
    CouponDialog,
    NewsScroll,
    CampaignScroll,
    EventScroll
  }
})
export default class Home extends Vue {
  readonly largeSlickOptions = {
    dots: true,
    dotsClass: 'topMv_swiper_dots',
    centerMode: true,
    variableWidth: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 500,
    arrows: true,
    prevArrow:
      '<button type="button" class="topMv_swiper_btn topMv_swiper_btn-prev" aria-label="前"><i class="icon icon-arrowLeft"></i></button>',
    nextArrow:
      '<button type="button" class="topMv_swiper_btn topMv_swiper_btn-next" aria-label="次"><i class="icon icon-arrowRight"></i></button>'
  };
  readonly smallSlickOptions = {
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  readonly serviceSlickOptions = {
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnFocus: false,
    pauseOnHover: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 500,
    dots: false,
    arrows: true,
    prevArrow:
      '<button type="button" class="topService_slide_btn topService_slide_btn-prev" aria-label="前"><i class="icon icon-arrowLeft"></i></button>',
    nextArrow:
      '<button type="button" class="topService_slide_btn topService_slide_btn-next" aria-label="次"><i class="icon icon-arrowRight"></i></button>',
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }
    ]
  };

  private isCarouselDialogEnabled: boolean = false; // Slickに表示しているアイカサ3ヶ月無料のモーダル

  partners: AllianceServiceItem[] = [];
  largeCarousels: LargeCarouselItem[] = [];

  smallCarousels: SmallCarouselItem[] = [];

  carouselDialog: DialogContent | null = null;
  carouselDialogImage: Image | null = null;

  finishBindingEventToSmallCarousels = false;

  coupons: CouponItem[] = [];

  clickedCoupon: CouponItem | null = null;
  isCouponDialogOpen = false; // クーポンクリック時のv-dialogをコントロールする

  get repository() {
    return new CouponsRepository(
      this.$store.state.isSP,
      this.$store.state.supportsWebP
    );
  }

  @Watch('$auth.loading', { immediate: true })
  async loadCoupon() {
    this.coupons = await this.repository.get();
    //上から4つのクーポンを取得
    this.coupons = this.coupons.slice(0, 4);
  }

  openCouponDialog(c: CouponItem) {
    this.clickedCoupon = c;
    this.isCouponDialogOpen = true;
    this.$dataLayer.push({
      coupon_title: c.coupon_name,
      event: 'coupon-click'
    });
  }

  created() {
    this.doOnCreated();
  }

  updated() {
    this.attachClickHandlerToSmallCarouselChild();
  }

  beforeDestroy() {
    this.detachClickHandlerToSmallCarouselChild();
  }

  doOnCreated() {
    this.loadData();
  }

  async loadData() {
    const results = [];
    results.push(this.fetchPartners());
    results.push(this.fetchSmallCarousel());
    results.push(this.fetchLargeCarousel());
    await Promise.all(results);
  }

  async fetchPartners() {
    this.partners = await this.allianceServicesRepository.getServices();
  }

  async fetchSmallCarousel() {
    this.smallCarousels = await this.smallCarouselRepository.get({
      showPage: 'TopPage'
    });
  }

  async fetchLargeCarousel() {
    this.largeCarousels = await this.largeCarouselRepository.get();
  }

  get smallCarouselRepository() {
    return new SmallCarouselRepository(
      this.$store.state.isSP,
      this.$store.state.supportsWebP
    );
  }

  get allianceServicesRepository() {
    return new AllianceServicesRepository();
  }

  get largeCarouselRepository() {
    return new LargeCarouselRepository(this.$store.state.supportsWebP);
  }

  private signup() {
    this.$auth.loginWithRedirect({
      initialDisplay: 'signup'
    });
  }

  /**
   * vue-slickのDOMに @click でイベント登録しても、DOMによってはそのイベントが発火しないことがある。そのため、jQueryでイベント登録する。
   * Watch('smallCarousels')だとvue-slickのDOMが作成される前に実行されてしまうので、DOM更新のupdatedでイベント登録処理を実施する。
   * https://github.com/staskjs/vue-slick/issues/72
   *
   * TODO(k.sakiyama.d7@future.co.jp): vue-slickはそもそもVue.jsと相性が悪いく、枯れていないため、今後新規でカルーセルを作成する場合は以下を検討したい
   * https://github.com/surmon-china/vue-awesome-swiper
   */
  attachClickHandlerToSmallCarouselChild() {
    // 初回だけイベント登録すれば良いのでフラグ管理する
    if (this.finishBindingEventToSmallCarousels) {
      return;
    }

    if (!this.smallCarousels.length) {
      return;
    }

    $(this.$el)
      .find('.smallCarouselClickEventAnchor')
      .on('click', e => {
        e.preventDefault();
        const carouselId = $(e.currentTarget).data('id');
        const carousel = this.smallCarousels.find(
          element => element.id === carouselId
        );
        carousel && this.setDialogContent(carousel);
      });

    this.finishBindingEventToSmallCarousels = true;
  }

  detachClickHandlerToSmallCarouselChild() {
    if (!this.finishBindingEventToSmallCarousels) {
      return;
    }

    $(this.$el)
      .find('.smallCarouselClickEventAnchor')
      .off('click');

    this.finishBindingEventToSmallCarousels = false;
  }

  /**
   * 小カルーセルクリック時に展開されるダイアログの内容をセットし、公開する。
   */
  setDialogContent(smallCarousel: SmallCarouselItem) {
    this.carouselDialog = smallCarousel.dialogContent;
    this.carouselDialogImage = smallCarousel.img;
    this.isCarouselDialogEnabled = true;
  }

  private get isAuthenticatedOk() {
    return this.$auth.isAuthenticated && !this.$auth.loading;
  }

  private get isEmailVerified() {
    return this.$auth.user['email_verified'];
  }

  private get isSignupCompleted() {
    return this.$auth.user['https://one-odakyu.com/isSignupCompleted'];
  }

  private login() {
    this.$auth.loginWithRedirect({ appState: { targetUrl: '/' } });
  }
}
