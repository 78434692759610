/**
 * ドッペルゲンガードメインでないか検証する
 */
const allowed_email_domain: any = {
  validate: (value: unknown): boolean => {
    if (typeof value !== 'string') {
      return false;
    }
    return allowed_email_domain.contains(value);
  },
  contains: (email: string): boolean => {
    const deniedDomains = [
      '@gmai.com',
      '@gmahil.com',
      '@gmaij.com',
      '@gmail.com.com',
      '@gmajil.com',
      '@gmajl.com',
      '@gmalil.com',
      '@gmaol.com',
      '@gmgil.com',
      '@gmil.com',
      '@gmile.com',
      '@i.sftbank.jp',
      '@iclud.com',
      '@icoud.com',
      '@gmaii.com',
      '@gmail.cm',
      '@gmail.co.com',
      '@gmaile.com',
      '@gmall.com',
      '@gmaoil.com',
      '@gmcil.com',
      '@gmial.com',
      '@gmiel.com',
      '@ickoud.com',
      '@icliud.com',
      '@icloid.com',
      '@icroud.com',
      '@icluod.com',
      '@icolud.com',
      '@yahooco.jp'
    ];
    return !deniedDomains.some(domain => email.endsWith(domain));
  }
};
export default allowed_email_domain;
