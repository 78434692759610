import Vue from 'vue';
import * as api from '@/gen/api';
import partners from '@/resources/id-partners.json';
import { AllianceServicesRepositoryImpl } from '@/repositories/interface/AllianceServicesRepositoryImpl';
import { AllianceServiceItem } from '@/models/AllianceServiceItem';
import { EnergyRepository } from '@/repositories/EnergyRepository';

/**
 * 外部サーバからデータ取得するロジックに関して、以下のような場合はRepository classとして切り出すことを検討。
 * XxxRepositoryに切り出して、コンポーネントから利用する時にはシンプルな形式にする。
 *
 * 導入する背景
 * * 複数の画面から呼ばれるとき、OpenAPIのconfig部分を隠蔽してシンプルなインターフェイスを提供できる
 * * 単にバックエンドからデータを取得するだけでなく、加工が必要なときにRepository側に処理を埋め込むことができ、Vueコンポーネントの見通しがよくなる
 * * Vueコンポーネントにロジックが集まらなくなり、単体テストが書きやすくなる
 *
 * 参考
 * https://qiita.com/07JP27/items/0923cbe3b6435c19d761
 */

/**
 * @classdesc 提携サービス（ゴールドパートナー,ID連携パートナー）の情報を取得する
 */
export class AllianceServicesRepository
  implements AllianceServicesRepositoryImpl {
  private readonly NAME_DENGASU = '小田急でんき・ガス';

  public constructor() {}

  private async getApiClient() {
    const config = await Vue.prototype.$getConfigWithToken(Vue.prototype.$auth);
    return new api.AllianceServiceApi(config);
  }

  /**
   * バックエンドサーバから提携サービスが連携済みか否かのデータを取得。
   * その情報をもとにid-partners.jsonのデータを連携済みか否かで分けて返す。
   */
  public async getLinkedAndUnlinkedServices(excludeEnergy = false) {
    // サーバからデータを取得。Auth0のデータを確認し、ID連携済みの場合はis_linked=trueとなっている。
    const fetchServices = await this.fetchAllianceServices();

    // ID連携済みのサービスから名前だけ取得（Auth0から取得したデータ）
    const linkedNames = fetchServices
      .filter(e => e.is_linked)
      .map(e => e.alliance_service_name);

    // 画面表示するサービスの母数を取得。
    // #1428 アカウント情報には電ガスを載せないため 除外の対応を含める
    const partners = await this.getServices(excludeEnergy);

    if (excludeEnergy) {
      return this.splitLinkedAndUnlinked(partners, linkedNames);
    }

    // #1428 電気orガスを契約している場合に、電ガスもID連携済みサービスとして特別に扱う
    const hasContracts = await this.userHasEnergyContracts();
    if (hasContracts) {
      linkedNames.push(this.NAME_DENGASU);
    }

    return this.splitLinkedAndUnlinked(partners, linkedNames);
  }

  private async fetchAllianceServices() {
    const client = await this.getApiClient();
    if (!client) {
      return [];
    }

    const res = await client
      .searchAllianceServices()
      .catch(Vue.prototype.$tokenErrHandler);

    return res ? res.data : [];
  }

  /**
   * TODO(k.sakiyama.d7@future.co.jp) いずれMicroCMSから取得するため、あえてasyncにしている
   * ID連携パートナーの画面描画情報を返す
   */
  public async getServices(excludeEnergy = false) {
    const ret = this.getFromJson()
      .filter(i => i.is_show_list) // 表示可能かチェック
      .map(i => AllianceServiceItem.valueOf(i)) // json形式からinterfaceへ変換
      .sort((a, b) => {
        // sortの数値が低い順に並び替え
        if (a.sort > b.sort) {
          return 1;
        } else {
          return -1;
        }
      });

    // 母数から電ガスを除外するパタン
    if (excludeEnergy) {
      return Promise.resolve(ret.filter(e => e.name != this.NAME_DENGASU));
    }
    return Promise.resolve(ret);
  }

  private splitLinkedAndUnlinked(
    items: AllianceServiceItem[],
    linkedNames: (string | undefined)[]
  ) {
    const linkedServices = items
      .filter(e => linkedNames.includes(e.name))
      .map(e => this.convertResponseToDomainObject(e));
    const unlinkedServices = items
      .filter(e => !linkedNames.includes(e.name))
      .map(e => this.convertResponseToDomainObject(e));

    return { linkedServices, unlinkedServices };
  }

  /**
   * TODO (r.kobayashi.s8@future.co.jp) RepositoryからRepositoryで依存関係があるのはNG。 id-partners.jsonをmicroCMSに移すタイミングで、Energy, AllianceService, microCMS(id-partner.json) をそれぞれrepositoryに分け、サービスクラスを作成する
   */
  private async userHasEnergyContracts() {
    return await this.getEnergyRepository().userHasEnergyContracts();
  }

  getEnergyRepository() {
    return new EnergyRepository();
  }

  /**
   * テストのためあえてメソッド化
   */
  private getFromJson() {
    return partners.items;
  }

  private convertResponseToDomainObject({
    id,
    name,
    caption_for_linked,
    caption_for_not_linked,
    tag,
    img_main,
    img_logo,
    describe,
    attention,
    path_in_one,
    is_show_list,
    top_url,
    linkage_window_url,
    sort,
    available_use_op,
    available_earn_op
  }: {
    id: number;
    name: string;
    caption_for_linked?: string;
    caption_for_not_linked?: string;
    tag: string;
    img_main: string;
    img_logo: string;
    describe: string;
    attention: string;
    path_in_one: string;
    is_show_list: boolean;
    top_url: string;
    linkage_window_url: string;
    sort: number;
    available_use_op?: boolean;
    available_earn_op?: boolean;
  }) {
    return AllianceServiceItem.valueOf({
      id,
      name,
      caption_for_linked,
      caption_for_not_linked,
      tag,
      img_main,
      img_logo,
      describe,
      attention,
      path_in_one,
      is_show_list,
      top_url,
      linkage_window_url,
      sort,
      available_use_op,
      available_earn_op
    });
  }
}
