export const gtmDataLayer = {
  install: function(Vue: any) {
    const dataLayer = (window as any).dataLayer || [];
    Vue.prototype.$dataLayer = {
      push: (param: any) => {
        dataLayer.push(param);
      }
    };
  }
};
