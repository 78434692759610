import { ResGetOpCard, ResOpBalance } from '@/gen/titan';
import utils from '@/common/utils';
import cls from '@/common/classification';
import { OpUtils } from '@/common/OpUtils';

export class OpCard {
  private readonly CARD_TYPE_DIGITAL = 'OPポイント専用カード（デジタルタイプ）';
  // OPカード有効ステータス
  public readonly VALID_STATUS_CD = '00';

  constructor(
    readonly odakyuCustomerNo: string,
    readonly isMain: boolean,
    readonly cardType: string,
    readonly isAuthenticated: boolean,
    readonly status: string,
    readonly expire: string
  ) {}

  static valueOf(data: ResGetOpCard) {
    return new OpCard(
      data.odakyu_customer_no,
      data.is_main,
      data.card_type,
      data.is_authenticated,
      data.status ? data.status : '',
      data.expire ? data.expire : ''
    );
  }

  get formatOpCardNo() {
    // windows環境でlintエラーが発生したため、3行に分ける
    const customerNo1 = this.odakyuCustomerNo.slice(0, 2);
    const customerNo2 = this.odakyuCustomerNo.slice(2, 9);
    const customerNo3 = this.odakyuCustomerNo.slice(9, 12);
    return `${customerNo1}-${customerNo2}-${customerNo3}`;
  }

  get formatCardOptionText() {
    return `${this.formatOpCardNo} \r\n${this.cardType}`;
  }

  get isValidCard() {
    return this.status === this.VALID_STATUS_CD;
  }

  get isPointOnlyDigitalCard() {
    return OpUtils.isPointOnlyDigitalCard(this.odakyuCustomerNo);
  }

  get isPointOnlyRealCard() {
    return OpUtils.isPointOnlyRealCard(this.odakyuCustomerNo);
  }

  get isJCBCard() {
    return OpUtils.isJCBCard(this.odakyuCustomerNo);
  }

  get isVISACard() {
    return OpUtils.isVISACard(this.odakyuCustomerNo);
  }

  get isMasterCard() {
    return OpUtils.isMasterCard(this.odakyuCustomerNo);
  }

  get isOPCMembersCard() {
    return OpUtils.isOPCMembersCard(this.odakyuCustomerNo);
  }

  get isJCICard() {
    return OpUtils.isJCICard(this.odakyuCustomerNo);
  }
}

export class OpCardList {
  private opCreditCardList = [
    'OPクレジット・JCB',
    'OPクレジット・Visa',
    'OPクレジット・Mastercard®',
    'JALカード OPクレジット',
    'OPクレジット メンバーズ'
  ];
  private constructor(readonly list: OpCard[]) {}

  static valueOf(data: ResGetOpCard[]) {
    const cards = data.map(e => OpCard.valueOf(e));
    return new OpCardList(cards);
  }

  get sortedOpCards() {
    const cards = this.list;
    cards.sort((a: OpCard, b: OpCard) => {
      if (a.isMain) {
        return -1;
      }
      if (b.isMain) {
        return 1;
      }
      if (a.status !== b.status) {
        return parseInt(a.status) - parseInt(b.status);
      }
      return parseInt(b.odakyuCustomerNo) - parseInt(a.odakyuCustomerNo);
    });
    return cards;
  }

  get selectMainCard() {
    return this.list.find((v: OpCard) => v.isMain)!;
  }

  get validOpCards() {
    return this.list.filter(v => v.isValidCard);
  }

  get exceptOpCreditCards() {
    return this.list.filter(v => !this.opCreditCardList.includes(v.cardType));
  }
}

export class Balance {
  // 会員管理⇄GPSの同期が取れていない場合、point_accum_term, point_invalid_date, admission_dateの値が0で連携されてくる
  private readonly INVALID_DATE_CD = '0';

  static valueOf(data: ResOpBalance) {
    return new Balance(
      data.process_datetime,
      data.return_status,
      data.err_message ? data.err_message : '',
      data.member_status,
      data.this_year_balance ? data.this_year_balance : 0,
      data.last_year_balance ? data.last_year_balance : 0,
      data.previous_balance ? data.previous_balance : 0,
      data.point_accum_term ? data.point_accum_term : '',
      data.admission_date ? data.admission_date : '',
      data.point_invalid_date ? data.point_invalid_date : ''
    );
  }

  constructor(
    readonly processDatetime: string,
    readonly returnStatus: string,
    readonly errMessage: string,
    readonly memberStatus: string,
    readonly thisYearBalance: number,
    readonly lastYearBalance: number,
    readonly previousBalance: number,
    readonly pointAccumTerm: string,
    readonly admissionDate: string,
    readonly pointInvalidDate: string
  ) {}

  isInvalidOpCard() {
    return (
      this.returnStatus === cls.POINT_DEAL_STATUS_FROM_ECOP.MISSING.CD ||
      this.returnStatus === cls.POINT_DEAL_STATUS_FROM_ECOP.UNSUBSCRIBE.CD ||
      this.returnStatus === cls.POINT_DEAL_STATUS_FROM_ECOP.EXPIRED.CD
    );
  }

  // 本年ポイント有効期限
  get formatPointInvalidDate() {
    if (this.pointInvalidDate === this.INVALID_DATE_CD) {
      return '-';
    }
    const year = parseInt(this.pointInvalidDate.slice(0, 4));
    const month = parseInt(this.pointInvalidDate.slice(4, 6));
    const lastDay = utils.getLastDayOfMonth(year, month);
    return `${year}/${month}/${lastDay}`;
  }

  // 前年度の積立ポイントを利用できる3ヶ月の期間中かどうかを返す
  get isPrevPointValidTerm() {
    const year = parseInt(this.pointInvalidDate.slice(0, 4));
    const month = parseInt(this.pointInvalidDate.slice(4, 6));
    const firstDate = new Date(year, month - 3, 1);
    const lastDate = new Date(year, month, 1);
    const today = new Date();
    return firstDate <= today && today < lastDate;
  }

  // 本年ポイント積立期限をYYYY/M/Dに整形する
  get fundingTerm() {
    if (this.admissionDate === this.INVALID_DATE_CD) {
      return '-';
    }
    const accLastYear = parseInt(this.pointAccumTerm.slice(0, 4));
    const accLastMonth = parseInt(this.pointAccumTerm.slice(4, 6));
    const accLastDay = new Date(accLastYear, accLastMonth, 0).getDate();

    return `${accLastYear}/${accLastMonth}/${accLastDay}`;
  }
}
