import {
  LogoutOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  RedirectLoginResult
} from '@auth0/auth0-spa-js';
import Message from '@/common/message';
import { jwtDecode } from 'jwt-decode';

export interface OpAppLogoutOptions extends LogoutOptions {
  opAppLogout?: boolean;
  opAppLogoutMessage?: string;
}

const timeoutStatusCode = -1000;
const timeoutValue = 10000;

const ERR_MSG_INVALID_REQUEST_CODE = 'invalid requestCode';
const OPAPP_STATUS_CODE_SUCCESS = 1;

export class Auth0ClientThruOpApp {
  // OPアプリを用いて認証管理をしていることを確認するためのメンバ変数
  isOpApp = true;
  // OPアプリ宛のリクエストとOPアプリからのコールバックを紐付けるためのIDとして用いるカウンタ
  counter = 0;
  constructor(
    private refreshToken: (requestOptions: string) => void,
    private closeWindow: (message: string) => void,
    private closeWindowAndLogout: (message: string) => void
  ) {
    // ONEからのトークンリフレッシュのリクエストと、OPアプリからのコールバックを正しく対象付けるため、
    // requestCodeをキーとして対象のコールバック関数を取得できるよう、mapにコールバック関数を登録しておく。
    window.completeRefreshTokenCallback = (
      requestCode: string,
      statusCode: number,
      tokens: string
    ) => {
      var callback = this.callbackFunctions.get(requestCode);
      if (!callback) {
        // タイムアウト用に、リフレッシュのリクエストから規定のタイムアウト値経過後に必ずコールバック関数が呼ばれる。
        // 正常にOPアプリからコールバック関数が呼ばれている場合、当該関数は削除済であるため、何もせず終了する。
        if (statusCode === timeoutStatusCode) {
          return;
        }
        // 不正なrequestCodeがOPアプリから返却された場合
        this.closeWindow(
          Message.get('2000071', {
            errorCode: ERR_MSG_INVALID_REQUEST_CODE
          })
        );
        throw new Error(
          `invalid request code was sent. requestCode:${requestCode}`
        );
      }
      callback(statusCode, tokens);
      this.callbackFunctions.delete(requestCode);
    };
  }

  callbackFunctions = new Map<
    string,
    (statusCode: number, tokens: string) => void
  >();

  // Auth0Clientと同等のインターフェイスになるようメソッドを定義するが、
  // OPアプリ内Webviewでアクセスする限り呼び出されないメソッドについては、一切処理を行わない。
  async loginWithPopup(): Promise<void> {
    return;
  }
  async loginWithRedirect(): Promise<void> {
    return;
  }
  async getUser(): Promise<any> {
    try {
      const { idToken } = await this.refreshAndFetchTokens(false);
      return jwtDecode(idToken);
    } catch (err) {
      // トークンリフレッシュのリクエスト失敗や、トークンの形式不正、有効期限切れ等の場合
      this.closeWindow(
        Message.get('2000071', {
          errorCode: err
        })
      );
      throw err;
    }
  }
  async getIdTokenClaims(): Promise<any> {
    return;
  }
  async handleRedirectCallback(): Promise<RedirectLoginResult> {
    return { appState: null };
  }
  async getTokenSilently(o: GetTokenSilentlyOptions): Promise<string> {
    try {
      const { accessToken } = await this.refreshAndFetchTokens(
        o ? o.ignoreCache || false : false
      );
      return accessToken;
    } catch (err) {
      // トークンリフレッシュのリクエスト失敗や、トークンの形式不正、有効期限切れ等の場合
      this.closeWindow(
        Message.get('2000071', {
          errorCode: err
        })
      );
      throw err;
    }
  }
  async getTokenWithPopup(o: GetTokenWithPopupOptions): Promise<string> {
    try {
      const { accessToken } = await this.refreshAndFetchTokens(
        o ? o.ignoreCache || false : false
      );
      return accessToken;
    } catch (err) {
      // トークンリフレッシュのリクエスト失敗や、トークンの形式不正、有効期限切れ等の場合
      this.closeWindow(
        Message.get('2000071', {
          errorCode: err
        })
      );
      throw err;
    }
  }
  async logout(options: OpAppLogoutOptions) {
    if (options.opAppLogout) {
      this.closeWindowAndLogout(options.opAppLogoutMessage || '');
      return;
    }
    this.closeWindow(options.opAppLogoutMessage || '');
  }
  async isAuthenticated(): Promise<boolean> {
    return true;
  }

  // リフレッシュした最新のアクセストークン・IDトークンを取得する。
  private async refreshAndFetchTokens(
    ignoreCache: boolean
  ): Promise<{
    accessToken: string;
    idToken: string;
  }> {
    return new Promise((resolve, reject) => {
      const requestCode = String(this.counter++);
      const callback = (statusCode: number, tokens: string) => {
        if (statusCode !== OPAPP_STATUS_CODE_SUCCESS) {
          reject(statusCode);
        }
        const { accessToken, IDToken } = JSON.parse(tokens);
        if (this.isAvailableToken(accessToken)) {
          resolve({ accessToken: accessToken, idToken: IDToken });
        }
        reject(new Error(`accessToken is unavailable`));
      };
      this.callbackFunctions.set(requestCode, callback);
      const requestOptions = JSON.stringify({
        requestCode: requestCode,
        isForce: ignoreCache
      });
      try {
        this.refreshToken(requestOptions);
      } catch (err) {
        reject(err);
      }
      setTimeout(() => {
        // 規定のタイムアウト値経過後にタイムアウトしたことを伝えるためにコールバック関数を呼び出す。
        window.completeRefreshTokenCallback(requestCode, timeoutStatusCode, '');
      }, timeoutValue);
    });
  }

  // アクセストークンの有効期限を確認する。
  private isAvailableToken(jwt: string | null): boolean {
    if (!jwt) {
      return false;
    }
    // トークンのデコード失敗は無効トークンとして扱う。
    try {
      const payload = jwtDecode(jwt);
      // Date.now()ではミリ秒単位のtimestampを取得するため秒単位にして比較
      return payload.exp ? payload.exp > Date.now() / 1000 : false;
    } catch {
      return false;
    }
  }
}
