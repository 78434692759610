/**
 * JIS X 0213は会員管理システム等で受け入れられるCP932(従来Windows文字コード)と互換性が無く、
 * JIS X 0208との差分である第三水準漢字は常用漢字ではないため、
 * 入力文字として禁止する。そのためのバリデーションとして、
 * 入力文字列がJIS X 0208に含まれる文字かどうかを検証する。
 * http://ash.jp/code/codetbl2.htm
 */
const jis_x_0208: any = {
  validate: (value: any): boolean => {
    if (Array.isArray(value)) {
      return value.every((v: any) => jis_x_0208.validate(v));
    }
    if (value === null || value === undefined || value === '') {
      return true;
    }
    if (typeof value !== 'string') {
      return false;
    }
    return Array.prototype.every.call(value, jis_x_0208.contains);
  },
  contains: (c: string): boolean => {
    const encoding = require('encoding-japanese');
    const jis = encoding.convert([c.charCodeAt(0)], 'SJIS', 'UNICODE');
    if (jis.length === 1) {
      // 半角文字チェック
      return (
        /**
         * encoding-japaneseはJIS X 0212までしか対応しておらず、JIS X 0213で追加された文字や、
         * 非対応文字は63(本来"?"に対応)になってしまうため、回避する。
         * https://github.com/polygonplanet/encoding.js/blob/master/src/encoding-convert.js#L614
         * JIS X 0212までの文字は変換が保証されているため、本用途では問題ないものと判断する。
         */
        (jis[0] === 63 && c === '?') ||
        (jis[0] != 63 && jis_x_0208.checkHalfWidth(jis[0]))
      );
    } else if (jis.length === 2) {
      // 全角文字チェック
      return jis_x_0208.checkFullWidth(jis[0] * 256 + jis[1]);
    }
    return false;
  },
  checkHalfWidth: (v: number): boolean => {
    return (32 <= v && v <= 126) || (161 <= v && v <= 223);
  },
  checkFullWidth: (v: number): boolean => {
    return (
      // 各種記号、英数字、かな
      (33087 <= v && v <= 33982) ||
      // 記号
      (34624 <= v && v <= 34714) ||
      // JIS第一水準漢字
      (34975 <= v && v <= 39026) ||
      // JIS第二水準漢字
      (39071 <= v && v <= 60068)
    );
  }
};
export default jis_x_0208;
