export class translateErr {
  private static readonly ERR_TYPE_CLS: { [key: string]: string } = {
    unauthorized: '認証エラー',
    'internal error': 'サーバーエラー'
  };

  private static readonly ERR_MSG_CLS: { [key: string]: string } = {
    'You did not agree to the terms of service':
      '規約に同意いただけない場合は、サービスを継続利用いただけません',
    'Sorry, something went wrong.':
      'サーバーエラーが発生しました。しばらく経ってから再度お試しください。',
    'Failed to fetch opcards from the server: Request failed with status code 503':
      'システムメンテナンス中のため、ログインすることができません。しばらく経ってから再度お試しください。'
  };

  static getErrTypeVal(key: string) {
    return translateErr.ERR_TYPE_CLS[key]
      ? translateErr.ERR_TYPE_CLS[key]
      : key;
  }

  static getErrMsgVal(key: string) {
    return translateErr.ERR_MSG_CLS[key] ? translateErr.ERR_MSG_CLS[key] : key;
  }
}
